<template>
    <div class="container">
        <div class="sub-tit">
            <h2>합격자안내</h2>
        </div>
        <div class="inner pt0">
            <div class="board-list">
                <Search :searchArr="searchArr" @setState="setState"></Search>
                <div class="contents board-list__table">
                    <table>
                        <colgroup>
                            <col style="width: 100px;">
                            <col style="width: auto">
                            <col style="width: 100px;">
                            <col style="width: 150px;">
                            <col style="width: 100px;">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>NO.</th>
                            <th>제목</th>
                            <th>첨부파일</th>
                            <th>등록일</th>
                            <th>조회수</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(recruitGuide, idx) in recruitGuideList" :key="idx">
                            <td class="number">
                                <template v-if="recruitGuide.noticeYn == 'Y'">
                                    <span class="notice">공지</span>
                                </template>
                                <template v-else>
                                <span class="num">
                                {{ recruitGuide.rgNo }}
                                </span>
                                </template>
                            </td>
                            <td class="left tit">
                                <router-link :to="`/inquiry/guideDetail.do?rgNo=${recruitGuide.rgNo}`" class="board-list__link">
                                    <p>{{ recruitGuide.rgTitle }}</p>
                                </router-link>
                            </td>
                            <td class="file"><span v-if="Object.keys(recruitGuide.attachBag).length > 0" class="icon"></span></td>
                            <td class="date">{{ $commonUtils.timestampToDate(recruitGuide.createdDt) }}</td>
                            <td class="view">{{ recruitGuide.readCnt }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <pagination :totCnt="recruitGuideCnt" :pageRow="pageRow" :currentNum="currentNum" :pageCount="10" @setNum="setNum"></pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import Pagination from "@/components/common/Pagination.vue";
    import Search from "@/components/common/SearchBox.vue";

    export default {
        components: {
            Search, Pagination
        },
        data() {
            return {
                // 검색박스 셀렉트 옵션 리스트
                searchArr: [
                    { 'title': '전체', 'value': '' }
                    , { 'title': '제목', 'value': 'Title' }
                    , { 'title': '내용', 'value': 'Content' }
                ],
                searchOption: '',
                searchKeyword: '',

                pageRow: 10,
                currentNum: 1,
                recruitGuideCnt: 0,
                recruitGuideList: [],
            }
        },
        methods: {
            async getRecruitGuideList() {
                const params = {
                    rnCategory : this.rnCategory,
                    pageRow : this.pageRow,
                    startIndex: this.currentNum,
                }
                if (this.searchOption) {
                    params[this.searchOption] = this.searchKeyword;
                }

                await this.axios.get(this.api.recruit.guide.list, {
                    params: params
                }).then(res => {
                    // console.log(res.data)
                    this.recruitGuideCnt = res.data.totCnt
                    this.recruitGuideList = res.data.list
                });
            },
            async setState(searchOption, searchKeyword) {
                this.searchOption = searchOption ? 'rg' + searchOption : 'searchKeyword'
                this.searchKeyword = searchKeyword

                this.currentNum = 1
                this.getRecruitGuideList()
            },
            // pagination emit
            setNum(n){
                this.currentNum = n
                this.getRecruitGuideList()
            },
        },
        mounted() {
            this.getRecruitGuideList()
        },
    }
</script>
<style>

</style>