<template>
    <div>
        <div class="input-form type1">
            <div class="text-box type1 info-text mt20">
                <div class="input-form__top-wrap">
                    <p class="input-form__top-text type2 c-red">- 온라인 입사지원시 브라우저(익스, 크롬등) 당 1개의 채용공고만 지원해주시기 바랍니다.(동일 브라우저에서 한개이상 지원불가)</p>
                    <p class="input-form__top-text type2">- 개인정보 보호를 위해 일정시간(30분) 동안 입력이 없을 경우 작성한 내용이 자동삭제 됩니다.</p>
                    <p class="input-form__top-text type2">- 지원서 작성 시 이전 페이지로 강제이동시 입력 오류가 발생할 수 있으니 주의 바랍니다.</p>
                </div>
            </div>
        </div>

        <div>
            <div class="text-box type4 top-blue" style="border: none;">
                <div class="text-box__wrap">
                    <div class="info-text ">
                        <h3 class="depth03__title title">개인정보 수집 및 이용에 대한 동의</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>
                                    본 홈페이지를 활용하여 채용을 실시하는 창원파티마병원 지원자의 개인정보를 중요시하며, <br>
                                    「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,「개인정보보호법」을 준수하기 위하여 노력하고 있습니다.<br>
                                    개인정보수집과 이용에 대한 안내입니다. 반드시 읽어보시고 동의를 하셔야 본 기관에 지원하실 수 있습니다.<br><br>
                                    * 입사지원시 확인사항
                                    <br>
                                    입력하신 정보는 입사를 제외한 어떠한 경우에도 사용되지 않습니다. <br>
                                    입사지원이 마감된 후에는 수정 및 취소가 불가합니다. <br>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="info-text">
                        <h3 class="depth03__title title">채용서류 반환 청구 안내</h3>
                        <div class="depth__wrap depth03">
                            <div class="depth__text">
                                <p>
                                    <b>「채용절차의 공정화에 관한 법률」 제11조(채용서류의 반환 등) 1항</b> <br>
                                    구인자는 구직자의 채용 여부가 확정된 이후 구직자(확정된 채용대상자는 제외한다)가 채용서류의 반환을 청구하는 경우에는 본인임을 확인한 후 대통령령으로 정하는 바에 따라 반환하여야 한다. 다만, 제7조 제1항에 <span style="border-bottom: 1px solid #e8508d;">따라 홈페이지 또는 전자우편으로 제출된 경우나 구직자가 구인자의 요구 없이 자발적으로
                                        제출한 경우에는 그러하지 아니하다.</span><br>
                                    <br>
                                    <b>「채용절차의 공정화에 관한 법률 시행령」 제4조(채용서류의 반환 청구기간)</b> <br>
                                    법 제11조제4항에 따른 채용서류의 반환 청구기간은 구직자의 채용 여부가 확정된 날 이후 14일부터 180일까지의 기간의 범위에서 구인자가 정한 기간으로 한다. 이 경우 구인자는 채용 여부가 확정되기 전까지 구인자가 정한 채용서류의 반환 청구기간을 구직자에게 알려야 한다. <br>
                                    <br>
                                    <b>「채용절차의 공정화에 관한 법률 시행규칙」 제3조(채용서류의 반환청구 방법)</b> <br>
                                    구직자는 법 제11조제2항에 따라 채용서류의 반환을 청구하는 경우 별지 제3호서식의 채용서류 반환청구서를 홈페이지, 전자우편 또는 팩스 등으로 구인자에게 제출하여야 한다. <br>
                                    <br>
                                    위 관련 법률에 의거하여 본원은 채용서류 반환과 관련하여 아래와 같이 고지하오니 참고하여 주시기 바랍니다. <br>
                                    <br>
                                    □ 채용서류 반환 청구를 희망하는 구직자는 [별지 제3호서식]채용서류 반환청구서(채용절차의 공정화에 관한 법률 시행규칙)를 작성하시어 채용담당자에게 발송 <br>
                                    □ 응시원서에 작성한 이메일과 청구 반환 요청한 이메일등 정보가 다를 경우 본인확인을 요청할 수 있음 <br>
                                    □ 합격자발표일로 부터 최대 180일까지 구직자의 서류반환 청구 요청이 없을 경우 「개인정보보호법」에 따라 채용서류를 파기함
                                </p>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </div>

        <div class="contents join-agree">

            <div class="join-agree__total">
                <div class="agree-input">
                    <label class="join-agree__total-input">
                        <input type="checkbox" class="agree-input__radio">
                        <span class="icon"></span> 서비스 전체 약관에 동의합니다.
                    </label>
                </div>
            </div>

            <div class="patient">
                <!-- 개인정보 수집·이용 동의  -->
                <div>
                    <div class="contents text-box type2">
                        <h3 class="text-box__tit">개인정보 수집·이용 동의 <span class="c-red">(필수)</span></h3>
                        <div class="text-box type2">
                            <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                <h5 class="text-box__tit">1. 수집 목적</h5>
                                <p class="text-box__desc">지원자 역량 및 특기를 파악하기 위한 추가정보 확인</p>

                                <h5 class="text-box__tit">2. 수집 항목</h5>
                                <p class="text-box__desc">보훈사항, 병역사항, 경력사항, 외국어, 자격면허, 교육연수, 자기소개, 취미, 특기</p>

                                <h5 class="text-box__tit">3. 보유 및 이용 기간</h5>
                                <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">서류 마감일 기준 1년 후</p>
                                <br>
                                <p class="small-text">※ 지원자는 개인정보 수집·이용에 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 채용 진행이 불가합니다.</p>
                            </div>
                        </div>
                    </div>
                    <div class="agree-input">
                        <label class="agree-input__item">
                            <input type="radio" class="agree-input__radio" name="agree1">
                            <span class="icon"></span> 동의합니다
                        </label>
                        <label class="agree-input__item">
                            <input type="radio" class="agree-input__radio" name="agree1" checked>
                            <span class="icon"></span> 동의하지 않습니다
                        </label>
                    </div>
                </div>

                <!-- 개인정보 수집·이용 내역(선택) -->
                <div>
                    <div class="contents text-box type2">
                        <h3 class="text-box__tit">개인정보 수집·이용 내역 <span>(선택)</span></h3>
                        <div class="text-box type2">
                            <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                <h5 class="text-box__tit">1. 수집 목적</h5>
                                <p class="text-box__desc">입사지원 서비스 이용(채용 전형 진행, 합격 여부 확인, 이력서 작성 및 조회/수정 등)</p>

                                <h5 class="text-box__tit">2. 수집 항목</h5>
                                <p class="text-box__desc">성명(국문), 성명(영문), 성별, 생년월일, 사진, 휴대전화, 이메일, 응급연락망, 주민등록지(등본주소지), 현주소, 비밀번호, 학력사항</p>

                                <h5 class="text-box__tit">3. 보유 및 이용 기간</h5>
                                <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">서류 마감일 기준 1년 후</p>
                                <br>
                                <p class="small-text">※ 관련 법률에 근거한 우대 지원을 받지 못할 수 있으며, 업무 적합성 판단이 불가할 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                    <div class="agree-input">
                        <label class="agree-input__item">
                            <input type="radio" class="agree-input__radio" name="agree1">
                            <span class="icon"></span> 동의합니다
                        </label>
                        <label class="agree-input__item">
                            <input type="radio" class="agree-input__radio" name="agree1" checked>
                            <span class="icon"></span> 동의하지 않습니다
                        </label>
                    </div>
                </div>

                <!-- 민감정보 수집에 대한 동의 (선택) -->
                <div>
                    <div class="contents text-box type2">
                        <h3 class="text-box__tit">민감정보 수집에 대한 동의 <span>(선택)</span></h3>
                        <div class="text-box type2">
                            <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                <h5 class="text-box__tit">1. 수집 목적</h5>
                                <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">부서 배치 적합도 파악</p>

                                <h5 class="text-box__tit">2. 수집 항목</h5>
                                <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">장애여부</p>

                                <h5 class="text-box__tit">3. 보유 및 이용 기간</h5>
                                <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">서류 마감일 기준 1년 후</p>
                                <br>
                                <p class="small-text">※ 지원자는 개인정보 수집·이용에 동의를 거부할 권리가 있으며, 동의를 거부할 경우 지원에 불이익이 없음을 알려드립니다.</p>
                            </div>
                        </div>
                    </div>
                    <div class="agree-input">
                        <label class="agree-input__item">
                            <input type="radio" class="agree-input__radio" name="agree1">
                            <span class="icon"></span> 동의합니다
                        </label>
                        <label class="agree-input__item">
                            <input type="radio" class="agree-input__radio" name="agree1" checked>
                            <span class="icon"></span> 동의하지 않습니다
                        </label>
                    </div>
                </div>
            </div>

            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn">가입취소</a>
                <a href="javascript:;" class="btn blue">다음단계</a>
            </div>


        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>

</style>