import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import store from '@/store/store';

const methods = {
    /**
     * 날자 형식으로 리턴
     * ref) $makeDateFormat(20140101);  // 2014.01.01
     * @param YYYYMMDD
     * @returns YYYY.MM.DD
     */
    makeDateFormat(strDate, delimiter) {

        if (isNaN(strDate) || strDate == null) {
            strDate = "";
        }

        var size = strDate.length;
        if (size >= 6) {
            strDate = strDate.substring(0, 4) + delimiter + strDate.substring(4, 6) + delimiter + strDate.substring(6);
        } else if (size >= 4) {
            strDate = strDate.substring(0, 4) + delimiter + strDate.substring(4);
        }

        return strDate;
    },
    /**
     * Date to String
     * ref) $dateToStr(new Date(), '.');  // 2014.01.01
     * @param Date, Delimiter
     * @returns YYYY.MM.DD ...
     */
    dateToStr(d,dot){
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();
        return `${year}`+dot+`${month >= 10 ? month : '0' + month}`+dot+`${date >= 10 ? date : '0' + date}`;
    },

    getMenuName(menuList) {
        let depth = 0;
        let menuName = '';
        let path = window.location.pathname

        if(path.indexOf('/view.do') > -1) {
            path = path.replace('/view.do', '/list.do');
        }
        if(path.indexOf('View.do') > -1) {
            path = path.replace('View.do', 'List.do');
        }

        for(var i = 0; i < menuList.length; i++) {
            if(menuList[i].menuUrl == path && menuList[i].depth > depth) {
                depth = menuList[i].depth;
                menuName = menuList[i].menuName;
            }
        }

        return menuName
    },

    getMenuPath(menuList) {
        let path = window.location.pathname

        if(path.indexOf('/view.do') > -1) {
            path = path.replace('/view.do', '/list.do');
        }
        if(path.indexOf('View.do') > -1){
            path = path.replace('View.do', 'List.do');
        }
        if(path.indexOf('Detail.do') > -1){
            path = path.replace('Detail.do', '.do');
        }
        if(path.indexOf('Complete.do') > -1){
            path = path.replace('Complete.do', '.do');
        }

        return path
    },

    timestampToDateFormate(timestamp, format, delimiter) {
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();

        if(timestamp == null || timestamp == ''){
            return '';
        }else{
            return year + delimiter + (month < 10 ? '0' + month : month) + delimiter + (day < 10 ? '0' + day : day);
        }
    },

    /**
     * timestamp to date
     * ref) $timestampToDate(1613491200000);  // 2021.02.16
     * @param timestamp
     * @returns YYYY.MM.DD
     */
    timestampToDate(timestamp) {
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();

        if(timestamp == null || timestamp == ''){
            return '';
        }else{
            return year + '.' + (month < 10 ? '0' + month : month) + '.' + (day < 10 ? '0' + day : day);
        }
    },

    timestampToDateTime(timestamp) {
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();

        if(timestamp == null || timestamp == ''){
            return '';
        }else{
            return year + '.' + (month < 10 ? '0' + month : month) + '.' + (day < 10 ? '0' + day : day) + ' ' + (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute);
        }
    },

    timestampToYear(timestamp) {
        var date = new Date(timestamp);
        var year = date.getFullYear();
        return year
    },

    timestampToMonthDay(timestamp) {
        var date = new Date(timestamp);
        var month = date.getMonth() + 1;
        var day = date.getDate();

        return (month < 10 ? '0' + month : month) + '.' + (day < 10 ? '0' + day : day);
    },
    removeTags(html) {
        if (!html) return '';

        // HTML 엔터티 변환
        const entities = {
            '&nbsp;': ' ',
            '&lt;': '<',
            '&gt;': '>',
            '&amp;': '&',
            '&quot;': '"',
            '&apos;': "'",
            '&#39;': "'",
            '&lsquo;': '‘',
            '&rsquo;': '’',
            '&ldquo;': '“',
            '&rdquo;': '”',
            '&hellip;': '…',
            '&mdash;': '—',
            '&ndash;': '–',
            '&bull;': '•',
            '&prime;': '′',
            '&Prime;': '″',
            '&permil;': '‰'
            // 필요한 추가 엔터티를 여기에 추가하세요
        };

        // 엔터티를 변환하여 특수 문자를 처리
        let text = html.replace(/&[^;]+;/g, (match) => entities[match] || match);

        // HTML 태그 제거
        text = text.replace(/<\/?[^>]+(>|$)/g, "");

        // 특수 문자를 제거 (캐리지 리턴과 뉴라인을 제거)
        text = text.replace(/[\r\n]+/g, ' ').trim();

        text = text.replace(/&[^;]+;/g, (match) => entities[match] || match);

        return text;
    },
    getFirstImgUrl(html) {
        if (!html) return '';

        const regex = /<img.*?src="(.*?)"/;
        const result = regex.exec(html);

        return result ? result[1] : '';
    },
    dupArr(arr){
        return arr.filter((e, i) => {
            return arr.indexOf(e) === i;
        })
    },
    fileDownload(no, name) {
        axios({
            url: `/downloadFile.do?attachNo=${no}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then(res => {
                var fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', `${name}`);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            })
    },
    getTitle(html){
        if (!html) return '';

        return html.title
    },
    /**
     * array 내의 object의 key 값을 기준으로 그룹화
     * ref) this.$commonUtils.groupArrayByKey(Array, key);
     * @param Array, Key(String)
     * @returns [{s: [...]}]
     */
    groupArrayByKey(array, s) {        
        const object = array.reduce((acc, obj) => {
            const oK = obj[s];
            if (!acc[oK]) {
                acc[oK] = [];
            }
            acc[oK].push(obj);
            return acc;
        }, {});

        const result = Object.keys(object).map(key => ({
            [key]: object[key]
        }));
    
        return result;
    },
    maskingEmail(email){
        if(!email){
            return '';
        }
        const arr = email.split("@");
        const id = arr[0];
        const domain = arr[1];
        const len = id.length
    
        let masking;
        if (id.length > 2) {
            masking = id.substring(0, 3) + "*".repeat(len - 3);
        }else{
            masking = id
        }
    
        return masking + "@" + domain;
    },
    maskingId(id){
        if(!id){
            return '';
        }
        return id.substring(0, 3) + "*".repeat(id.length - 3);ㄴ
    },
    getConsonant(str) {
        if(!str) return ''

        const cho = ["ㄱ","ㄲ","ㄴ","ㄷ","ㄸ","ㄹ","ㅁ","ㅂ","ㅃ","ㅅ","ㅆ","ㅇ","ㅈ","ㅉ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ"]
        let result = ''
        let code = str[0].charCodeAt() - 44032
        if(code > -1 && code < 11172){
            let index = Math.floor(code / 588)
            if (index >= 1 && index <= 13) {
                if (index === 1) index = 0; // ㄲ -> ㄱ
                else if (index === 4) index = 3; // ㄸ -> ㄷ
                else if (index === 8) index = 7; // ㅃ -> ㅂ
                else if (index === 10) index = 9; // ㅆ -> ㅅ
                else if (index === 13) index = 12; // ㅉ -> ㅈ
            }
            result = cho[index];
        }

        return result;
    },
    setConsonantList(list, key){
        if(!list) return []

        let consonantList =  [
            {cho:'ㄱ', disable: 'disable'},
            {cho:'ㄴ', disable: 'disable'},
            {cho:'ㄷ', disable: 'disable'},
            {cho:'ㄹ', disable: 'disable'},
            {cho:'ㅁ', disable: 'disable'},
            {cho:'ㅂ', disable: 'disable'},
            {cho:'ㅅ', disable: 'disable'},
            {cho:'ㅇ', disable: 'disable'},
            {cho:'ㅈ', disable: 'disable'},
            {cho:'ㅊ', disable: 'disable'},
            {cho:'ㅋ', disable: 'disable'},
            {cho:'ㅌ', disable: 'disable'},
            {cho:'ㅍ', disable: 'disable'},
            {cho:'ㅎ', disable: 'disable'}
        ]
        for(let i of consonantList){
            for(let j of list){
                if(i.cho == this.getConsonant(j[key])){
                    i.disable = ''
                }
            }
        }
        return consonantList
    },
    listToConList(list, oK, key){
        let result = []
        if(!list) return result

        for(let i of list){
            if(this.getConsonant(i[oK]) == key){
                result.push(i)
            }
        }
        return result
    },
    objArrSort(list, key, order){
        const num = order == 'ASC' ? 1 : -1
        list.sort(function (a, b) {
            // ASC인 경우 빈 값은 뒤로 배치
            if (!a[key]) return 1 * num
            if (!b[key]) return -1 * num

            if (a[key] > b[key]) {
                return 1 * num
            }
            if (a[key] < b[key]) {
                return -1 * num
            }
            return 0
        })
    },
    leftPad(value) {
        if (value < 10) {
            value = "0" + value;
            return value;
        }
        return value;
    },
    goMenu(url){
        router.push(url)
    },
    goArticle(article) {
        if (article.linkUrl != '' && article.linkUrl != null) {
            if (article.linkMethod == '_blank') {
                window.open(article.linkUrl);
            } else {
                location.href = article.linkUrl;
            }
        } else {
            alert('올바르지 않은 게시글입니다.')
        }
    },
    setPhoneNumber(phone) {
        let pNo = ('' + phone).replace(/\D/g, '');

        if (pNo.length === 9) {
            let result = pNo.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
            return result;
        }
        if (pNo.length === 10) {
            let result = pNo.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            return result;
        }
        if (pNo.length === 11) {
            let result = pNo.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
            return result;
        }
        return phone;
    },
    /*
    * 공백, null 체크
    */
    isEmpty(str) {
        if (str == null || str == undefined || str == '') {
            return true;
        }
        return false;
    },
    isNotEmpty(str) {
        return !this.isEmpty(str);
    },
    getToday() {
        //return moment(value).format('YYYY-MM-DD');
        var delimiter = "";
        var today = new Date();
        var year = today.getFullYear();
        var month = ('0' + (today.getMonth() + 1)).slice(-2);
        var day = ('0' + today.getDate()).slice(-2);
        return year + delimiter + month + delimiter + day;
    },
    /**
     * 특정 날짜에 대해 지정한 값만큼 가감(+-)한 날짜를 반환
     * ref : this.$addDate(aType, aDay, aDate)
     *       20130304 로부터 2달뒤 ==> this.$addDate("m", 2, "20130304");
     * @param aType 가감타입 : y(연도), m(월),  d(일), md(월가감 날짜까지 표현)
     * @param aDay 가감일
     * @param aDate 가감기준일
     * @returns {String}
     */
    addDate(aType, aDay, aDate) {
        var delimiter = "";
        var yyyy;
        var mm;
        var dd;
        var cDate;
        var cYear, cMonth, cDay;

        if (aDate == undefined) {
            aDate = dateUtil.getDate();
        }

        aDate = aDate.replace(/[\-]/gi, "");

        yyyy = aDate.substr(0, 4);
        mm = aDate.substr(4, 2);
        dd = aDate.substr(6, 2);

        if (aType == "y") {
            yyyy = (yyyy * 1) + (aDay * 1);
        } else if (aType == "m" || aType == "md") {
            mm = (mm * 1) + (aDay * 1);
        } else if (aType == "d") {
            dd = (dd * 1) + (aDay * 1);
            if (aDay < 0) dd++;
            else dd--;
        }

        cDate = new Date(yyyy, mm - 1, dd); // 12월, 31일을 초과하는 입력값에 대해 자동으로 계산된 날짜가 만들어짐.
        cYear = cDate.getFullYear();
        cMonth = cDate.getMonth() + 1;
        cDay = cDate.getDate();

        cMonth = cMonth < 10 ? "0" + cMonth : cMonth;
        cDay = cDay < 10 ? "0" + cDay : cDay;

        return cYear + delimiter + cMonth + delimiter + cDay;
    },
    getRecruitCodeName(codeType, codeNo) {
        let codeName = '';

        if (codeType === 'type') {
            for (const baseCode of store.state.baseCodeStore.recruitTypeList) {
                if (baseCode.codeNo === codeNo) {
                    codeName = baseCode.codeName
                }
            }
        } else if (codeType === 'category') {
            for (const baseCode of store.state.baseCodeStore.recruitCategoryList) {
                if (baseCode.codeNo === codeNo) {
                    codeName = baseCode.codeName
                }
            }
        } else if (codeType === 'categoryDetail') {
            for (const baseCode of store.state.baseCodeStore.recruitCategoryDetailList) {
                if (baseCode.codeNo === codeNo) {
                    codeName = baseCode.codeName
                }
            }
        }

        return codeName;
    },
    validateEmail : function(value) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    },
    validateDomail : function(value) {
        var re = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
        return re.test(value);
    },
    validateTel : function(value) {
        var re = /^(0(2|3[1-3]|4[1-4|9]|5[1-5]|6[1-4]|70))-(\d{3,4})-(\d{4})$/;
        return re.test(value);
    },
    numberOnly(event) {
        const value = event.target.value;
        if (!/^\d*$/.test(value)) {
            event.target.value = value.replace(/\D/g, '');
        }
    },
    decimalOnly(event) {
        const value = event.target.value;
        if (!/^\d*\.?\d*$/.test(value)) {
            // 첫 번째 소수점 이후에 나오는 소수점은 제거합니다.
            const filteredValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            event.target.value = filteredValue;
        }
    },
    isExpose(exposeEndDt) {
        var inputDate = new Date(exposeEndDt);
        var currentDate = new Date();

        return !exposeEndDt || (inputDate > currentDate);
    },
    inputXssReplace(event) {
        event.target.value = event.target.value.replace(/[()<>{}[\]\\\/`'"$%&]/g, '')
    },
}

export default{
    install(Vue){
        Vue.config.globalProperties.$commonUtils = methods;

    }
}