<template>
    <div class="wrap" @click="selectOn">
        <div class="main-container">
            <div class="kv">
                <ul class="kv__tab-list">
                    <li class="kv__tab-item">
                        <router-link to="/application/editApplication.do">
                            <span class="text">진행현황 수정/조회</span>
                            <span class="icon"><img src="@/assets/resource/icon/kv-icon3.png" alt=""></span>
                        </router-link>
                    </li>
                    <li class="kv__tab-item">
                        <router-link to="/inquiry/checkResult.do">
                            <span class="text">창원 파티마 합격자 조회</span>
                            <span class="icon"><img src="@/assets/resource/icon/kv-icon4.png" alt=""></span>
                        </router-link>
                    </li>
                </ul>
            </div>

            <!-- board -->
            <div class="main-board" id="main-board">
                <div class="main-board__bg">
                    <p class="top">FATIMA</p>
                    <p class="bottom">RECRUITMENT</p>
                </div>
                <div class="inner">
                    <div class="sub-tit">
                        <h2>진행 중인 채용공고</h2>
                    </div>
                    <Search :searchArr="searchArr" @setState="setState"></Search>
                    <div class="tab-menu type01">
                        <ul class="tab-menu__list">
                            <li class="tab-menu__item" :class="{active : !rnCategory}">
                                <button type="button" class="tab-menu__btn" @click="setRecruitCategory('')">전체</button>
                            </li>
                            <li class="tab-menu__item" :class="{active : recruitCategory.codeNo === rnCategory}" v-for="(recruitCategory, idx) in recruitCategoryList" :key="idx">
                                <button type="button" class="tab-menu__btn" @click="setRecruitCategory(recruitCategory.codeNo)">{{recruitCategory.codeName}}</button>
                            </li>
                        </ul>
                    </div>

                    <ul class="main-board__list contents">
                        <li class="main-board__item" v-for="(recruitNotice) in recruitNoticeList" :key="recruitNotice.rnNo">
                            <router-link :to="`/application/noticeDetail.do?rnNo=${recruitNotice.rnNo}`" class="main-board__link">
                                <span class="text">
                                    <span class="type">
                                        <span class="name" v-if="recruitNotice.noticeYn === 'Y'">공지</span>
                                        <span class="new" v-if="parseInt((new Date().getTime() - recruitNotice.createdDt) / 1000 / 60 / 60 / 24) <= 7">N</span>
                                    </span>
                                    <span class="tit">{{recruitNotice.rnTitle}}</span>
                                </span>
                                <span class="info">
                                    <span class="date">{{$commonUtils.timestampToDate(recruitNotice.createdDt)}}</span>
                                    <span class="more">VIEW MORE +</span>
                                </span>
                            </router-link>
                        </li>
                    </ul>
                    <div class="contents btn-wrap center" v-if="recruitNoticeCnt > pageRow">
                        <a href="javascript:;" class="btn blue" @click="moreRecruitNoticeList">더보기</a>
                    </div>
                </div>
            </div>

            <div class=" main-info">
                <div class="inner">
                    <ul class="main-info__list">
                        <li class="main-info__item">
                            <router-link to="/system/talentIdeal.do">
                                <span class="tit"><img src="../../assets/resource/icon/info-icon1.png" alt="">파티마 인재상 / 비전</span>
                                <span class="desc">
                                    고객중심에서 상호협력을 하고 <br>
                                    전문성 있게 고객의 평생건강을 지키는 <br>
                                    정직한 동반자를 기다립니다.
                                </span>
                            </router-link>
                        </li>
                        <li class="main-info__item">
                            <router-link to="/system/welfareBenefits.do">
                                <span class="tit"><img src="../../assets/resource/icon/info-icon2.png" alt="">복리후생</span>
                                <span class="desc">
                                    직원 여러분의 행복과 만족을 <br>
                                    최우선으로 복리후생을 통해 건강하고 <br>
                                    균형 잡힌 삶을 지원합니다.
                                </span>
                            </router-link>
                        </li>
                        <li class="main-info__item">
                            <router-link to="/inquiry/directions.do">
                                <span class="tit"><img src="../../assets/resource/icon/info-icon3.png" alt="">오시는길</span>
                                <span class="desc">
                                    창원파티마병원으로 <br>
                                    오시는 길을 안내드립니다.
                                </span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- end -->
        </div>
    </div>
</template>


<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Search from '../common/SearchBox.vue'

export default {
        components: {
            Search,
        },
        data() {
            return {
                // 검색박스 셀렉트 옵션 리스트
                searchArr: [
                    { 'title': '전체', 'value': '' }
                    , { 'title': '제목', 'value': 'Title' }
                    , { 'title': '내용', 'value': 'Content' }
                ],
                searchOption: '',
                searchKeyword: '',

                recruitCategoryList: this.$store.state.baseCodeStore.recruitCategoryList,
                rnCategory: '',
                pageRow: 6,
                recruitNoticeCnt: 0,
                recruitNoticeList: [],
            }
        },
        setup() {
        },
        methods: {
            async setRecruitCategory(recruitCategory) {
                this.rnCategory = recruitCategory
                // this.pageRow = 6

                await this.getRecruitNoticeList()
            },
            async getRecruitNoticeList() {
                // this.recruitNoticeCnt = 0
                // this.recruitNoticeList = []

                const params = {
                    rnCategory : this.rnCategory,
                    pageRow : this.pageRow,
                    exposeCheckType : 'C',
                }
                if (this.searchOption) {
                    params[this.searchOption] = this.searchKeyword;
                }

                await this.axios.get(this.api.recruit.notice.list, {
                    params: params
                }).then(res => {
                    // console.log(res.data)
                    this.recruitNoticeCnt = res.data.totCnt
                    this.recruitNoticeList = res.data.list
                });
            },
            async moreRecruitNoticeList() {
                this.pageRow = this.pageRow + 6
                await this.getRecruitNoticeList()
            },
            async setState(searchOption, searchKeyword) {
                this.searchOption = searchOption ? 'rn' + searchOption : 'searchKeyword'
                this.searchKeyword = searchKeyword

                await this.getRecruitNoticeList()
            }
        },
    async mounted() {
        setTimeout(() => {
            gsap.registerPlugin(ScrollTrigger);
            gsap.from('.main-board .sub-tit h2', { scrollTrigger: { trigger: '.main-board', start: 'top center', end: '-100%', scrub: 4, }, y: 30, opacity: 0 });
            gsap.from('.board__search', { scrollTrigger: { trigger: '.main-board', start: 'top center', end: '-90%', scrub: 5, }, y: 30, opacity: 0 });
            gsap.from('.main-board__bg .top', { scrollTrigger: { trigger: '.main-board', start: 'top center', end: '-50%', scrub: 5, }, x: 200, opacity: 0 });
            gsap.from('.main-board__bg .bottom', { scrollTrigger: { trigger: '.main-board', start: '60% center', end: '-50%', scrub: 5, }, x: -200, opacity: 0 });
            gsap.from('.tab-menu__list', { scrollTrigger: { trigger: '.main-board', start: 'top center', end: '-50%', scrub: 5, }, x: 200, opacity: 0 });
            gsap.from('.main-board__list', { scrollTrigger: { trigger: '.main-board', start: '0% center', end: '-50%', scrub: 5}, y: 50, opacity: 0 });
            gsap.from('.main-info__list', { scrollTrigger: { trigger: '.main-info__list', start: 'top bottom', end: '-100%', scrub: 5, }, x: 200, opacity: 0 });
        }, 100);

            await this.getRecruitNoticeList()
        },
    beforeUnmount() {
            let triggers = ScrollTrigger.getAll();
            triggers.forEach(trigger => {
                trigger.kill();
            });
        }
}
</script>

<style scoped>
    @media (max-width:1440px) {
        .main-info__item a .tit {font-size: 15px}
        .main-info__item a .desc {display: none}
    }
</style>