<template>
    <Header></Header>
    <Main></Main>
    <Footer class="list-show"></Footer>
</template>

<script>
    import Header from '@/components/common/Header.vue';
    import Main from '@/components/main/Main.vue';
    import Footer from '@/components/common/Footer.vue';

    // Main,
    export default {
        components: {
            Header,
            Main,
            Footer
        }
    }
</script>
<style scoped>
@import url('../../assets/resource/css/main.css');
@import url('../../assets/resource/css/sub.css');

.header { position: fixed; }
.LocationBar { position: fixed; }
.container { padding-top: 100px; }
</style>