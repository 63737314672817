<template>
    <Header></Header>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home">
                    <a href="" class="Location__item--btn">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQ4ja3TP0sDQRAF8J+HiGAKLQVFsbIQ4p/SRrDKB0hhZyXYiFjnQ9jZCJYigo2CRUDBNkr8ArGytRAUooVaZALHceeZ4IOFtzPz3s4uOyP1el0JFnAafAtPvxUnJWZ1tPEYqx2xQowWxMdxGB3t4CziNzjGJvbR/UuHi2hhDaspM8FXI9eK2l8Nt3GPJtbRyTmwE7lm1G6nk/0rV3CEmt41L3OM0vjEAW5xovcEu3hLsIwHzAcvM0vjMjTz4bGcROvn2MDzAGZ9PIf2HM0EM2jgAtUhDKuhbWB2FB+RmMNUjmAEK8Hb+M7kp0IL3aJ/2McErjOGNbwXCcomZVfvB0zHqkSsEGUdLuEKb7G/ilghyjqErwI+tOFA+HfD7BtWMJnaj+VoxjI1lSLDV/ljt5fiL3qzvpWpueuTH1quOlOE3j3aAAAAAElFTkSuQmCC" alt="">
                    </a>
                </li>
                <li class="Location__item">
                    <a href="javascript:;" class="Location__item--btn">
                        <p>입사지원</p><span></span>
                    </a>
                </li>
                <li class="Location__item">
                    <a href="javascript:;" class="Location__item--btn">
                        <p>수정/조회</p><span></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="sub-tit"><h2>메뉴명</h2></div>
        <div class="inner">
            <div class="input-form type1">
                <div class="input-form__top-wrap">
                    <p class="input-form__top-text type2">- 온라인 입사지원시 브라우저(익스, 크롬등) 당 1개의 채용공고만 지원해주시기 바랍니다.(동일 브라우저에서 한개이상 지원불가)</p>
                    <p class="input-form__top-text type2">- 개인정보 보호를 위해 일정시간(30분) 동안 입력이 없을 경우 작성한 내용이 자동삭제 됩니다.</p>
                    <p class="input-form__top-text type2">- 지원서 작성 시 이전 페이지로 강제이동시 입력 오류가 발생할 수 있으니 주의 바랍니다.</p>
                    <p class="input-form__top-text type2">- 접수종료된 공고는 출력만 가능합니다.</p>
                </div>
                <ul class="input-form__list mt20">
                    <li class="input-form__item">
                        <div class="input-form__box email">
                            <div class="tit">채용구분</div>
                            <div class="cont row">
                                <select class="cont__select full">
                                    <option value="선택">선택</option>
                                    <option value="공개채용">공개채용</option>
                                    <option value="공개채용">일반채용</option>
                                </select>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box email">
                            <div class="tit">접수/수정기간</div>
                            <div class="cont">
                                <p>2024.06.21
                                    ~
                                    2024.07.21
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">성명</div>
                            <div class="cont">
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">생년월일</div>
                            <div class="cont">
                                <input type="text" class="cont__text-input">
                                <p class="desc">주민등록상의 6자리 생년월일을 입력하여 주시기 바랍니다. ex) 800821</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box phon">
                            <div class="tit">휴대전화</div>
                            <div class="cont row">
                                <input type="text" class="cont__text-input"> <span class="line">-</span>
                                <input type="text" class="cont__text-input"> <span class="line">-</span>
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">비밀번호</div>
                            <div class="cont">
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                    <button class="btn">비밀번호 찾기</button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box email">
                            <div class="tit">접수/수정기간</div>
                            <div class="cont">
                                <p>02-2258-5527</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn blue">입사지원서 조회</a>
                <a href="javascript:;" class="btn blue">입사지원서 출력</a>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script>
    import Header from "@/components/common/Header.vue";
    import Footer from "@/components/common/Footer.vue";

    export default {
        components: {
            Header,
            Footer
        },
        mounted() {
        },
    }
</script>
<style>

</style>