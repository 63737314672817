import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import baseCodeStore from './baseCodeStore'
import applicantStore from './applicantStore'

export default createStore({
    plugins: [
        createPersistedState({
            paths: ['baseCodeStore', 'applicantStore']
        })
    ],
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        /**
         * 회원가입
         */        
        baseCodeStore: baseCodeStore,
        applicantStore: applicantStore,
    }
})
