<template>
    <div class="container">
        <div class="sub-tit">
            <h2>채용공고</h2>
        </div>
        <div class="inner">
            <div class="board-view">
                <div class="board-view__top">
                    <ul class="board-view__share-list" :class="{active : this.shareList}">
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/copy-icon.png" alt=""></a></li>
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/band-icon.png" alt=""></a></li>
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/x-icon.png" alt=""></a></li>
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/facebook-icon.png" alt=""></a></li>
                    </ul>
                    <button type="button" class="board-view__btn share" @click="shareFn()"></button>
                    <button type="button" class="board-view__btn print" @click="print()"></button>
                </div>
                <div class="table-cont">
                    <ul class="table-list type02">
                        <li class="table-item">
                            <div class="item tit">채용구분</div>
                            <div class="item">{{ $commonUtils.getRecruitCodeName('type', noticeInfo.rnType) }}</div>
                        </li>
                        <li class="table-item">
                            <div class="item tit">부서</div>
                            <div class="item">{{ noticeInfo.rnDepartment }}</div>
                        </li>
                        <li class="table-item">
                            <div class="item tit">직종</div>
                            <div class="item" v-if="noticeInfo.rnCategoryDetail">[{{$commonUtils.getRecruitCodeName('category', noticeInfo.rnCategory)}}] {{$commonUtils.getRecruitCodeName('categoryDetail', noticeInfo.rnCategoryDetail)}}</div>
                            <div class="item" v-else>{{$commonUtils.getRecruitCodeName('category', noticeInfo.rnCategory)}}</div>
                        </li>
                        <li class="table-item">
                            <div class="item tit">게시기간</div>
                            <div class="item">{{ $commonUtils.timestampToDateTime(noticeInfo.exposeStartDt) }} ~ {{ $commonUtils.timestampToDateTime(noticeInfo.exposeEndDt) }}</div>
                        </li>
                    </ul>
                </div>
                <div class="board-view__wrap" style="margin-top: 0px">
                    <div class="board-view__tit">
                        <h3>{{ noticeInfo.rnTitle }}</h3>
                        <div class="board-view__info">
                            <span class="date">{{ $commonUtils.timestampToDate(noticeInfo.createdDt) }}</span>
                            <div class="view">
                                <span class="icon"></span>
                                {{ noticeInfo.readCnt }}
                            </div>
                        </div>
                    </div>
                    <div class="board-view__file" v-if="noticeInfo && JSON.stringify(noticeInfo.attachBag) !== '{}'">
                        <div class="tit">첨부파일</div>
                        <ul class="file-list">
                            <li class="file-item" v-for="(i, idx) in attachList" :key="idx">
                                <button type="button" @click="this.$commonUtils.fileDownload(i.attachNo, i.displayName)">
                                    <span class="icon"></span><em class="num">{{ idx + 1 }} .</em> {{ i.displayName }}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!-- 컨텐츠 내용 -->
                    <div class="board-view__cont">
                        <div v-html="noticeInfo.rnContent" class="board-view__cont-inner"></div>
                    </div>
                    <div class="contents btn-wrap center">
                        <router-link to="/" class="btn gray" @click="gsapReset">목록</router-link>
                        <router-link :to="{ path: '/application/applicationAgree.do', query: { rnNo: this.rnNo }}" class="btn blue" v-if="isValidTime">지원서 작성하기</router-link>
                        <a @click="showValidAlert" class="btn gray" v-else>지원서 작성하기</a>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>


<script>
export default {
    data() {
        return {
            shareList: false,
            noticeInfo: {},
            attachList: [],
            rnNo: this.$route.query.rnNo,
        };
    },
    watch: {
    },
    methods: {
        gsapReset() {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        },
        shareFn() {
            this.shareList = !this.shareList
        },
        getRecruitNoticeView() {
            this.axios
                .get(this.api.recruit.notice.view, {
                    params: {
                        rnNo: this.rnNo,
                    }
                })
                .then((res) => {
                    this.noticeInfo = res.data
                    if(this.noticeInfo) this.attachList = res.data.attachBag.attach;
                    if(this.noticeInfo == '' || !this.$commonUtils.isExpose(this.noticeInfo.exposeEndDt)){
                        alert('종료되었거나 조회되지 않는 게시글입니다.')
                        this.$router.push('/')
                    }
                });
        },
        print() {
            window.print()
        },
        showValidAlert() {
            alert("지원서는 2024.09.02(월) 00시부터 작성이 가능합니다.")
        }
    },
    computed: {
        isValidTime() {
            let isView = true

            let viewDate = new Date('2024-09-02 00:00:00')
            let currentDate = new Date()

            if (this.rnNo === '37' && viewDate > currentDate) {
                isView = false
            }

            return isView
        },
    },
    async mounted() {
        // console.log(this.rnNo)
        if (this.rnNo) {
            this.getRecruitNoticeView()
        } else {
            alert("올바르지 않은 접근입니다.")
            this.$router.back()
        }
    },
};
</script>