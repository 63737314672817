<template>
    <div class="container">
        <div class="sub-tit">
            <h2>결과확인</h2>
        </div>
        <div class="inner">
            <div class="input-form type1" ref="selectDiv" v-if="!passStatus">
                <ul class="input-form__list mt20">
                    <li class="input-form__item">
                        <div class="input-form__box email">
                            <div class="tit">채용구분</div>
                            <div class="cont row">
                                <select class="cont__select full" v-model="recruitNoticeInfo">
                                    <option value="">선택</option>
                                    <option v-for="(recruitNotice) in recruitNoticeList" :key="recruitNotice.rnNo" :value="recruitNotice">{{recruitNotice.rnTitle}}</option>
                                </select>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">성명</div>
                            <div class="cont">
                                <input type="text" class="cont__text-input" name="raName" v-model="searchInfo.raName">
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">생년월일</div>
                            <div class="cont">
                                <div class="date_wrap" style="margin: 0;">
                                    <div class="date-input">
                                        <DatePicker v-model="searchInfo.raBirth" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                    week-start="0" :model-type="dateFormat" :enable-time-picker="false" auto-apply year-first :flow="['year', 'month', 'calendar']"/>
                                        <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                                <!--<input type="text" class="cont__text-input" v-model="searchInfo.raName">-->
                                <!--<p class="desc">주민등록상의 6자리 생년월일을 입력하여 주시기 바랍니다. ex) 800821</p>-->
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box phon">
                            <div class="tit">휴대전화</div>
                            <div class="cont row">
                                <input type="text" class="cont__text-input" name="raHpNo1" v-model="raHpNo1"> <span class="line">-</span>
                                <input type="text" class="cont__text-input" name="raHpNo2" v-model="raHpNo2"> <span class="line">-</span>
                                <input type="text" class="cont__text-input" name="raHpNo3" v-model="raHpNo3">
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">비밀번호</div>
                            <div class="cont">
                                <div class="cont-item">
                                    <input type="password" class="cont__text-input" v-model="searchInfo.raPwd" :emptyValid="isFindPwd ? true : null">
                                    <button class="btn" @click="findPwd">비밀번호 찾기</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="contents btn-wrap center" v-if="!passStatus">
                <a href="javascript:;" class="btn blue" @click="getRecruitApplicantView">결과확인</a>
            </div>


            <div class="input-form type1" v-if="passStatus">
                <ul class="input-form__list mt20">
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">채용구분</div>
                            <div class="cont">
                                <p type="text" class="cont__text-input noborder">{{getRecruitNoticeName}}</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">성명</div>
                            <div class="cont">
                                <p type="text" class="cont__text-input noborder" name="raName">{{applicantInfo.raName}}</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">생년월일</div>
                            <div class="cont">
                                <p type="text" class="cont__text-input noborder">{{applicantInfo.raBirth}}</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box phon">
                            <div class="tit">휴대전화</div>
                            <div class="cont row">
                                <p type="text" class="cont__text-input noborder" name="raHpNo1">{{applicantInfo.raHpNo1}}</p> <span class="line">-</span>
                                <p type="text" class="cont__text-input noborder" name="raHpNo2">{{applicantInfo.raHpNo2}}</p> <span class="line">-</span>
                                <p type="text" class="cont__text-input noborder" name="raHpNo3">{{applicantInfo.raHpNo3}}</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit">합격여부</div>
                            <div class="cont">
                                <div class="cont-item">
                                    <p type="text" class="cont__text-input noborder" name="raName">{{passStatus}}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="contents btn-wrap center" v-if="passStatus">
                <a href="javascript:;" class="btn blue" @click="passStatus=''">재조회</a>
            </div>
        </div>
    </div>
</template>
<script>

    import DatePicker from "@vuepic/vue-datepicker";

    export default {
        components: {
            DatePicker
        },
        data() {
            return {
                dateFormat: 'yyyy-MM-dd', // 날짜 형식

                recruitNoticeList: [],
                recruitNoticeInfo: {},
                searchInfo: {},
                // searchInfo: {rnNo : '19', raName: '박세담', raBirth: '2024-09-24', raHpNo: '01082880924', raPwd: 'qkrtpeka1!'},
                raHpNo1: '',
                raHpNo2: '',
                raHpNo3: '',
                applicantInfo: '',
                passStatus: '',
                isFindPwd: false,
            }
        },
        watch: {
            raHpNo1: {
                handler(newData) {
                    if (newData && this.raHpNo1 && this.raHpNo2 && this.raHpNo3) {
                        this.searchInfo.raHpNo = this.raHpNo1 + this.raHpNo2 + this.raHpNo3
                    } else {
                        this.searchInfo.raHpNo = ''
                    }
                },
                deep: true,
                immediate: true
            },
            raHpNo2: {
                handler(newData) {
                    if (newData && this.raHpNo1 && this.raHpNo2 && this.raHpNo3) {
                        this.searchInfo.raHpNo = this.raHpNo1 + this.raHpNo2 + this.raHpNo3
                    } else {
                        this.searchInfo.raHpNo = ''
                    }
                },
                deep: true,
                immediate: true
            },
            raHpNo3: {
                handler(newData) {
                    if (newData && this.raHpNo1 && this.raHpNo2 && this.raHpNo3) {
                        this.searchInfo.raHpNo = this.raHpNo1 + this.raHpNo2 + this.raHpNo3
                    } else {
                        this.searchInfo.raHpNo = ''
                    }
                },
                deep: true,
                immediate: true
            },
        },
        methods: {
            async getRecruitNoticeList() {

                const params = {
                    pageRow : 1000,
                    exposeCheckType : 'E',
                }

                await this.axios.get(this.api.recruit.notice.list, {
                    params: params
                }).then(res => {
                    // console.log(res.data)
                    this.recruitNoticeCnt = res.data.totCnt
                    this.recruitNoticeList = res.data.list
                });
            },
            async getRecruitApplicantView() {

                this.passStatus = ''

                this.$nextTick( async () => {
                    if (!this.isInputValidation(this.$refs.selectDiv)) {
                        return
                    }

                    const param = Object.assign({}, this.searchInfo)
                    param.rnNo = this.recruitNoticeInfo.rnNo
                    param.raPwd = btoa(this.searchInfo.raPwd)

                    await this.axios.get(this.api.recruit.applicant.view, {
                        params: param
                    }).then(res => {
                        // console.log(res.data)
                        if (res.data) {
                            this.applicantInfo = res.data
                            if (this.applicantInfo.raHpNo) {
                                this.applicantInfo.raHpNo1 = this.applicantInfo.raHpNo.substring(0, 3)
                                this.applicantInfo.raHpNo2 = this.applicantInfo.raHpNo.substring(3, 7)
                                this.applicantInfo.raHpNo3 = this.applicantInfo.raHpNo.substring(7)
                            }

                            var pass1Dt = new Date(this.recruitNoticeInfo.pass1Dt);
                            var pass2Dt = new Date(this.recruitNoticeInfo.pass2Dt);
                            var pass3Dt = new Date(this.recruitNoticeInfo.pass3Dt);
                            var currentDate = new Date();

                            console.log(pass1Dt)
                            console.log(pass2Dt)
                            console.log(pass3Dt)

                            if (pass3Dt && pass3Dt <= currentDate && this.applicantInfo.pass1Yn === 'Y' && this.applicantInfo.pass2Yn === 'Y') {
                                if (this.applicantInfo.pass3Yn === 'Y') {
                                    this.passStatus = '합격 (최종)';
                                } else if (this.applicantInfo.pass3Yn === 'N') {
                                    this.passStatus = '불합격 (최종)';
                                } else {
                                    this.passStatus = '최종합격 심사 중입니다.';
                                }
                            } else if (pass2Dt && pass2Dt <= currentDate && this.applicantInfo.pass1Yn === 'Y') {
                                if (this.applicantInfo.pass2Yn === 'Y') {
                                    this.passStatus = '합격 (2차 면접 전형)';
                                } else if (this.applicantInfo.pass2Yn === 'N') {
                                    this.passStatus = '불합격 (2차 면접 전형)';
                                } else {
                                    this.passStatus = '2차 면접 전형 심사 중입니다.';
                                }
                            } else if (pass1Dt && pass1Dt <= currentDate) {
                                if (this.applicantInfo.pass1Yn === 'Y') {
                                    this.passStatus = '합격 (1차 서류 전형)';
                                } else if (this.applicantInfo.pass1Yn === 'N') {
                                    this.passStatus = '불합격 (1차 서류 전형)';
                                } else {
                                    this.passStatus = '1차 서류 전형 심사 중입니다.';
                                }
                            }
                        } else {
                            alert("해당 지원정보가 없습니다.")
                        }
                    });
                });
            },
            async findPwd() {
                this.isFindPwd = true

                this.$nextTick( async () => {
                    if (!this.isInputValidation(this.$refs.selectDiv)) {
                        return
                    }

                    const param = Object.assign({}, this.searchInfo)
                    param.rnNo = this.recruitNoticeInfo.rnNo
                    param.raPwd = ''

                    await this.axios.get(this.api.recruit.applicant.findPassword, {
                        params: param
                    }).then(res => {
                        // console.log(res.data)
                        alert(res.data.message)
                    });
                })
            },
            isInputValidation(component) {

                let isValid = true;
                const invalidFields = [];

                const findInvalidFields = (component) => {
                    if (component.children) {
                        for (var i = 0; i < component.children.length; i++) {
                            if (component.children[i].readOnly || component.children[i].disabled || (component.children[i].attributes.emptyValid && !component.children[i].value)) {
                                continue
                            }
                            if (component.children[i].tagName === 'INPUT') {
                                if (component.children[i].type === 'radio') {
                                    if (component.children[i].attributes.emptyValid) {
                                        continue
                                    }
                                    if (!this.applicantInfo[component.children[i].name]) {
                                        invalidFields.push(component.children[i].closest('div'));
                                        continue
                                    }
                                } else if (component.children[i].type === 'text') {
                                    if (!component.children[i].value || component.children[i].value === '') {
                                        if (component.children[i].attributes["data-test"] && component.children[i].attributes["data-test"].value === 'dp-input') {
                                            invalidFields.push(component.children[i].closest('div.dp__main'));
                                        } else {
                                            invalidFields.push(component.children[i]);
                                        }
                                        continue
                                    }
                                    if (component.children[i].attributes.validType) {
                                        if (component.children[i].attributes.validType.value === 'email' && !this.$func.validateEmail(component.children[i].value)) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                        } else if (component.children[i].attributes.validType.value === 'tel' && !this.$func.validateTel(component.children[i].value)) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                        }
                                    }
                                } else if (component.children[i].type === 'password') {
                                    if (!component.children[i].value || component.children[i].value === '') {
                                        invalidFields.push(component.children[i]);
                                        continue
                                    }
                                } else if (component.children[i].type === 'file') {
                                    if (component.children[i].closest('.img-add').querySelector('img').attributes.src.value.startsWith('data:image/png;base64,iVBORw0KGgoAAAA')) {
                                        invalidFields.push(component.children[i].closest('.img-add'));
                                        continue
                                    }
                                }
                            } else if (component.children[i].tagName === 'TEXTAREA') {
                                if (!component.children[i].value || component.children[i].value === '') {
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                                if (component.children[i].minLength > 0 && component.children[i].minLength > component.children[i].value.length) {
                                    component.children[i].validMsg = 'minLength'
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                            } else if (component.children[i].tagName === 'SELECT') {
                                if (!component.children[i].value || component.children[i].value === '') {
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                            }
                            findInvalidFields(component.children[i]);
                        }
                    }
                };

                findInvalidFields(component);

                if (invalidFields.length > 0) {
                    // console.log(invalidFields[0])

                    invalidFields[0].scrollIntoView({ block: 'center' })
                    setTimeout(() => {
                        invalidFields[0].focus();
                    }, 100)

                    let objNm = invalidFields[0].closest('div.input-form__box').querySelector('div.tit').textContent;

                    objNm = objNm.replace('* ', '').trim();
                    objNm = objNm.replace('\n', '');
                    objNm = objNm.replace(/ +/g, " ");

                    if (invalidFields[0].validMsg) {
                        alert(`[${objNm}] 항목이 유효하지 않습니다. 다시 확인해주세요.`);
                    } else {
                        alert(`[${objNm}] 항목을 입력해주세요.`);
                    }

                    isValid = false;
                }

                return isValid;
            },
        },
        computed: {
            getRecruitNoticeName() {
                let noticeName = '';

                for (const recruitNotice of this.recruitNoticeList) {
                    if (recruitNotice.rnNo === this.applicantInfo.rnNo) {
                        noticeName = recruitNotice.rnTitle
                    }
                }
                return noticeName;
            },
        },
        mounted() {
            this.getRecruitNoticeList()
        },
    }
</script>
<style>

</style>