<!-- 전송페이지 공통 -->
<template>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">개인정보입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">학력 및 경력사항 등 입력</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">자기소개서 작성</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">입사지원 완료</div>
            </li>
        </ul>
    </div>
    <div class="input-form type1">
        <div class="text-box type1 info-text mt20">
            <div class="input-form__top-wrap">
                <p class="input-form__top-text type2">- 개인정보 보호를 위해 일정시간(120분) 동안 입력이 없을 경우 작성한 내용이 자동삭제 됩니다. </p>
                <p class="input-form__top-text type2">- 기존 작성된 내용을 입력하시는 경우, 메모장에 붙여넣기 하신 후 진행하여 주시기 바랍니다.</p>
            </div>
        </div>
    </div>

    <div class="contents">
        <div class=" input-form type1">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet">
                    <span class="bullet01"></span>
                </div>
                <div class="depth__text">
                    <h4 class="depth01__title title">자기소개</h4>
                </div>
            </div>
            <ul class="input-form__list">


                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit" style="display: flex; align-items: center;">성장과정 및 학교생활</div>
                        <div class="cont">
                            <p class="small-text">0/500자</p>
                            <textarea name="" id=""></textarea>
                        </div>
                    </div>
                </li>

                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit" style="display: flex; align-items: center;">지원한 분야와 <br>
                            관련한 자신의 장점 및 보완점</div>
                        <div class="cont">
                            <p class="small-text">0/500자</p>
                            <textarea name="" id=""></textarea>
                        </div>
                    </div>
                </li>
                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit" style="display: flex; align-items: center;">성격 및 특기사항</div>
                        <div class="cont">
                            <p class="small-text">0/500자</p>
                            <textarea name="" id=""></textarea>
                        </div>
                    </div>
                </li>
                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit" style="display: flex; align-items: center;">지원 동기 및 <br> 입사 후 포부/장래 계획</div>
                        <div class="cont">
                            <p class="small-text">0/1000자</p>
                            <textarea name="" id=""></textarea>
                        </div>
                    </div>
                </li>
                <li class="input-form__item desc">
                    <div class="input-form__box">
                        <div class="tit" style="display: flex; align-items: center;">건강상태 <br>(최근 질병 또는 사고로 치료를 받았거나 과거 질병이 있었던 경우 작성)</div>
                        <div class="cont">
                            <p class="small-text">0/500자</p>
                            <textarea name="" id=""></textarea>
                        </div>
                    </div>
                </li>
            </ul>
            <p class="desc" style="margin-top: 20px;">* 위 내용은 사실과 틀림없으며 기재사실이 허위로 판명될 경우에는 합격취소 또는 임용취소 처분을 감수 할 것을 서약하면서 응시지원서를 제출합니다.</p>
        </div>
    </div>
    <div class="contents text-box type1 info-text">
        <ul class="text-box__list">
            <li class="text-box__item">이전단계 수정이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 수정가능합니다.</li>
            <li class="text-box__item">공용 PC에서 접속하셨을 경우 개인정보보호를 위해 입력완료 후 브라우저 창을 종료해주시기 바랍니다.</li>
        </ul>
    </div>
    <div class="contents btn-wrap center">
        <a href="javascript:;" class="btn">중간저장</a>
        <a href="javascript:;" class="btn blue">다음단계</a>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import DatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        data() {
            return {
                isChecked: false,
                imageSrc: require('@/assets/resource/img/img-add.png'),
                date: null, // 선택된 날짜를 저장할 변수
                dateFormat: 'YYYY-MM-DD', // 날짜 형식
            };
        },
        methods: {
            previewImage(event) {
                const file = event.target.files[0];
                if (file && file.type.startsWith('image/') && file.size <= 1000000) { // 1000KB 이하
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.imageSrc = e.target.result;
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('유효하지 않은 파일입니다. JPG 파일만 가능하며 크기는 1000KB 이하이어야 합니다.');
                }
            },
            isCheckedFn() {
                this.isChecked = true;
            }
        },
        setup() {
            const date = ref(null); // 선택된 날짜를 저장할 변수
            const startDate = ref(null); // 시작 날짜
            const endDate = ref(null); // 종료 날짜
            const dateFormat = 'yyyy-MM-dd'; // 날짜 형식

            return {
                date,
                startDate,
                endDate,
                dateFormat,
            };
        },
        components: {
            DatePicker,
        },
    };
</script>