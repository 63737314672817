<template>
    <!-- 팝업 -->
    <div class="first_reserve_popup name_certify_popup my_health_popup" v-if="isPopupVisible">
        <div class="first_reserve_wrap job">
            <div class="first_tit">
                <h4>개인정보 삭제 알림</h4>
                <a href="javascript:;" @click="closePopup">
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div class="popup-text-box">
                <p>개인정보 보호를 위해 중간저장 후 일정시간<b class="c-red">(60분)</b>이 경과할 경우 <br> 작성한 내용이 자동삭제 됩니다.</p>
                <p>지원정보 삭제까지 <span class="c-red" style="font-weight: 700;">{{validTime}}</span> 남았습니다.</p>
                <p>입력시간을 연장하시겠습니까?</p>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn" @click="closePopup">취소</a>
                <a href="javascript:;" class="btn blue" @click="extensionTime(true)">연장</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        raNo: null,
    },
    data() {
        return {
            isPopupVisible: false,
            validTime: '04분 59초',
            interval: null,
        }
    },
    methods: {
        openPopup() {
            this.isPopupVisible = true;
        },
        closePopup() {
            this.isPopupVisible = false;
        },
        setModifyTime(_modifiedDt) {
            const modifiedDt = new Date(_modifiedDt);
            modifiedDt.setMinutes(modifiedDt.getMinutes() + 55);
            // modifiedDt.setMinutes(modifiedDt.getMinutes() + 1);
            const currentDate = new Date();
            const timeDifference = modifiedDt - currentDate;

            console.log('setModifyTime timeDifference : ' + (timeDifference/1000))

            this.clearAll()
            setTimeout(() => {
                this.setTimeCheck(_modifiedDt)
            }, timeDifference)
        },
        setTimeCheck(time) {
            this.openPopup()

            const modifiedDt = new Date(time);
            modifiedDt.setMinutes(modifiedDt.getMinutes() + 60);
            // modifiedDt.setMinutes(modifiedDt.getMinutes() + 2);

            const _vue = this
            this.interval = setInterval(function() {
                const currentDate = new Date();
                const timeDifference = modifiedDt - currentDate;

                if (timeDifference <= 0) {
                    _vue.clearAll()
                    alert("개인정보 보호를 위해 중간저장 후 60분이 경과하여 작성한 내용이 자동삭제 되었습니다.");
                    _vue.$router.push({ path: '/'})
                } else {
                    let secondsRemaining = Math.floor(timeDifference / 1000);
                    let minutesRemaining = Math.floor(secondsRemaining / 60);

                    secondsRemaining = secondsRemaining % 60;
                    minutesRemaining = minutesRemaining % 60;

                    const timeString = minutesRemaining.toString().padStart(2, '0') + "분 " +
                        secondsRemaining.toString().padStart(2, '0') + "초";

                    _vue.validTime = timeString
                    console.log("Time remaining: " + _vue.validTime);
                }
            }, 1000);

        },
        async extensionTime(showAlert) {
            this.closePopup()
            this.clearAll()

            let formData = new FormData()
            formData.set('raNo', this.raNo)

            this.axios.post(this.api.recruit.applicant.register, formData)
                .then(res => {
                    if(res.data.code == '0000'){
                        this.setModifyTime(new Date().getTime())

                        if (showAlert) {
                            alert('입력시간이 60분 연장되었습니다.');
                        }
                    }else{
                        alert(res.data.message);
                    }
                })
        },
        clearAll() {
            const highestIntervalId = setInterval(";");
            for (let i = 0 ; i < highestIntervalId ; i++) {
                clearInterval(i);
                this.interval = null
            }
        }
    },
    computed: {
    },
    mounted() {
        this.clearAll()
    },
    beforeUnmount() {
        this.clearAll()
    }
}
</script>
