<template>
    <div class="contents">
        <div class="praise full-width">
            <div class="praise__tit job">
                <h3>
                    <b class="c-red">사랑의 돌봄</b>으로 <b class="c-blue">생명존중의 전인치유</b>를 수행할 <br>
                    역량 있는 인재를 기다리고 있습니다.
                </h3>
            </div>
        </div>

        <div class="inner" style="margin-bottom: 150px;">
            <div class="info-text depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title title">진료절차</h4>
                    <div class="depth__wrap depth02">
                        <ul class="step-textbox type02 job">
                            <li class="step-textbox__item">
                                <div class="step-textbox__item-wrap">
                                    <div class="step-textbox__title">
                                        <h3 class="depth02__title">
                                            서류전형
                                        </h3>
                                        <img src="@/assets/resource/icon/job-icon1.png" class="step-textbox__title--icon" alt="">
                                    </div>
                                    <div class="step-textbox__con">
                                        <div class="depth__wrap ">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>채용에 적합한지 판단하기 위해 경력사
                                                    항, 자격증, 전공 등을 종합적으로 평가
                                                    합니다.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="step-textbox__item">
                                <div class="step-textbox__item-wrap">
                                    <div class="step-textbox__title">
                                        <h3 class="depth02__title">
                                            면접(1차 통과자)
                                        </h3>
                                        <img src="@/assets/resource/icon/job-icon2.png" class="step-textbox__title--icon" alt="">
                                    </div>
                                    <div class="step-textbox__con">
                                        <div class="depth__wrap ">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>면접은 기본인성 및 지원 동기, 조직적
                                                    응력, 직무수행능력, 전공지식 등을 종
                                                    합적으로 평가합니다.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="step-textbox__item">
                                <div class="step-textbox__item-wrap">
                                    <div class="step-textbox__title">
                                        <h3 class="depth02__title">
                                            신체검사(2차 통과자)
                                        </h3>
                                        <img src="@/assets/resource/icon/job-icon3.png" class="step-textbox__title--icon" alt="">
                                    </div>
                                    <div class="step-textbox__con">
                                        <div class="depth__wrap ">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>신체 검사에서 이상이 없을 경우 최종
                                                    합격 됩니다.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="step-textbox__item">
                                <div class="step-textbox__item-wrap">
                                    <div class="step-textbox__title">
                                        <h3 class="depth02__title">
                                            최종합격
                                        </h3>
                                        <img src="@/assets/resource/icon/job-icon4.png" class="step-textbox__title--icon" alt="">
                                    </div>
                                    <div class="step-textbox__con">
                                        <div class="depth__wrap ">
                                            <div class="depth__bullet">
                                                <span class="bullet03"></span>
                                            </div>
                                            <div class="depth__text">
                                                <p>최종합격자는 창원 파티마병원 직원으로 임용됩니다.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>

</style>