<!-- 전송페이지 공통 -->
<template>
    <div class="container">
        <div class="floating__save">
            <div class="save-btn"><button type="button"><img src="../../assets/resource/icon/save-btn.png" alt="중간저장" @click="save"></button></div>
        </div>
        <div class="sub-tit">
            <!--<h2></h2>-->
        </div>
        <div class="inner" ref="insertDiv">
            <div class="join-tab">
                <ul class="join-tab__list">
                    <li class="join-tab__item">
                        <div class="step"><span class="text">step </span><span class="num">01</span></div>
                        <div class="tit">개인정보입력</div>
                    </li>
                    <li class="join-tab__item active">
                        <div class="step"><span class="text">step </span><span class="num">02</span></div>
                        <div class="tit">학력 및 경력사항 등 입력</div>
                    </li>
                    <li class="join-tab__item ">
                        <div class="step"><span class="text">step </span><span class="num">03</span></div>
                        <div class="tit">자기소개서 작성</div>
                    </li>
                    <li class="join-tab__item ">
                        <div class="step"><span class="text">step </span><span class="num">04</span></div>
                        <div class="tit">입사지원 완료</div>
                    </li>
                </ul>
            </div>
            <div class="input-form type1">
                <div class="text-box type1 info-text mt20">
                    <div class="input-form__top-wrap">
                        <p class="input-form__top-text type2">- 개인정보 보호를 위해 중간저장 후 일정시간<b class="c-red">(60분)</b> 동안 저장이 없을 경우 작성한 내용이 자동삭제 됩니다.</p>
                    </div>
                </div>
            </div>


            <div class="contents patient">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">입력 유의사항</h4>
                    </div>
                </div>
                <div class="text-box type2">
                    <div class="text-box__wrap type2">
                        <h5 class="text-box__tit">1. 학교명</h5>

                        <p class="text-box__desc">
                            - 고등학교, 전문대학, 대학교, 편입대학교, 대학원등의 순서로 입력 <br>
                            <!--- 학교명 등이 변경되어 선택목록에 없을 경우 인사팀으로 연락해 주시면 등록해 드립니다. [단, 구(舊) 학교명이 있으면 선택가능] <br>-->
                            - 검정고시일 경우에는 검정고시 일자를 각각 입력하고 학교명에는 '검정고시'라고 입력한 후 학교과정을 선택하고 '졸업'을 선택한 후, 소재지는 검정고지 시행지역을 입력하십시오.
                        </p>

                        <!--<h5 class="text-box__tit">2. 전공</h5>-->
                        <!--<p class="text-box__desc">- 전공이 목록에 없을 경우 인사팀으로 연락해 주십시오.</p>-->

                        <h5 class="text-box__tit">2. 소재지</h5>
                        <p class="text-box__desc">- 대도시 단위로만 입력하십시오. (ex. 서울, 경기, 인천, 충남, 대전, 제주 등)</p>

                        <h5 class="text-box__tit">3. 석차</h5>
                        <p class="text-box__desc">- 석차는 석차 / 전체 인원 순으로 입력하십시오. (예, 45/200)</p>

                        <h5 class="text-box__tit">4. 학점</h5>
                        <p class="text-box__desc">
                            - 4.5 만점기준으로 기재 하십시오. <br>
                            - 소수 첫째 자리로 입력하십시오. (반올림 금지) <br>
                            - 4.3 만점일 경우 4.5만점 환산점수로 기재해야 하며, 4.5만점 기준으로 성적증명서 제출하십시오. <br>
                            - 편입한 경우 전적대학의 성적까지 기재하십시오. (단, 중퇴의 경우는 기재하지 마십시오.) <br>
                            <span class="c-red">- 간호사의 경우 백분율 점수를 입력하십시오.</span>
                        </p>
                    </div>
                </div>
            </div>

            <!-- 학력사항 -->
            <div class="contents" ref="educationDiv">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">학력사항</h4>
                    </div>
                </div>
                <div class="table-wrap table-type1 long">
                    <div class="scroll">
                        <table style="table-layout: fixed;">
                            <caption class="blind"></caption>
                            <colgroup>
                                <col style="width: calc(100%/11);">
                                <col style="width: calc(100%/11);">
                                <col style="width: calc(100%/11);">
                                <col style="width: 80px;">
                                <col style="width: auto">
                                <col style="width: calc(100%/11);">
                                <col style="width: 110px">
                                <col style="width: calc(100%/11);">
                                <col style="width: 80px">
                                <col style="width: 100px">
                                <col style="width: 80px">
                                <!--<col style="width: 100px">-->
                            </colgroup>
                            <thead>
                            <tr>
                                <th><span class="c-red">* </span>학력구분</th>
                                <th><span class="c-red">* </span>학교명</th>
                                <th><span class="c-red">* </span>전공</th>
                                <th><span class="c-red">* </span>수업 년한</th>
                                <th><span class="c-red">* </span>입학일자 ~ 졸업일자</th>
                                <th><span class="c-red">* </span>학위코드/이수</th>
                                <th><span class="c-red">* </span>학위번호</th>
                                <th><span class="c-red">* </span>소재지</th>
                                <th><span class="c-red">* </span>평점</th>
                                <th><span class="c-red">* </span>석차</th>
                                <th>백분율</th>
                                <!--<th>비고</th>-->
                            </tr>
                            </thead>
                            <tbody ref="educationSection">
                            <tr v-for="(educationInfo, idx) in educationList" :key="educationInfo.raeNo">
                                <td v-if="idx === 0">
                                    <select v-model="educationInfo.raeType" title="학력구분" style="width: 100%;">
                                        <option value="">선택</option>
                                        <option value="무">무</option>
                                        <option value="초재">초재</option>
                                        <option value="초졸">초졸</option>
                                        <option value="중재">중재</option>
                                        <option value="중졸">중졸</option>
                                        <option value="중중퇴">중중퇴</option>
                                        <option value="고재">고재</option>
                                        <option value="고졸">고졸</option>
                                        <option value="고중퇴">고중퇴</option>
                                        <option value="초중퇴">초중퇴</option>
                                        <option value="기타">기타</option>
                                    </select>
                                </td>
                                <td v-else-if="idx !== 0">
                                    <select v-model="educationInfo.raeType" title="학력구분" :emptyValid="isEmptyEducation(this.educationList[idx])">
                                        <option value="">선택</option>
                                        <option value="전문대재">전문대재</option>
                                        <option value="전문대졸">전문대졸</option>
                                        <option value="전문대중퇴">전문대중퇴</option>
                                        <option value="대재">대재</option>
                                        <option value="대졸">대졸</option>
                                        <option value="대중퇴">대중퇴</option>
                                        <option value="대학원재">대학원재</option>
                                        <option value="대학원졸">대학원졸</option>
                                        <option value="대학원중퇴">대학원중퇴</option>
                                        <option value="대학원수료">대학원수료</option>
                                        <option value="석박통합과정">석박통합과정</option>
                                    </select>
                                </td>
                                <td v-if="idx === 0"><input type="text" v-model="educationInfo.raeName" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td class="" v-else-if="idx !== 0">
                                    <input type="text" v-model="educationInfo.raeName" :emptyValid="isEmptyEducation(this.educationList[idx])" @input="$commonUtils.inputXssReplace($event)"/>
                                    <div class="input-form type1" style="margin-top: 10px;">
                                        <div class="input-form__radio">
                                            <label class="input-item" style="display: flex; cursor: pointer;">
                                                <input type="checkbox" class="radio" v-model="educationInfo.raeTransferYn" true-value="Y" false-value="N" :emptyValid="isEmptyEducation(this.educationList[idx])">
                                                <span class="icon"></span> 편입
                                            </label>
                                        </div>
                                    </div>
                                </td>
                                <td v-if="idx === 0"></td>
                                <td v-else-if="idx !== 0">
                                    <input type="text" v-model="educationInfo.raeMajor" maxlength="50" :emptyValid="isEmptyEducation(this.educationList[idx])" @input="$commonUtils.inputXssReplace($event)"/>
                                    <div class="input-form type1" style="margin-top: 10px;">
                                        <div class="input-form__radio">
                                            <label class="input-item" style="display: flex; cursor: pointer;">
                                                <input type="checkbox" class="radio" v-model="educationInfo.raeDoubleMajorYn" true-value="Y" false-value="N" :emptyValid="isEmptyEducation(this.educationList[idx])">
                                                <span class="icon"></span> 복수전공
                                            </label>
                                        </div>
                                    </div>
                                    <input type="text" v-model="educationInfo.raeDoubleMajor" v-if="educationInfo.raeDoubleMajorYn === 'Y'" maxlength="50" :emptyValid="isEmptyEducation(this.educationList[idx])" @input="$commonUtils.inputXssReplace($event)"/>
                                </td>
                                <td><input type="text" style="text-align: center;" v-model="educationInfo.raeYear" maxlength="2" @input="$commonUtils.numberOnly" :emptyValid="isEmptyEducation(this.educationList[idx])"></td>
                                <td>
                                    <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                        <div class="date-input" style="width: 170px;">
                                            <DatePicker v-model="educationInfo.raeAdmissionD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" :max-date="educationInfo.raeGraduationD" auto-apply year-first
                                                        :emptyValid="isEmptyEducation(this.educationList[idx])" :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                        <span class="line" style="margin: 0 10px;">~</span>
                                        <div class="date-input" style="width: 170px;">
                                            <DatePicker v-model="educationInfo.raeGraduationD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" :min-date="educationInfo.raeAdmissionD" auto-apply year-first
                                                        :emptyValid="isEmptyEducation(this.educationList[idx])" :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                    </div>
                                </td>
                                <td v-if="idx === 0"></td>
                                <td v-else-if="idx !== 0">
                                    <select title="학위코드" v-model="educationInfo.raeDegree" :emptyValid="isEmptyEducation(this.educationList[idx])">
                                        <option value="">선택</option>
                                        <option value="학사">학사</option>
                                        <option value="석사재학">석사재학</option>
                                        <option value="석사수료">석사수료</option>
                                        <option value="석사">석사</option>
                                        <option value="박사재학">박사재학</option>
                                        <option value="박사수료">박사수료</option>
                                        <option value="박사">박사</option>
                                        <option value="기타">기타</option>
                                        <option value="전문학사">전문학사</option>
                                        <option value="의학석사">의학석사</option>
                                        <option value="치의학석사">치의학석사</option>
                                        <option value="보건학석사">보건학석사</option>
                                        <option value="행정학석사">행정학석사</option>
                                        <option value="경영학석사">경영학석사</option>
                                        <option value="사회복지학석사">사회복지학석사</option>
                                        <option value="약학석사">약학석사</option>
                                        <option value="간호학석사">간호학석사</option>
                                        <option value="이학석사">이학석사</option>
                                        <option value="신학석사">신학석사</option>
                                        <option value="교육학석사">교육학석사</option>
                                        <option value="문학석사">문학석사</option>
                                        <option value="법학석사">법학석사</option>
                                        <option value="의학박사">의학박사</option>
                                        <option value="치의학박사">치의학박사</option>
                                        <option value="경영학박사">경영학박사</option>
                                        <option value="석박수료">석박수료</option>
                                        <option value="석박과정재학">석박과정재학</option>
                                        <option value="석박과정졸업">석박과정졸업</option>
                                        <option value="이학사">이학사</option>
                                        <option value="공학사">공학사</option>
                                        <option value="의무석사">의무석사</option>
                                        <option value="약학사">약학사</option>
                                        <option value="치무석사">치무석사</option>
                                    </select>
                                    <select title="이수구분" style="margin-top:4px;" v-model="educationInfo.raeDegreeSatus" :emptyValid="isEmptyEducation(this.educationList[idx])">
                                        <option value="">선택</option>
                                        <option value="이수">이수</option>
                                        <option value="미이수">미이수</option>
                                    </select>
                                </td>
                                <td v-if="idx === 0"></td>
                                <td v-else-if="idx !== 0"><input type="text" v-model="educationInfo.raeDegreeSn" maxlength="50" :emptyValid="isEmptyEducation(this.educationList[idx])" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td>
                                    <select title="소재지 선택" v-model="educationInfo.raeLocation" :emptyValid="isEmptyEducation(this.educationList[idx])">
                                        <option value="">선택</option>
                                        <option value="서울특별시">서울특별시</option>
                                        <option value="인천광역시">인천광역시</option>
                                        <option value="대전광역시">대전광역시</option>
                                        <option value="대구광역시">대구광역시</option>
                                        <option value="광주광역시">광주광역시</option>
                                        <option value="부산광역시">부산광역시</option>
                                        <option value="울산광역시">울산광역시</option>
                                        <option value="경기도">경기도 </option>
                                        <option value="강원도">강원도 </option>
                                        <option value="경상남도">경상남도</option>
                                        <option value="경상북도">경상북도</option>
                                        <option value="충청남도">충청남도</option>
                                        <option value="충청북도">충청북도</option>
                                        <option value="전라남도">전라남도</option>
                                        <option value="전라북도">전라북도</option>
                                        <option value="제주도">제주도 </option>
                                        <option value="기타">기타 </option>
                                    </select>
                                </td>
                                <td v-if="idx === 0"></td>
                                <td v-else-if="idx !== 0"><input type="text" style="text-align: center;" v-model="educationInfo.raeGrade" maxlength="3" @input="$commonUtils.decimalOnly" :emptyValid="isEmptyEducation(this.educationList[idx])">/4.5</td>
                                <td v-if="idx === 0"></td>
                                <td v-else-if="idx !== 0">
                                    <div class="input-form type1" style="margin-bottom: 10px;" >
                                        <div class="input-form__radio">
                                            <label class="input-item" style="display: flex; cursor: pointer;">
                                                <input type="checkbox" class="radio" v-model="educationInfo.raeRankYn" true-value="Y" false-value="N" :emptyValid="isEmptyEducation(this.educationList[idx])">
                                                <span class="icon"></span>석차
                                            </label>
                                        </div>
                                    </div>
                                    <input type="text" style="text-align: center;" v-model="educationInfo.raeRank" :emptyValid="educationInfo.raeRankYn !== 'Y' ? true : null" maxlength="3" @input="$commonUtils.decimalOnly">
                                    /<input type="text" style="text-align: center;" v-model="educationInfo.raeRankStandard" :emptyValid="educationInfo.raeRankYn !== 'Y' ? true : null" maxlength="3" @input="$commonUtils.decimalOnly">
                                </td>
                                <td><input type="text" style="text-align: center;" v-model="educationInfo.raePercentage" emptyValid maxlength="3" @input="$commonUtils.numberOnly">/100</td>
                                <!--<td><input type="text" v-model="educationInfo.raeNote" emptyValid></td>-->
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="info-text depth__wrap depth01 contents">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <span class="bullet04"></span>
                                </div>
                                <div class="depth__text">
                                    <p class="c-blue">복수전공 체크 시, 항목을 추가하여 주전공과 부전공을 각각 입력해주시기 바랍니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <span class="bullet04"></span>
                                </div>
                                <div class="depth__text">
                                    <p>졸업예정자는 학위번호란에 '졸업예정'으로 입력하여 주시기 바랍니다.</p>
                                </div>
                            </div>
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <span class="bullet04"></span>
                                </div>
                                <div class="depth__text">
                                    <p>저장된 학력정보 삭제가 필요할 시 항목삭제를 눌러주시기 바랍니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap" style="margin-top: 20px;">
                        <a href="javascript:;" class="btn gray" @click="addList('education')">항목추가 +</a>
                        <a href="javascript:;" class="btn gray" @click="removeList('education')">항목삭제 -</a>
                    </div>
                </div>
            </div>
            <!-- 학력사항 -->

            <!-- 경력사항 -->
            <div class="contents" ref="careerDiv">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">경력사항</h4>
                    </div>
                </div>
                <div class="table-wrap table-type1 long">
                    <div class="scroll">
                        <table style="table-layout: fixed;">
                            <caption class="blind"></caption>
                            <colgroup>
                                <col style="width: auto;">
                                <col style="width: calc(100%/9);">
                                <col style="width: calc(100%/12);">
                                <col style="width: calc(100%/12);">
                                <col style="width: calc(100%/12);">
                                <col style="width: calc(100%/12);">
                                <col style="width: calc(100%/12);">
                                <col style="width: calc(100%/11);">
                                <col style="width: calc(100%/11);">
                            </colgroup>
                            <thead>
                            <tr>
                                <th>근무기간</th>
                                <th>근무처</th>
                                <th>고용형태</th>
                                <th>부서명</th>
                                <th>직책명</th>
                                <th>직위명</th>
                                <th>직무</th>
                                <th>퇴직사유</th>
                                <th>비고</th>
                            </tr>
                            </thead>
                            <tbody v-if="raAgree2 === 'Y'">
                            <tr v-for="(careerInfo) in careerList" :key="careerInfo.racNo">
                                <td>
                                    <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                        <div class="date-input" style="width: 170px;">
                                            <DatePicker v-model="careerInfo.racStartD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" :max-date="careerInfo.racEndD" auto-apply year-first
                                                        :emptyValid="(careerInfo.racWorkingYn !== 'Y' && !careerInfo.racEndD) ? true : null" :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                        <span class="line" style="margin: 0 10px;">~</span>
                                        <div class="date-input" style="width: 170px;" v-if="careerInfo.racWorkingYn !== 'Y'">
                                            <DatePicker v-model="careerInfo.racEndD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" :min-date="careerInfo.racStartD" auto-apply year-first
                                                        :emptyValid="!careerInfo.racStartD ? true : null" :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                        <div style="width: 170px;" v-else>
                                            <input type="text" class="cont__text-input" value="재직중" readonly>
                                        </div>
                                        <div class="input-form type1" style="margin: 0; margin-left: 15px;">
                                            <div class="input-form__radio">
                                                <label class="input-item" style="display: flex; cursor: pointer; margin: 0;">
                                                    <input type="checkbox" class="radio" v-model="careerInfo.racWorkingYn" true-value="Y" false-value="N">
                                                    <span class="icon"></span> 재직중 </label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td><input type="text" v-model="careerInfo.racName" :emptyValid="!careerInfo.racStartD && !careerInfo.racEndD ? true : null" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td>
                                    <select title="고용형태" style="width: 100%;" v-model="careerInfo.racEmployType" emptyValid>
                                        <option value="">선택</option>
                                        <option value="정규직">정규직</option>
                                        <option value="비정규직">비정규직</option>
                                        <option value="기타">기타</option>
                                    </select>
                                </td>
                                <td><input type="text" v-model="careerInfo.racDepartment" emptyValid maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="careerInfo.racPosition" emptyValid maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="careerInfo.racTitle" emptyValid maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="careerInfo.racDuty" emptyValid maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="careerInfo.racResignReason" emptyValid maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="careerInfo.racNote" emptyValid maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="info-text depth__wrap depth01 contents">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <span class="bullet04"></span>
                                </div>
                                <div class="depth__text">
                                    <p class="c-blue" v-if="raAgree2 !== 'Y'">경력사항 입력이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 개인정보 수집·이용 내역 (선택) 동의 후, 수정가능합니다.</p>
                                    <p v-else>경력사항 기간 입력 시 과거 사항부터 입력을 부탁드립니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap" style="margin-top: 20px;" v-if="raAgree2 === 'Y'">
                        <a href="javascript:;" class="btn gray" @click="addList('career')">항목추가 +</a>
                        <a href="javascript:;" class="btn gray" @click="removeList('career')">항목삭제 -</a>
                    </div>
                </div>
            </div>
            <!-- 경력사항 -->

            <!-- 자격면허 -->
            <div class="contents" ref="licenseDiv">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">자격면허</h4>
                    </div>
                </div>
                <div class="table-wrap table-type1 long">
                    <div class="scroll">
                        <table style="table-layout: fixed;">
                            <caption class="blind"></caption>
                            <colgroup>
                                <col style="width: auto;">
                                <col style="width: calc(100%/7);">
                                <col style="width: calc(100%/5);">
                                <col style="width: calc(100%/5);">

                            </colgroup>
                            <thead>
                            <tr>
                                <th>자격면허명</th>
                                <th>취득일자</th>
                                <th>발급기관</th>
                                <th>자격면허번호</th>
                            </tr>
                            </thead>
                            <tbody v-if="raAgree2 === 'Y'">
                            <tr v-for="(licenseInfo) in licenseList" :key="licenseInfo.raliNo">
                                <td class="">
                                    <input type="text" v-model="licenseInfo.raliName" maxlength="50" @input="$commonUtils.inputXssReplace($event)">
                                </td>
                                <td>
                                    <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                        <div class="date-input" style="width: 100%;">
                                            <DatePicker v-model="licenseInfo.raliObtainD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" auto-apply year-first :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                    </div>
                                </td>
                                <td><input type="text" v-model="licenseInfo.raliIssuer" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="licenseInfo.raliSn" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="info-text depth__wrap depth01 contents">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <span class="bullet04"></span>
                                </div>
                                <div class="depth__text">
                                    <p class="c-blue" v-if="raAgree2 !== 'Y'">자격면허 입력이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 개인정보 수집·이용 내역 (선택) 동의 후, 수정가능합니다.</p>
                                    <p v-else>조회불가항목의 경우 '기타'를 입력 후 상세 입력해 주십시오.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap" style="margin-top: 20px;" v-if="raAgree2 === 'Y'">
                        <a href="javascript:;" class="btn gray" @click="addList('license')">항목추가 +</a>
                        <a href="javascript:;" class="btn gray" @click="removeList('license')">항목삭제 -</a>
                    </div>
                </div>
            </div>
            <!-- 자격면허 -->

            <!-- 외국어 -->
            <div class="contents" ref="languageDiv">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">외국어</h4>
                    </div>
                </div>
                <div class="table-wrap table-type1 long">
                    <div class="scroll">
                        <table style="table-layout: fixed;">
                            <caption class="blind"></caption>
                            <colgroup>
                                <col style="width: calc(100%/6);">
                                <col style="width: calc(100%/6);">
                                <col style="width: calc(100%/6);">
                                <col style="width: calc(100%/6);">
                                <col style="width: calc(100%/6);">
                                <col style="width: calc(100%/6);">
                            </colgroup>
                            <thead>
                            <tr>
                                <th>외국어</th>
                                <th>종합등급</th>
                                <th>시험코드</th>
                                <th>취득일자</th>
                                <th>시험점수</th>
                                <th>비고</th>
                            </tr>
                            </thead>
                            <tbody v-if="raAgree2 === 'Y'">
                            <tr v-for="(languageInfo) in languageList" :key="languageInfo.ralaNo">
                                <td>
                                    <select title="외국어" style="width:100%" v-model="languageInfo.ralaType">
                                        <option value="">선택</option>
                                        <option value="영어">영어</option>
                                        <option value="독일어">독일어</option>
                                        <option value="불란서어">불란서어</option>
                                        <option value="서반아어">서반아어</option>
                                        <option value="일본어">일본어</option>
                                        <option value="중국어">중국어</option>
                                        <option value="러시아어">러시아어</option>
                                        <option value="에스페란토어">에스페란토어</option>
                                        <option value="스페인어">스페인어</option>
                                        <option value="이태리어">이태리어</option>
                                        <option value="아랍어">아랍어</option>
                                        <option value="포루투칼어">포루투칼어</option>
                                        <option value="기타">기타</option>
                                    </select>
                                </td>
                                <td>
                                    <select title="종합등급" v-model="languageInfo.ralaGrade">
                                        <option value="">선택</option>
                                        <option value="상">상</option>
                                        <option value="중">중</option>
                                        <option value="하">하</option>
                                    </select>
                                </td>
                                <td>
                                    <select title="시험코드" style="width:85px" v-model="languageInfo.ralaTest">
                                        <option value="">선택</option>
                                        <option value="TOEIC">TOEIC</option>
                                        <option value="JPT">JPT</option>
                                        <option value="기타">기타</option>
                                        <option value="TEPS">TEPS</option>
                                        <option value="TOEFL_PBT">TOEFL_PBT</option>
                                        <option value="TOEFL_CBT">TOEFL_CBT</option>
                                        <option value="TOEFL_IBT">TOEFL_IBT</option>
                                        <option value="New TEPS">New TEPS</option>
                                    </select>
                                </td>
                                <td>
                                    <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                        <div class="date-input" style="width: 100%;">
                                            <DatePicker v-model="languageInfo.ralaObtainD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" auto-apply year-first :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                    </div>
                                </td>
                                <td><input type="text" v-model="languageInfo.ralaScore" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="languageInfo.ralaNote" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="info-text depth__wrap depth01 contents">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <span class="bullet04"></span>
                                </div>
                                <div class="depth__text">
                                    <p class="c-blue" v-if="raAgree2 !== 'Y'">외국어 입력이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 개인정보 수집·이용 내역 (선택) 동의 후, 수정가능합니다.</p>
                                    <p v-else >TOEIC 및 외국어 점수는 2년 내의 취득 성적만 인정되며, 기타 외국어일 경우에는 해당 외국어와 시험명 등을 직접 입력하십시오.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap" style="margin-top: 20px;" v-if="raAgree2 === 'Y'">
                        <div class="btn-wrap" style="margin-top: 20px;">
                            <a href="javascript:;" class="btn gray" @click="addList('language')">항목추가 +</a>
                            <a href="javascript:;" class="btn gray" @click="removeList('language')">항목삭제 -</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 외국어 -->

            <!-- 교육연수 -->
            <div class="contents" ref="trainingDiv">
                <div class="info-text depth__wrap depth01">
                    <div class="depth__bullet">
                        <span class="bullet01"></span>
                    </div>
                    <div class="depth__text">
                        <h4 class="depth01__title title">교육연수</h4>
                    </div>
                </div>
                <div class="table-wrap table-type1 long">
                    <div class="scroll">
                        <table style="table-layout: fixed;">
                            <caption class="blind"></caption>
                            <colgroup>
                                <col style="width: calc(100%/4);">
                                <col style="width: calc(100%/4);">
                                <col style="width: calc(100%/4);">
                                <col style="width: calc(100%/4);">
                            </colgroup>
                            <thead>
                            <tr>
                                <th>교육기관</th>
                                <th>교육내용</th>
                                <th>교육기간</th>
                                <th>비고</th>
                            </tr>
                            </thead>
                            <tbody v-if="raAgree2 === 'Y'">
                            <tr v-for="(trainingInfo) in trainingList" :key="trainingInfo.raeNo">
                                <td><input type="text" v-model="trainingInfo.ratInstitution" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td><input type="text" v-model="trainingInfo.ratContent" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                                <td>
                                    <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                        <div class="date-input" style="width: 170px;">
                                            <DatePicker v-model="trainingInfo.ratStartD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" :max-date="trainingInfo.ratEndD" auto-apply year-first :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                        <span class="line" style="margin: 0 10px;">~</span>
                                        <div class="date-input" style="width: 170px;">
                                            <DatePicker v-model="trainingInfo.ratEndD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                        week-start="0" :model-type="dateFormat" :enable-time-picker="false" :clearable="true" :min-date="trainingInfo.ratStartD" auto-apply year-first :flow="['year', 'month', 'calendar']"/>
                                            <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                        </div>
                                    </div>
                                </td>
                                <td><input type="text" v-model="trainingInfo.ratNote" maxlength="50" @input="$commonUtils.inputXssReplace($event)"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="info-text depth__wrap depth01 contents" v-if="raAgree2 !== 'Y'">
                        <div class="depth__text">
                            <div class="depth__wrap">
                                <div class="depth__bullet">
                                    <span class="bullet04"></span>
                                </div>
                                <div class="depth__text">
                                    <p class="c-blue">교육연수 입력이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 개인정보 수집·이용 내역 (선택) 동의 후, 수정가능합니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap" style="margin-top: 20px;" v-if="raAgree2 === 'Y'">
                        <div class="btn-wrap" style="margin-top: 20px;">
                            <a href="javascript:;" class="btn gray" @click="addList('training')">항목추가 +</a>
                            <a href="javascript:;" class="btn gray" @click="removeList('training')">항목삭제 -</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 교육연수 -->

            <div class="contents text-box type1 info-text">
                <ul class="text-box__list">
                    <li class="text-box__item">이전단계 수정이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 수정가능합니다.</li>
                    <li class="text-box__item">*공용 PC에서 접속하셨을 경우 개인정보보호를 위해 입력완료 후 브라우저 창을 종료해주시기 바랍니다.</li>
                </ul>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn" @click="save">중간저장</a>
                <a href="javascript:;" class="btn blue" @click="goNextStep">중간저장 및 다음단계</a>
            </div>

            <ExtentionTimePopup ref="extentionTimePopup" :raNo="this.raNo"></ExtentionTimePopup>

        </div>
    </div>
</template>

<script>
    import DatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import ExtentionTimePopup from "@/components/common/ExtentionTimePopup.vue";

    export default {
        components: {
            DatePicker,
            ExtentionTimePopup,
        },
        data() {
            return {
                dateFormat: 'yyyy-MM-dd', // 날짜 형식

                rnNo: this.$route.query.rnNo,
                // raNo: this.$route.query.raNo,
                raNo: '',
                type: this.$route.query.type,

                applicantInfo: {},
                educationList: [{}, {}],
                careerList: [{}],
                licenseList: [{}],
                languageList: [{}],
                trainingList: [{}],
                resResult: {},

                raAgree2: '',
                raAgree3: '',

                interval: null,
            };
        },
        methods: {
            openPopup() {
                this.isPopupVisible = true;
            },
            closePopup() {
                this.isPopupVisible = false;
            },

            addList(type) {
                this[type+'List'].push({})
            },
            removeList(type) {
                let minCount = 1
                if (type === 'education') {
                    minCount = 2
                }
                if (this[type+'List'].length > minCount) {
                    this[type+'List'].pop()
                } else {
                    alert(`${minCount}행의 정보를 삭제합니다.`)
                    this[type+'List'].pop()
                    this[type+'List'].push({})
                }
            },
            isNotEmptyList(list) {
                let valueCount = 0

                for (const object of list) {
                    for (const value of Object.values(object)) {
                        if (value) {
                            valueCount++
                        }
                    }
                }

                return valueCount > 0
            },
            isEmptyEducation(education) {
                let valueCount = 0

                for (const key of Object.keys(education)) {
                    if (key === 'delYn' || key === 'createdDt' || key === 'modifiedDt' || key === 'raeNo' || key === 'raNo') {
                        education[key] = ''
                    }
                }

                for (const value of Object.values(education)) {
                    if (value) {
                        valueCount++
                    }
                }

                return (valueCount > 0) ? null : true
            },
            async getRecruitApplicantView() {
                await this.axios.get(this.api.recruit.applicant.view, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.applicantInfo = res.data
                        if (this.applicantInfo.raAgree2) {
                            this.raAgree2 = this.applicantInfo.raAgree2
                        }
                        if (this.applicantInfo.raAgree3) {
                            this.raAgree3 = this.applicantInfo.raAgree3
                        }

                        if (!this.applicantInfo.submitDt) { // 중간 저장 후 55분 경과시 연장팝업 노출
                            this.$refs.extentionTimePopup.setModifyTime(this.applicantInfo.modifiedDt)
                        }

                    } else {
                        alert("해당 지원정보가 없습니다.")
                    }
                });
            },
            async getEducationList() {
                await this.axios.get(this.api.recruit.applicant.educationList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data && this.isNotEmptyList(res.data)) {
                        this.educationList = res.data

                        if (this.educationList.length === 1) {
                            this.educationList.push({})
                        }
                    }
                });
            },
            async getCareerList() {
                await this.axios.get(this.api.recruit.applicant.careerList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data && this.isNotEmptyList(res.data)) {
                        this.careerList = res.data
                    }
                });
            },
            async getLicenseList() {
                await this.axios.get(this.api.recruit.applicant.licenseList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data && this.isNotEmptyList(res.data)) {
                        this.licenseList = res.data
                    }
                });
            },
            async getLanguageList() {
                await this.axios.get(this.api.recruit.applicant.languageList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data && this.isNotEmptyList(res.data)) {
                        this.languageList = res.data
                    }
                });
            },
            async getTrainingList() {
                await this.axios.get(this.api.recruit.applicant.trainingList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data && this.isNotEmptyList(res.data)) {
                        this.trainingList = res.data
                    }
                });
            },
            async submit(mode) {

                this.resResult = {}

                await this.submitEducation();
                if (this.resResult.resCode == '0000') {
                    await this.submitCareer();
                }
                if (this.resResult.resCode == '0000') {
                    await this.submitLicense();
                }
                if (this.resResult.resCode == '0000') {
                    await this.submitLanguage();
                }
                if (this.resResult.resCode == '0000') {
                    await this.submitTraining();
                }

                if(this.resResult.resCode == '0000'){
                    // 입력시간연장
                    if (!this.applicantInfo.submitDt) {
                        this.$refs.extentionTimePopup.extensionTime()
                    }

                    if (mode === 'goNextStep') {
                        // alert('지원자의 학력 및 경력사항 정보가 저장되었습니다.');
                        if (this.raAgree2 === 'Y') {
                            this.$store.commit('setRaNo', this.raNo)
                            this.$router.push({ path: '/application/applicationStep3.do', query: {rnNo: this.rnNo, type: 'update'}})
                        } else {
                            if(confirm("위 내용은 사실과 틀림없으며 기재사실이 허위로 판명될 경우에는 \n합격취소 또는 임용취소 처분을 감수 할 것을 서약하면서 \n응시원서를 제출하시겠습니까?")) {
                                const params = new URLSearchParams();
                                params.append('raNo', this.raNo);
                                this.axios.post(this.api.recruit.applicant.submit, params)  // 최종제출 상태값 변경
                                    .then(res => {
                                        if(res.data.code == '0000'){
                                            this.$store.commit('setRaNo', this.raNo)
                                            this.$router.push({ path: '/application/applicationStep4.do', query: {rnNo: this.rnNo, type: 'update'}})
                                        }else{
                                            alert(res.data.message);
                                        }
                                    })
                            }
                        }
                    } else {
                        alert('중간저장이 완료되었습니다.');
                    }
                } else if (this.resResult.resMessage) {
                    alert(this.resResult.resMessage);
                } else {
                    alert('중간저장할 항목을 입력해주세요.');
                }

            },
            save() {
                this.submit()
            },
            goNextStep() {
                this.$nextTick( () => {
                    if (this.educationList.length < 1 || (Object.keys(this.educationList[0]).length === 0)) {
                        this.$refs.educationSection.scrollIntoView({ block: 'center'})
                        alert("학력사항은 하나 이상 입력해주세요.")
                        return
                    }

                    if (!this.isInputValidation(this.$refs.educationDiv)) {
                        return
                    }

                    if (!this.isInputValidation(this.$refs.careerDiv)) {
                        return
                    }

                    this.submit('goNextStep')
                });
            },
            async submitEducation() {

                for(let i = 0; i < this.educationList.length; i++) {
                    const object = this.educationList[i]
                    if (this.isEmptyEducation(object)) {
                        this.educationList.splice(i, 1)
                    }
                }

                if (!this.isNotEmptyList(this.educationList)) {
                    this.educationList = []
                }

                let params = {
                    raNo: this.raNo
                    , educationList: this.educationList
                };

                await this.axios.post(this.api.recruit.applicant.educationRegister, params)
                    .then(res => {
                        this.resResult.resCode = res.data.code
                        this.resResult.resMessage = res.data.message
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            },
            async submitCareer() {
                if (!this.isNotEmptyList(this.careerList)) {
                    this.careerList = []
                }
                let params = {
                    raNo: this.raNo
                    , careerList: this.careerList
                };

                await this.axios.post(this.api.recruit.applicant.careerRegister, params)
                    .then(res => {
                        this.resResult.resCode = res.data.code
                        this.resResult.resMessage = res.data.message
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            },
            async submitLicense() {
                if (!this.isNotEmptyList(this.licenseList)) {
                    this.licenseList = []
                }
                let params = {
                    raNo: this.raNo
                    , licenseList: this.licenseList
                };

                await this.axios.post(this.api.recruit.applicant.licenseRegister, params)
                    .then(res => {
                        this.resResult.resCode = res.data.code
                        this.resResult.resMessage = res.data.message
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            },
            async submitLanguage() {
                if (!this.isNotEmptyList(this.languageList)) {
                    this.languageList = []
                }
                let params = {
                    raNo: this.raNo
                    , languageList: this.languageList
                };

                await this.axios.post(this.api.recruit.applicant.languageRegister, params)
                    .then(res => {
                        this.resResult.resCode = res.data.code
                        this.resResult.resMessage = res.data.message
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });

            },
            async submitTraining() {
                if (!this.isNotEmptyList(this.trainingList)) {
                    this.trainingList = []
                }

                let params = {
                    raNo: this.raNo
                    , trainingList: this.trainingList
                };

                await this.axios.post(this.api.recruit.applicant.trainingRegister, params)
                    .then(res => {
                        this.resResult.resCode = res.data.code
                        this.resResult.resMessage = res.data.message
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            },
            async getRecruitNoticeView() {
                this.axios
                    .get(this.api.recruit.notice.view, {
                        params: {
                            rnNo: this.rnNo,
                            updateCntYn: 'N'
                        }
                    })
                    .then((res) => {
                        this.noticeInfo = res.data
                        if(this.noticeInfo == '' || !this.$commonUtils.isExpose(this.noticeInfo.exposeEndDt)){
                            alert('종료되었거나 조회되지 않는 게시글입니다.')
                            this.$router.push('/')
                        }
                    });
            },
            isInputValidation(component) {

                let isValid = true;
                const invalidFields = [];

                const findInvalidFields = (component) => {
                    if (component.children) {
                        for (var i = 0; i < component.children.length; i++) {
                            if (component.children[i].readOnly || component.children[i].disabled || (component.children[i].attributes.emptyValid && !component.children[i].value)) {
                                continue
                            }
                            if (component.children[i].tagName === 'INPUT') {
                                if (component.children[i].type === 'text') {
                                    if (!component.children[i].value || component.children[i].value === '') {
                                        invalidFields.push(component.children[i]);
                                        continue
                                    }
                                    if (component.children[i].attributes.validType) {
                                        if (component.children[i].attributes.validType.value === 'email' && !this.$func.validateEmail(component.children[i].value)) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                        } else if (component.children[i].attributes.validType.value === 'tel' && !this.$func.validateTel(component.children[i].value)) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                        }
                                    }
                                }
                            } else if (component.children[i].tagName === 'TEXTAREA') {
                                if (!component.children[i].value || component.children[i].value === '') {
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                                if (component.children[i].minLength > 0 && component.children[i].minLength > component.children[i].value.length) {
                                    component.children[i].validMsg = 'minLength'
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                            } else if (component.children[i].tagName === 'SELECT') {
                                if (!component.children[i].value || component.children[i].value === '') {
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                            }
                            findInvalidFields(component.children[i]);
                        }
                    }
                };

                findInvalidFields(component);

                if (invalidFields.length > 0) {
                    // console.log(invalidFields[0])

                    invalidFields[0].scrollIntoView({ block: 'center' })
                    setTimeout(() => {
                        invalidFields[0].focus();
                    }, 100)

                    let index= invalidFields[0].closest('td').cellIndex
                    let objNm = invalidFields[0].closest('table').querySelector('thead').querySelector('tr').querySelectorAll('th')[index].textContent;

                    objNm = objNm.replace('* ', '').trim();
                    objNm = objNm.replace('\n', '');
                    objNm = objNm.replace(/ +/g, " ");

                    if (invalidFields[0].validMsg) {
                        alert(`[${objNm}] 항목이 유효하지 않습니다. 다시 확인해주세요.`);
                    } else {
                        alert(`[${objNm}] 항목을 입력해주세요.`);
                    }

                    isValid = false;
                }

                return isValid;
            },
        },
        mounted() {
            this.raNo = this.$store.state.applicantStore.raNo;
            // this.$store.commit('setRaNo', null)

            if (this.type === 'update' && !this.raNo) {
                alert("올바르지 않은 접근입니다.")
                this.$router.push({ path: '/application/editApplication.do'})
            }

            if (this.rnNo) {
                this.getRecruitNoticeView()
            }

            if (this.raNo) {
                this.getRecruitApplicantView()
                this.getEducationList()
                this.getCareerList()
                this.getLicenseList()
                this.getLanguageList()
                this.getTrainingList()
            }

            setTimeout(() => {
                gsap.to('.floating__save', {scrollTrigger: {trigger: '.container',start: 'bottom-=0px bottom',toggleClass: { targets: '.floating__save', className: 'on' },scrub: true}});
            }, 300);

        }
    };
</script>