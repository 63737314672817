<template>
  <router-view />
</template>

<style>
  @import url('../src/assets/resource/css/reset.css');
  @import url('../src/assets/resource/css/common.css');
  @import url('../src/assets/resource/css/font.css');
</style>

<script>
export default{
	data() {
		return {
			
		}
	},
	watch: {
        $route(to, from) {
			if(to.path != from.path) this.loginChk();
        },
    },
	methods: {
		loginChk() {
			/*this.axios
				.get(this.api.member.info)
				.then((res) => {
					if(res.data){
						this.$store.commit('setStatus', true)
					}else{
						this.$store.commit('setStatus', false)
						this.$store.commit('myInfoInit')
					}
				})
				.catch((err) => {
					console.log(err);
				});*/
        },
	},
	mounted() {
		this.loginChk();
	},
}
</script>