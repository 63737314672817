<template>
    <header class="header" :class="{ 'show': isMenuActive }" @mouseenter="activateMenu" @mouseleave="resetHeaderActive">
        <div class="header__inner">
            <div class="header__front">
                <h1 class="header__tit">
                    <a href="/">
                        <img src="@/assets/resource/icon/logo-b.png" alt="">
                        <span>채용공고</span>
                    </a>
                </h1>
                <div class="header__menu-wrap">
                    <div class="line"></div>
                    <ul class="header__list">
                        <li class="header__item" @mouseenter="menuLine(1)" @click="handleClick(1)"> 
                            <a href="javascript:;" class="header__link">입사지원</a>
                        </li>
                        <li class="header__item" @mouseenter="menuLine(2)" @click="handleClick(2)">
                            <a href="javascript:;" class="header__link">합격조회</a>
                        </li>
                        <li class="header__item" @mouseenter="menuLine(3)" @click="handleClick(3)">
                            <a href="javascript:;" class="header__link">채용안내</a>
                        </li>
                        <li class="header__item" @mouseenter="menuLine(4)" @click="handleClick(4)">
                            <a href="javascript:;" class="header__link">인사제도</a>
                        </li>
                    </ul>
                </div>
                <slide-up-down class="header__cont-box" :active="isMenuActive">
                    <div class="header__cont-inner">
                        <ul class="header__cont-list">
                            <li class="header__cont-item" @mouseenter="menuLine(1)" @click="handleClickAndClose(1)">
                                <h2 class="header__cont-tit">
                                    <router-link to="/application/editApplication.do" @click="resetHeaderActive">
                                        수정/조회
                                    </router-link>
                                </h2>
                            </li>
                            <li class="header__cont-item" @mouseenter="menuLine(2)" @click="handleClickAndClose(2)">
                                <h2 class="header__cont-tit">
                                    <router-link to="/inquiry/checkResult.do" @click="resetHeaderActive">
                                        결과확인
                                    </router-link>
                                </h2>
                                <h2 class="header__cont-tit">
                                    <router-link to="/inquiry/guideResult.do" @click="resetHeaderActive">
                                        합격자안내
                                    </router-link>
                                </h2>
                                <h2 class="header__cont-tit">
                                    <router-link to="/inquiry/directions.do" @click="resetHeaderActive">
                                        오시는길
                                    </router-link>
                                </h2>
                            </li>
                            <li class="header__cont-item" @mouseenter="menuLine(3)" @click="handleClickAndClose(3)">
                                <h2 class="header__cont-tit">
                                    <router-link to="/guide/procedure.do" @click="resetHeaderActive">
                                        채용절차
                                    </router-link>
                                </h2>
                            </li>
                            <li class="header__cont-item" @mouseenter="menuLine(4)" @click="handleClickAndClose(4)">
                                <h2 class="header__cont-tit">
                                    <router-link to="/system/talentIdeal.do" @click="resetHeaderActive">
                                        인재상
                                    </router-link>
                                </h2>
                                <h2 class="header__cont-tit">
                                    <router-link to="/system/vision.do" @click="resetHeaderActive">
                                        비전
                                    </router-link>
                                </h2>
                                <h2 class="header__cont-tit">
                                    <router-link to="/system/welfareBenefits.do" @click="resetHeaderActive">
                                        복리후생
                                    </router-link>
                                </h2>
                            </li>
                        </ul>
                    </div>
                </slide-up-down>
            </div>
        </div>
    </header>
</template>

<script>
    import SlideUpDown from 'vue-slide-up-down';

    export default {
        components: {
            SlideUpDown,
        },
        data() {
            return {
                isMenuActive: false,
                activeMenuIndex: null,
            };
        },
        methods: {
            activateMenu() {
                this.isMenuActive = true;
            },
            resetHeaderActive() {
                this.isMenuActive = false;
                this.activeMenuIndex = null;
            },
            menuLine(item) {
                let headerLine = document.querySelector(".header__menu-wrap .line");
                headerLine.style.left = `${(item - 1) * 25}%`;
            },
            handleClick(item) {
                if (this.activeMenuIndex === item) {
                    this.resetHeaderActive();
                } else {
                    this.isMenuActive = true;
                    this.activeMenuIndex = item;
                    this.menuLine(item);
                }
            },
            handleClickAndClose(item) {
                this.handleClick(item);
                this.resetHeaderActive();
            },
        },
    };
</script>
