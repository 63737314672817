import { createRouter, createWebHistory } from 'vue-router';
import MainView from '../views/main/MainView.vue';

import recruitRouteComponentMapping from "@/router/recruitAutoRouteMapping"; // autoRouteMapping에서 생성된 매핑 객체를 가져옵니다.

const staticRoutes = [
    {
        path: '/',
        name: 'main',
        component: MainView
    },
];

const routes = Object.keys(recruitRouteComponentMapping).map(path => ({
    path, // 매핑 객체에서 정의된 경로
    name: recruitRouteComponentMapping[path],
    // component: () => import('@/components' + recruitRouteComponentMapping[path] + '.vue') // 해당 경로에 매핑된 컴포넌트를 동적으로 로드
    component: () => import('@/views/program/RecruitCommonProgramView.vue') // 공통 레이아웃 컴포넌트 적용
}));

routes.push(...staticRoutes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return { top: 0 };
        }
    },
});

export default router;
