<!-- 전송페이지 공통 -->
<template>
    <div class="container">
        <div class="sub-tit">
            <!--<h2></h2>-->
        </div>
        <div class="inner">
            <div class="join-tab">
                <ul class="join-tab__list">
                    <li class="join-tab__item">
                        <div class="step"><span class="text">step </span><span class="num">01</span></div>
                        <div class="tit">개인정보입력</div>
                    </li>
                    <li class="join-tab__item ">
                        <div class="step"><span class="text">step </span><span class="num">02</span></div>
                        <div class="tit">학력 및 경력사항 등 입력</div>
                    </li>
                    <li class="join-tab__item ">
                        <div class="step"><span class="text">step </span><span class="num">03</span></div>
                        <div class="tit">자기소개서 작성</div>
                    </li>
                    <li class="join-tab__item active">
                        <div class="step"><span class="text">step </span><span class="num">04</span></div>
                        <div class="tit">입사지원 완료</div>
                    </li>
                </ul>
            </div>
            <div class="find-info">
                <div class="contents find-info__box">
                    <img src="../../assets/resource/icon/logo-b.png" class="logo" alt="">
                    <p class="find-info__text-type2">지원해주셔서 감사합니다. <br> 좋은 결과가 있으시길 바라겠습니다.</p>
                </div>
                <div class="contents btn-wrap center">
                    <router-link to="/" class="btn">메인으로 이동</router-link>
                    <a href="javascript:;" class="btn blue" @click="printRecruitApplicantView">입사지원서 출력</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                rnNo: this.$route.query.rnNo,
                // raNo: this.$route.query.raNo,
                raNo: '',
                type: this.$route.query.type,
            };
        },
        methods: {
            async printRecruitApplicantView() {
                this.$store.commit('setRaNo', this.raNo)
                window.open(`/application/printApplication.do?rnNo=${this.rnNo}`, '_blank');
            },
        },
        mounted() {
            this.raNo = this.$store.state.applicantStore.raNo;

            if (!this.raNo) {
                alert("올바르지 않은 접근입니다.")
                this.$router.push({ path: '/application/editApplication.do'})
            }
        },
        beforeUnmount() {
            this.$store.commit('setRaNo', null)
        }
    };
</script>