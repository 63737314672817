<template>
    <div class="container">
        <div class="sub-tit">
            <h2>{{title}}</h2>
        </div>
        <div class="inner">
            <div v-html="content.content" class="contents"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            content: '',
        }
    },
    watch: {
        $route(to, from){
            if(to.path != from.path){
                setTimeout(() => {
                    let tableItems = document.querySelectorAll(".table-wrap");

                    tableItems.forEach((el) => {
                    this.checkTableWidth(el);
                    });

                    let tableItem = document.querySelectorAll(".table-wrap");

                    tableItem.forEach((el) => {
                        el.addEventListener("click", () => {
                            el.classList.add('on');
                        });
                    }); 
                }, 100);
               
            }
        }
    },
    methods: {
        getContent(){
            const path = '/hospital/etc/privacy.do'
            var menuNo
            for(let i of this.menuList){
                if(i.menuUrl == path && i.useYn == 'Y') menuNo = i.menuNo
            }
            this.axios.get(this.api.content.menuNo, {
                params: {
                    menuNo: menuNo
                }
            })
            .then(res => {
                this.content = res.data
                this.createScript(res.data.contentScript);
            })
        },
        createScript(item) {
            setTimeout(() => {
                const head = document.getElementsByTagName('head')[0];
                const script = document.createElement('script');
                const createText = document.createTextNode(item);
                script.appendChild(createText);
                head.appendChild(script);
            }, 500);
        },
        checkTableWidth(element) {
            let table = element.querySelector('table');
            if (table.offsetWidth > element.offsetWidth) {
                element.classList.add('scroll');
            } else {
                element.classList.remove('scroll');
            }
        }
    },
    mounted() {
        this.getContent()
    
    },
    updated() {
        this.$nextTick(() => {
        let tableItems = this.$el.querySelectorAll(".table-wrap");

        tableItems.forEach((el) => {
            this.checkTableWidth(el);
            el.addEventListener("click", () => {
            el.classList.add('on');
            });
        });
        });
    },
}
</script>