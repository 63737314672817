export default{
    // 게시판
    article: {
        // GET
        /**
         * 리스트 조회 시 {boardNo}
         * 상세 조회 시 {boardNo}/{articleNo}
         */
        list: '/api/article/',
        delete: '/api/article/',
        view: '/api/article/',
        newsList: '/api/article/newsList',
        /**
         * 게시판 정보 조회 시 {boardId}.do
         */
        board: '/api/board/',
        main: '/api/mainBoardList.do',
        // POST
        insert: '/api/article/',
    },
    // 컨텐츠
    content: {
        // GET
        stfnt: '/api/cntnt/stfnt.do',
        menuNo: '/api/content.do',
        cntntmstNo: '/api/content/cntntmstNo.do'
    },
    // 건강정보
    health: {
        // GET
        cancer: '/api/cancer.do',
        cancerList: '/api/cancerList.do',
        healthMental: '/api/healthMental.do',
        healthMentalList: '/api/healthMentalList.do',
        kdca: '/api/kdca.do',
        kdcaList: '/api/kdcaList.do'
    },
    member: {
        // GET
        dupId: '/api/user/checkDuplicate.do',
        parentCheck: '/api/user/api/member/parentCheck.do',
        logout: '/api/member/logout.do',
        info: '/api/info.do',
        findId: '/api/user/findId.do',
        findIdPhone: '/api/user/findIdPhone.do',
        findPassword: '/api/user/findPassword.do',
        findPasswordPhone: '/api/user/findPasswordPhone.do',
        encData: '/api/user/api/getEncData.do',
        ipinEncData: '/api/user/api/getIpinEncData.do',
        checkDuplicateDi: '/api/user/checkDuplicateDi.do',
        // POST
        regist: '/api/user/registerReg.do',
        login: '/api/member/login.do',
        pwChange: '/api/user/pwChange.do',
        sleep: '/api/member/sleep.do',
        encDataChk: '/api/user/api/phone/result.do',
        ipinEncDataChk: '/api/user/api/ipin/result.do',
        updateMember: '/api/user/updateMember.do',
    },
    token: {
        // GET
        getToken: '/api/user/api/getCrytoToken.do', // 암호화 토큰 발급
        decodeData: '/api/user/api/decrypResult.do', // 인증결과 복호화
        nameCheck: '/api/user/api/nameCheck.do', // 실명인증
    },
    schedule: {
        doctor: '/schedule/insertDoctor.do',
        dept: '/schedule/insertDepartment.do',
        nonpayment: '/schedule/insertNonpayment.do'
    },
    department: {
        list: '/api/department.do',
        view: '/api/department/info.do'
    },
    doctor: {
        list: '/api/doctor/doctorList.do',
        doctorList: '/api/doctorApi.do',
        deptDoctorList: '/api/doctor/deptDoctorList.do',
        view: '/api/doctor/getDoctor.do',
        schedule: '/api/getDoctorSchedule.do',
        absence: '/api/getDoctorAbsence.do',
        doctorCons: '/api/doctor/consonantList.do',
        record: '/api/getDoctorRecordList.do',
        thesis: '/api/getDoctorThesisList.do',
    },
    appointment: {
        time: '/api/getDoctorTime.do',
        doctorList: '/api/getDoctorList.do',
        reservation: '/api/reservation/reserve.do',
        reservationChange: '/api/reservation/reserveChange.do',
        simpleReservation: '/api/reservation/simpleReservation.do',
        getPatInfo: '/api/reservation/getPatInfo.do',
    },
    mypage: {
        reservationList: '/api/mypage/reservation/list.do',
        mainInfo: '/api/mypage/main.do',
        articleList: '/api/mypage/write/list.do',
        articleView: '/api/mypage/write/view.do',
        articleUpdate: '/api/mypage/write/modify.do',
        healthList : '/api/mypage/health/list.do',
        healthReg : '/api/mypage/health/register.do',
        drugList : '/api/drugList.do',
        drugDetail : '/api/drugDetail.do',
        outList : '/api/out.do',
        inList : '/api/schedule.do',
        checkList : '/api/healthCheckResult.do',
        healthDel : '/api/mypage/health/delete.do',
        myInfoUpdate : '/api/mypage/info/modify.do',
        myInfoDelete : '/api/mypage/info/withdraw.do',
        passwordCheck: '/api/mypage/info/check.do',
    },
    nonpaymentApi: {
        list: '/api/nonpayment/nonpaymentList.do',
        baseDt: '/api/nonpayment/getBaseDt.do',
    },
    favorite: {
        add: '/api/favoInfo/favoInfoAddData.do',
        delete: '/api/favoInfo/favoInfoDelData.do',
        list : '/api/mypage/bookmark/data.do',
    },
    baseCode: {
        list : '/api/baseCode/baseCodeList.do',
    },
    recruit: {
        notice: {
            list : '/api/recruit/notice/list.do',
            view : '/api/recruit/notice/view.do',
        },
        guide: {
            list : '/api/recruit/guide/list.do',
            view : '/api/recruit/guide/view.do',
        },
        applicant: {
            register : '/api/recruit/applicant/register.do',
            view : '/api/recruit/applicant/view.do',
            checkDuplicate : '/api/recruit/applicant/checkDuplicate.do',
            findPassword : '/api/recruit/applicant/findPassword.do',
            educationRegister : '/api/recruit/applicant/educationRegister.do',
            educationList : '/api/recruit/applicant/educationList.do',
            careerRegister : '/api/recruit/applicant/careerRegister.do',
            careerList : '/api/recruit/applicant/careerList.do',
            licenseRegister : '/api/recruit/applicant/licenseRegister.do',
            licenseList : '/api/recruit/applicant/licenseList.do',
            languageRegister : '/api/recruit/applicant/languageRegister.do',
            languageList : '/api/recruit/applicant/languageList.do',
            trainingRegister : '/api/recruit/applicant/trainingRegister.do',
            trainingList : '/api/recruit/applicant/trainingList.do',
            introductionRegister : '/api/recruit/applicant/introductionRegister.do',
            introductionView : '/api/recruit/applicant/introductionView.do',
            submit : '/api/recruit/applicant/submit.do',
        },
    },
}