<!-- 전송페이지 공통 -->
<template>
    <div class="floating__btb">
        <div class="save-btn"><button type="button"><img src="@/assets/resource/icon/save-btn.png" alt=""></button></div>
        <div class="goTop"><a href="#"><img src="@/assets/resource/icon/top_btn.png" alt=""></a></div>
    </div>
    <div class="join-tab">
        <ul class="join-tab__list">
            <li class="join-tab__item">
                <div class="step"><span class="text">step </span><span class="num">01</span></div>
                <div class="tit">개인정보입력</div>
            </li>
            <li class="join-tab__item active">
                <div class="step"><span class="text">step </span><span class="num">02</span></div>
                <div class="tit">학력 및 경력사항 등 입력</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">03</span></div>
                <div class="tit">자기소개서 작성</div>
            </li>
            <li class="join-tab__item ">
                <div class="step"><span class="text">step </span><span class="num">04</span></div>
                <div class="tit">입사지원 완료</div>
            </li>
        </ul>
    </div>
    <div class="input-form type1">
        <div class="text-box type1 info-text mt20">
            <div class="input-form__top-wrap">
                <p class="input-form__top-text type2">- 개인정보 보호를 위해 일정시간(120분) 동안 입력이 없을 경우 작성한 내용이 자동삭제 됩니다.</p>
            </div>
        </div>
    </div>


    <div class="contents patient">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet"><span class="bullet01"></span></div>
            <div class="depth__text">
                <h4 class="depth01__title title">입력 유의사항</h4>
            </div>
        </div>
        <div class="text-box type2">
            <div class="text-box__wrap type2">
                <h5 class="text-box__tit">1. 학교명</h5>

                <p class="text-box__desc">
                    - 고등학교, 전문대학, 대학교, 편입대학교, RN-BSN(간호사인 경우), 대학원등의 순서로 입력 <br>
                    - 학교명 등이 변경되어 선택목록에 없을 경우 인사팀으로 연락해 주시면 등록해 드립니다. [단, 구(舊) 학교명이 있으면 선택가능] <br>
                    - 검정고시일 경우에는 검정고시 일자를 각각 입력하고 학교명에는 '검정고시'라고 입력한 후 학교과정을 선택하고 '졸업'을 선택한 후, 소재지는 검정고지 시행지역을 입력하십시오.
                </p>

                <h5 class="text-box__tit">2. 전공</h5>
                <p class="text-box__desc">- 전공이 목록에 없을 경우 인사팀으로 연락해 주십시오</p>

                <h5 class="text-box__tit">3. 소재지</h5>
                <p class="text-box__desc">- 대도시 단위로만 입력하십시오. (ex. 서울, 경기, 인천, 충남, 대전, 제주 등)</p>

                <h5 class="text-box__tit">4. 석차</h5>
                <p class="text-box__desc">- 석차는 석차 / 전체 인원 순으로 입력하십시오 (예, 45/200)</p>

                <h5 class="text-box__tit">5. 학점</h5>
                <p class="text-box__desc">
                    - 4.5 만점기준으로 기재 하십시오. <br>
                    - 소수 첫째 자리로 입력하십시오 (반올림 금지) <br>
                    - 4.3 만점일 경우 4.5만점 환산점수로 기재해야 하며, 4.5만점 기준으로 성적증명서 제출하십시오 <br>
                    - 편입한 경우 전적대학의 성적까지 기재하십시오 (단, 중퇴의 경우는 기재하지 마십시오)
                </p>
            </div>
        </div>
    </div>
    <!-- 학력사항 -->
    <div class="contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">학력사항</h4>
            </div>
        </div>
        <div class="table-wrap table-type1 long">
            <div>
                <table style="table-layout: fixed;">
                    <caption class="blind"></caption>
                    <colgroup>
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                        <col style="width: 80px;">
                        <col style="width: auto">
                        <col style="width: calc(100%/11);">
                        <col style="width: 110px">
                        <col style="width: calc(100%/11);">
                        <col style="width: 80px">
                        <col style="width: 100px">
                        <col style="width: 100px">
                    </colgroup>
                    <thead>
                        <tr>
                            <th><span class="c-red">* </span>학력구분</th>
                            <th><span class="c-red">* </span>학교명</th>
                            <th><span class="c-red">* </span>전공</th>
                            <th><span class="c-red">* </span>수업 년한</th>
                            <th><span class="c-red">* </span>입학일자~졸업일자</th>
                            <th><span class="c-red">* </span>학위코드/이수</th>
                            <th><span class="c-red">* </span>학위번호</th>
                            <th><span class="c-red">* </span>소재지</th>
                            <th><span class="c-red">* </span>평점</th>
                            <th><span class="c-red">* </span>석차</th>
                            <th>백분율</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <select name="eduscholflag" title="학력구분" style="width: 100%;">
                                    <option value="">선택</option>
                                    <option value="01">무</option>
                                    <option value="02">초재</option>
                                    <option value="03">초졸</option>
                                    <option value="04">중재</option>
                                    <option value="05">중졸</option>
                                    <option value="06">중중퇴</option>
                                    <option value="07">고재</option>
                                    <option value="08">고졸</option>
                                    <option value="09">고중퇴</option>
                                    <option value="19">초중퇴</option>
                                    <option value="99">기타</option>
                                </select>
                            </td>
                            <td><input type="text"></td>
                            <td></td>
                            <td><input type="text" style="text-align: center;"></td>
                            <td>
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                    <span class="line" style="margin: 0 10px;">~</span>
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="endDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <select title="소재지 선택">
                                    <option value="">선택</option>
                                    <option value="서울특별시">서울특별시</option>

                                    <option value="인천광역시">인천광역시</option>
                                    <option value="대전광역시">대전광역시</option>
                                    <option value="대구광역시">대구광역시</option>
                                    <option value="광주광역시">광주광역시</option>
                                    <option value="부산광역시">부산광역시</option>
                                    <option value="울산광역시">울산광역시</option>

                                    <option value="경기도">경기도 </option>
                                    <option value="강원도">강원도 </option>
                                    <option value="경상남도">경상남도</option>
                                    <option value="경상북도">경상북도</option>
                                    <option value="충청남도">충청남도</option>
                                    <option value="충청북도">충청북도</option>
                                    <option value="전라남도">전라남도</option>
                                    <option value="전라북도">전라북도</option>
                                    <option value="제주도">제주도 </option>
                                    <option value="기타">기타 </option>
                                </select>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <div style="display: flex; align-items: center;">
                                    <input type="text" style="text-align: center;">/100
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <select name="eduscholflag" title="학력구분">
                                    <option value="">선택</option>
                                    <option value="10">전문대재</option>
                                    <option value="11">전문대졸</option>
                                    <option value="12">전문대중퇴</option>
                                    <option value="13">대재</option>
                                    <option value="14">대졸</option>
                                    <option value="15">대중퇴</option>
                                    <option value="16">대학원재</option>
                                    <option value="17">대학원졸</option>
                                    <option value="18">대학원중퇴</option>
                                    <option value="20">대학원수료</option>
                                    <option value="21">석박통합과정</option>
                                </select>
                            </td>
                            <td class="datalist">
                                <input type="text" list="list" id="numbers" @click="openPopup" />
                                <div class="input-form type1" style="margin-top: 10px;">
                                    <div class="input-form__radio">
                                        <label class="input-item" style="display: flex; cursor: pointer;">
                                            <input type="checkbox" class="radio" name="radio1" value="편입">
                                            <span class="icon"></span> 편입
                                        </label>
                                    </div>
                                </div>
                            </td>
                            <td class="datalist">
                                <input type="text" list="list" id="numbers" @click="openPopup" />
                                <div class="input-form type1" style="margin-top: 10px;">
                                    <div class="input-form__radio">
                                        <label class="input-item" style="display: flex; cursor: pointer;">
                                            <input type="checkbox" class="radio" name="radio2" value="복수전공">
                                            <span class="icon"></span> 복수전공
                                        </label>
                                    </div>
                                </div>
                            </td>
                            <td><input type="text" style="text-align: center;"></td>
                            <td>
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                    <span class="line" style="margin: 0 10px;">~</span>
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="endDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <select name="eduacdmydgcd" title="학위코드">
                                    <option value="">선택</option>

                                    <option value="01">학사</option>

                                    <option value="02">석사재학</option>

                                    <option value="03">석사수료</option>

                                    <option value="04">석사</option>

                                    <option value="05">박사재학</option>

                                    <option value="06">박사수료</option>

                                    <option value="07">박사</option>

                                    <option value="08">기타</option>

                                    <option value="09">전문학사</option>

                                    <option value="10">의학석사</option>

                                    <option value="11">치의학석사</option>

                                    <option value="12">보건학석사</option>

                                    <option value="13">행정학석사</option>

                                    <option value="14">경영학석사</option>

                                    <option value="15">사회복지학석사</option>

                                    <option value="16">약학석사</option>

                                    <option value="17">간호학석사</option>

                                    <option value="18">이학석사</option>

                                    <option value="19">신학석사</option>

                                    <option value="20">교육학석사</option>

                                    <option value="21">문학석사</option>

                                    <option value="22">법학석사</option>

                                    <option value="23">의학박사</option>

                                    <option value="24">치의학박사</option>

                                    <option value="25">경영학박사</option>

                                    <option value="26">석박수료</option>

                                    <option value="27">석박과정재학</option>

                                    <option value="28">석박과정졸업</option>

                                    <option value="29">이학사</option>

                                    <option value="30">공학사</option>

                                    <option value="31">의무석사</option>

                                    <option value="32">약학사</option>

                                    <option value="33">치무석사</option>

                                </select>
                                <select name="educmpltcursstdyflag" title="이수구분" style="margin-top:4px;">
                                    <option value="">선택</option>
                                    <option value="01">이수</option>
                                    <option value="02">미이수</option>
                                </select>
                            </td>
                            <td><input type="text"></td>
                            <td>
                                <select title="소재지 선택">
                                    <option value="">선택</option>
                                    <option value="서울특별시">서울특별시</option>

                                    <option value="인천광역시">인천광역시</option>
                                    <option value="대전광역시">대전광역시</option>
                                    <option value="대구광역시">대구광역시</option>
                                    <option value="광주광역시">광주광역시</option>
                                    <option value="부산광역시">부산광역시</option>
                                    <option value="울산광역시">울산광역시</option>

                                    <option value="경기도">경기도</option>
                                    <option value="강원도">강원도</option>
                                    <option value="경상남도">경상남도</option>
                                    <option value="경상북도">경상북도</option>
                                    <option value="충청남도">충청남도</option>
                                    <option value="충청북도">충청북도</option>
                                    <option value="전라남도">전라남도</option>
                                    <option value="전라북도">전라북도</option>
                                    <option value="제주도">제주도 </option>
                                    <option value="기타">기타 </option>
                                </select>
                            </td>
                            <td><input type="text" style="text-align: center;">/4.5</td>
                            <td>
                                <div class="input-form type1" style="margin-bottom: 10px;">
                                    <div class="input-form__radio">
                                        <label class="input-item" style="display: flex; cursor: pointer;">
                                            <input type="checkbox" class="radio" name="radio2" value="석차">
                                            <span class="icon"></span>석차
                                        </label>
                                    </div>
                                </div>
                                <input type="text" style="text-align: center;">/<input type="text" style="text-align: center;">
                            </td>
                            <td>
                                <div style="display: flex; align-items: center;">
                                    <input type="text" style="text-align: center;">/100
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="info-text depth__wrap depth01 contents">
                <div class="depth__text">
                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <span class="bullet04"></span>
                        </div>
                        <div class="depth__text">
                            <p class="c-blue">복수전공 체크 시, 항목을 추가하여 주전공과 부전공을 각각 입력해주시기 바랍니다.</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <span class="bullet04"></span>
                        </div>
                        <div class="depth__text">
                            <p>졸업예정자는 학위번호란에 '졸업예정'으로 입력하여 주시기 바랍니다.</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <span class="bullet04"></span>
                        </div>
                        <div class="depth__text">
                            <p>대학교 입력은 필수사항입니다.(대학원 졸업자의 경우 대학교 졸업사항도 입력하여 주시기 바랍니다.</p>
                        </div>
                    </div>
                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <span class="bullet04"></span>
                        </div>
                        <div class="depth__text">
                            <p>저장된 학력정보 삭제가 필요할 시 항목삭제를 눌러주시기 바랍니다.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-wrap" style="margin-top: 20px;">
                <a href="javascript:;" class="btn gray">항목추가 +</a>
                <a href="javascript:;" class="btn gray">항목삭제 -</a>
            </div>
        </div>
    </div>
    <!-- 학력사항 -->

    <!-- 경력사항 -->
    <div class="contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">경력사항</h4>
            </div>
        </div>
        <div class="table-wrap table-type1 long">
            <div>
                <table style="table-layout: fixed;">
                    <caption class="blind"></caption>
                    <colgroup>
                        <col style="width: auto;">
                        <col style="width: calc(100%/10);">
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                        <col style="width: calc(100%/11);">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>근무기간</th>
                            <th>근무처</th>
                            <th>고용형태</th>
                            <th>부서명</th>
                            <th>직책명</th>
                            <th>직위명</th>
                            <th>직무</th>
                            <th>퇴직사유</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                    <span class="line" style="margin: 0 10px;">~</span>
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="endDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                    <div class="input-form type1" style="margin: 0; margin-left: 15px;">
                                        <div class="input-form__radio">
                                            <label class="input-item" style="display: flex; cursor: pointer; margin: 0;">
                                                <input type="checkbox" class="radio" name="radio2" value="재직중">
                                                <span class="icon"></span> 재직중 </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><input type="text"></td>
                            <td>
                                <select name="eduscholflag" title="학력구분" style="width: 100%;">
                                    <option value="">선택</option>
                                    <option value="">정규직</option>
                                    <option value="">비정규직</option>
                                    <option value="">기타</option>
                                </select>
                            </td>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="info-text depth__wrap depth01 contents">
                <div class="depth__text">
                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <span class="bullet04"></span>
                        </div>
                        <div class="depth__text">
                            <p>경력사항 기간 입력 시 과거 사항부터 입력을 부탁드립니다.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-wrap" style="margin-top: 20px;">
                <a href="javascript:;" class="btn gray">항목추가 +</a>
                <a href="javascript:;" class="btn gray">항목삭제 -</a>
            </div>
        </div>
    </div>
    <!-- 경력사항 -->


    <!-- 자격면허 -->
    <div class="contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">자격면허</h4>
            </div>
        </div>
        <div class="table-wrap table-type1 long">
            <div>
                <table style="table-layout: fixed;">
                    <caption class="blind"></caption>
                    <colgroup>
                        <col style="width: auto;">
                        <col style="width: calc(100%/7);">
                        <col style="width: calc(100%/5);">
                        <col style="width: calc(100%/5);">

                    </colgroup>
                    <thead>
                        <tr>
                            <th>자격면허명</th>
                            <th>취득일자</th>
                            <th>발급기관</th>
                            <th>자격면허번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="datalist">
                                <input type="text" list="list" id="numbers" @click="openPopup">
                            </td>
                            <td>
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 100%;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                            </td>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="info-text depth__wrap depth01 contents">
                <div class="depth__text">
                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <span class="bullet04"></span>
                        </div>
                        <div class="depth__text">
                            <p>조회불가항목의 경우 '기타'를 입력 후 상세 입력해 주십시오.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-wrap" style="margin-top: 20px;">
                <a href="javascript:;" class="btn gray">항목추가 +</a>
                <a href="javascript:;" class="btn gray">항목삭제 -</a>
            </div>
        </div>
    </div>
    <!-- 자격면허 -->


    <!-- 외국어 -->
    <div class="contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">외국어</h4>
            </div>
        </div>
        <div class="table-wrap table-type1 long">
            <div>
                <table style="table-layout: fixed;">
                    <caption class="blind"></caption>
                    <colgroup>
                        <col style="width: calc(100%/6);">
                        <col style="width: calc(100%/6);">
                        <col style="width: calc(100%/6);">
                        <col style="width: calc(100%/6);">
                        <col style="width: calc(100%/6);">
                        <col style="width: calc(100%/6);">

                    </colgroup>
                    <thead>
                        <tr>
                            <th>외국어</th>
                            <th>종합등급</th>
                            <th>시험코드</th>
                            <th>취득일자</th>
                            <th>시험점수</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <select name="forgforgnlangcd" title="외국어" style="width:100%">
                                    <option value="">선택</option>

                                    <option value="01">영 어</option>

                                    <option value="02">독일어</option>

                                    <option value="03">불란서어</option>

                                    <option value="04">서반아어</option>

                                    <option value="05">일본어</option>

                                    <option value="09">중국어</option>

                                    <option value="12">러시아어</option>

                                    <option value="13">에스페란토어</option>

                                    <option value="14">스페인어</option>

                                    <option value="15">이태리어</option>

                                    <option value="16">아랍어</option>

                                    <option value="17">포루투칼어</option>

                                    <option value="99">기타</option>

                                </select>
                            </td>
                            <td>
                                <select name="forgcolggrde" title="종합등급">
                                    <option value="">선택</option>
                                    <option value="A">상</option>
                                    <option value="B">중</option>
                                    <option value="C">하</option>

                                </select>
                            </td>
                            <td>
                                <select name="forgexamcd" title="시험코드" style="width:85px">
                                    <option value="">선택</option>
                                    <option value="01">TOEIC</option>
                                    <option value="03">JPT</option>
                                    <option value="04">기타</option>
                                    <option value="05">TEPS</option>
                                    <option value="06">TOEFL_PBT</option>
                                    <option value="07">TOEFL_CBT</option>
                                    <option value="08">TOEFL_IBT</option>
                                    <option value="09">New TEPS</option>
                                </select>
                            </td>
                            <td>
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 100%;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                            </td>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="info-text depth__wrap depth01 contents">
                <div class="depth__text">
                    <div class="depth__wrap">
                        <div class="depth__bullet">
                            <span class="bullet04"></span>
                        </div>
                        <div class="depth__text">
                            <p>TOEIC 및 외국어 점수는 2년 내의 취득 성적만 인정되며, 기타 외국어일 경우에는 해당 외국어와 시험명 등을 직접 입력하십시오.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-wrap" style="margin-top: 20px;">
                <a href="javascript:;" class="btn gray">항목추가 +</a>
                <a href="javascript:;" class="btn gray">항목삭제 -</a>
            </div>
        </div>
    </div>
    <!-- 외국어 -->

    <!-- 교욱연수 -->
    <div class="contents">
        <div class="info-text depth__wrap depth01">
            <div class="depth__bullet">
                <span class="bullet01"></span>
            </div>
            <div class="depth__text">
                <h4 class="depth01__title title">교욱연수</h4>
            </div>
        </div>
        <div class="table-wrap table-type1 long">
            <div>
                <table style="table-layout: fixed;">
                    <caption class="blind"></caption>
                    <colgroup>
                        <col style="width: calc(100%/4);">
                        <col style="width: calc(100%/4);">
                        <col style="width: calc(100%/4);">
                        <col style="width: calc(100%/4);">

                    </colgroup>
                    <thead>
                        <tr>
                            <th>교육기관</th>
                            <th>교육내용</th>
                            <th>교육기간</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                            <td>
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" placeholder="시작일자" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                    <span class="line" style="margin: 0 10px;">~</span>
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="endDate" :format="dateFormat" placeholder="종료일자" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                            </td>
                            <td><input type="text"></td>
                        </tr>
                        <tr>
                            <td><input type="text"></td>
                            <td><input type="text"></td>
                            <td>
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" placeholder="시작일자" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                    <span class="line" style="margin: 0 10px;">~</span>
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="endDate" :format="dateFormat" placeholder="종료일자" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                            </td>
                            <td><input type="text"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="btn-wrap" style="margin-top: 20px;">
                <a href="javascript:;" class="btn gray">항목추가 +</a>
                <a href="javascript:;" class="btn gray">항목삭제 -</a>
            </div>
        </div>
    </div>
    <!-- 교욱연수 -->

    <div class="contents text-box type1 info-text">
        <ul class="text-box__list">
            <li class="text-box__item">이전단계 수정이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 수정가능합니다.</li>
            <li class="text-box__item">*공용 PC에서 접속하셨을 경우 개인정보보호를 위해 입력완료 후 브라우저 창을 종료해주시기 바랍니다.</li>
        </ul>
    </div>
    <div class="contents btn-wrap center">
        <a href="javascript:;" class="btn">중간저장</a>
        <a href="javascript:;" class="btn blue">다음단계</a>
    </div>



    <!-- 팝업 -->
    <div class="first_reserve_popup name_certify_popup my_health_popup" v-if="isPopupVisible">
        <div class="first_reserve_wrap">
            <div class="first_tit">
                <h4>전공검색</h4>
                <a href="javascript:;" @click="closePopup">
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div class="board__search">
                <div class="board__input">
                    <input type="text" placeholder="검색어를 입력해주세요.">
                    <button type="button" class="board__input-btn"></button>
                </div>
            </div>
            <ul class="contents search-list">
                <li class="search-item"><button type="button">전공1</button></li>
                <li class="search-item"><button type="button">전공전공전공2</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공3</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공5</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
                <li class="search-item"><button type="button">전공전공전공전공전공전공전공전공전공전공전공전공6</button></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import DatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        components: {
        },
        data() {
            return {

                isChecked: false,
                imageSrc: require('@/assets/resource/img/img-add.png'),
                date: null, // 선택된 날짜를 저장할 변수
                dateFormat: 'YYYY-MM-DD', // 날짜 형식
                isPopupVisible: false
            };
        },
        methods: {
            previewImage(event) {
                const file = event.target.files[0];
                if (file && file.type.startsWith('image/') && file.size <= 1000000) { // 1000KB 이하
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.imageSrc = e.target.result;
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('유효하지 않은 파일입니다. JPG 파일만 가능하며 크기는 1000KB 이하이어야 합니다.');
                }
            },
            isCheckedFn() {
                this.isChecked = true;
            },
            openPopup() {
                this.isPopupVisible = true;
            },
            closePopup() {
                this.isPopupVisible = false;
            },
        },
        setup() {
            const date = ref(null); // 선택된 날짜를 저장할 변수
            const startDate = ref(null); // 시작 날짜
            const endDate = ref(null); // 종료 날짜
            const dateFormat = 'yyyy-MM-dd'; // 날짜 형식

            return {
                date,
                startDate,
                endDate,
                dateFormat,
            };
        },
        components: {
            DatePicker,
        },
    };
</script>