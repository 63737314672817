import { createApp } from 'vue';
import App from './App.vue';
import store from './store/store';
import router from './router';
import api from './api/api';
import axios from 'axios';
import global from './assets/resource/js/global';
import components from '@/components/index.js';

// import AOS from 'aos';
// import 'aos/dist/aos.css';

const app = createApp(App);
// AOS 초기화
// AOS.init({
//   duration: 1000,
//   once: true,
// });
// api.js 전역변수화
app.config.globalProperties.api = api;
app.config.globalProperties.axios = axios;
// app.config.globalProperties.AOS = AOS
app.config.globalProperties.healthMentalUrl = 'https://www.mentalhealth.go.kr/dissJson.do?key=';
app.config.globalProperties.kcdaUrl = 'https://api.kdca.go.kr/api/provide/healthInfo?TOKEN=18f08e6a8c04&cntntsSn=';
app.config.globalProperties.cancerUrl = 'http://www.cancer.go.kr/api/cancerData.do?cancer_name=';
app.config.globalProperties.naverId = 'O4VmgAaKsTC5TXDjqZ2e';
app.config.globalProperties.naverSecret = 'V4pHL5B8aC';
// 실 key
// app.config.globalProperties.kakaoJSKey = '38222be8098eb112d7650900d95b4b96'
// if(window.Kakao){
//     window.Kakao.init("38222be8098eb112d7650900d95b4b96");
// }
// test key
app.config.globalProperties.kakaoJSKey = '00d50b7532c3e65e155c4b853b3c462f';
if (window.Kakao) {
    window.Kakao.init('00d50b7532c3e65e155c4b853b3c462f');
}

axios.interceptors.request.use(
    config => {
        // console.log('interceptors.request', config)
        return config;
    },
    error => {
        // console.log('interceptors.request', error)
        return Promise.reject(error);
    }
);

Object.keys(components).forEach(name => {
    app.component(name, components[name]);

    // console.log(`Component '${name}' registered globally.`);
});

await axios.get('/api/baseCode/baseCodeList.do', {
    params: {
        instNo: 1,
        parentNo : 'RECRUIT_TYPE',
    }
}).then(res => {
    // console.log(res.data)
    store.commit('setRecruitTypeList', res.data)
});

await axios.get('/api/baseCode/baseCodeList.do', {
    params: {
        instNo: 1,
        parentNo : 'RECRUIT_CATEGORY',
    }
}).then(res => {
    // console.log(res.data)
    store.commit('setRecruitCategoryList', res.data)

    const recruitCategoryDetailList = []
    for (const recruitCategory of res.data) {
        axios.get('/api/baseCode/baseCodeList.do', {
            params: {
                instNo: 1,
                parentNo : recruitCategory.codeNo,
            }
        }).then(res => {
            // console.log(res.data)
            recruitCategoryDetailList.push(...res.data)
        });
    }
    // console.log(recruitCategoryDetailList)
    store.commit('setRecruitCategoryDetailList', recruitCategoryDetailList)
});

// menu api 1회 호출(다중 호출 방지)
axios
    .get('/menu.do', {
        params: {
            lang: 'kr',
            instNo: 1
        }
    })
    .then(res => {
        app.config.globalProperties.menuList = res.data;
        app.use(store).use(router).use(global).mount('#app');
        // AOS.refresh(); // AOS 초기화 후 refresh 호출
    });
