const baseCodeStore = {
    state: {
        // 채용구분
        recruitTypeList: [],
        // 직종
        recruitCategoryList: [],
        // 세부직종
        recruitCategoryDetailList: []
    },
    mutations: {
        setRecruitTypeList: function (state, recruitTypeList) {
            state.recruitTypeList = recruitTypeList;
        },
        setRecruitCategoryList: function (state, recruitCategoryList) {
            state.recruitCategoryList = recruitCategoryList;
        },
        setRecruitCategoryDetailList: function (state, recruitCategoryDetailList) {
            state.recruitCategoryDetailList = recruitCategoryDetailList;
        },
    }
}

export default baseCodeStore