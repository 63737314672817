<template>
    <div class="LocationBar">
        <div class="inner">
            <ul class="Location">
                <li class="Location__item home">
                    <a href="" class="Location__item--btn">
                        <img src="../../assets/resource/icon/Location_home.png" alt="">
                    </a>
                </li>
                <li class="Location__item" v-for="(item, index) in menuItems" :key="index" :class="{ 'active': activeIndex === index }">
                    <a href="javascript:;" class="Location__item--btn" @click="toggleActive($event, index)">
                        <p>{{ item.label }}</p>
                        <span></span>
                    </a>
                    <div class="Location__item--Layer">
                        <ul>
                            <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
                                <component :is="subItem.menuUrl.startsWith('http') ? 'a' : 'router-link'" :href="subItem.menuUrl" :to="subItem.menuUrl">{{ subItem.menuName }}</component>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
  </div>
</template>


<script>
export default {
    data() {
        return {
            menuItems: [
                {
                    label: ''
                    , subItems: [
                        {menuName: '입사지원', menuUrl: '/application/editApplication.do'}
                        , {menuName: '합격조회', menuUrl: '/inquiry/checkResult.do'}
                        , {menuName: '채용안내', menuUrl: '/guide/procedure.do'}
                        , {menuName: '인사제도', menuUrl: '/system/talentIdeal.do'}
                    ]
                },
            ],
            subItems: {
                application: [
                    {menuName: '수정/조회', menuUrl: '/application/editApplication.do'}
                ]
                ,
                inquiry: [
                    {menuName: '결과확인', menuUrl: '/inquiry/checkResult.do'}
                    , {menuName: '합격자안내', menuUrl: '/inquiry/guideResult.do'}
                    , {menuName: '오시는길', menuUrl: '/inquiry/directions.do'}
                ]
                ,
                guide: [
                    {menuName: '채용절차', menuUrl: '/guide/procedure.do'}
                ]
                ,
                system: [
                    {menuName: '인재상', menuUrl: '/system/talentIdeal.do'}
                    , {menuName: '비전', menuUrl: '/system/vision.do'}
                    , {menuName: '복리후생', menuUrl: '/system/welfareBenefits.do'}
                ]
            },
            activeIndex: -1,
        };
    },
    watch: {
        $route(to, from) {
            if (to.path != from.path) {
                this.setLocation()
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);

        this.setLocation()
    },
    unmounted() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleActive(event,index) {
            const target = event.currentTarget;
            const parentLayer = target.closest('.Location__item').querySelector('.Location__item--Layer');
            if (this.activeIndex === index) {
                // 두 번째 클릭 시 높이를 0으로 설정
                parentLayer.style.height = '0';
                this.activeIndex = -1; // 비활성화
            } else {
                const layers = this.$el.querySelectorAll('.Location__item--Layer');
                layers.forEach(layer => {
                    layer.style.height = '0';
                });
                const ulHeight = parentLayer.querySelector('ul').offsetHeight + 'px';
                parentLayer.style.height = ulHeight;
                this.activeIndex = index; // 활성화
            }
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                // 메뉴 외부를 클릭하면 엑티브 제거
                this.activeIndex = -1;

                // .Location__item--Layer의 높이를 0으로 설정
                const layers = this.$el.querySelectorAll('.Location__item--Layer');
                layers.forEach(layer => {
                    layer.style.height = '0';
                });
            }
        },
        setLocation() {
            const path = this.$route.path
            const pathSegments = path.replace('/', '').split('/')

            const depth1SubItems = this.menuItems[0].subItems
            let depth1SubTitle = depth1SubItems[0].menuName
            for (const subItem of depth1SubItems) {
                if (subItem.menuUrl.includes(pathSegments[0])) {
                    depth1SubTitle = subItem.menuName
                }
            }
            this.menuItems[0].label = depth1SubTitle

            const depth2SubItems = this.subItems[pathSegments[0]].slice()
            // console.log(depth2SubItems)
            let depth2SubTitle = depth2SubItems[0].menuName
            if (path.includes('/application/noticeDetail.do')) {
                depth2SubItems.unshift({menuName: '채용공고', menuUrl: this.$route.fullPath})
            }
            for (const subItem of depth2SubItems) {
                if (subItem.menuUrl.includes(path)) {
                    depth2SubTitle = subItem.menuName
                }
            }
            if (path.includes('/inquiry/guideDetail.do')) {
                depth2SubTitle = '합격자안내'
            }

            const depth2 = {
                label: depth2SubTitle , subItems: depth2SubItems
            }
            if (this.menuItems.length > 1) {
                this.menuItems.pop()
            }
            this.menuItems.push(depth2)
        }
    }
}
</script>
