<template>
    <footer class="footer">
        <div class="floating__wrap">
            <div class="floating__btn">
                <div class="go-top"><a href="#"><img src="../../assets/resource/icon/top_btn.png" alt="상단이동"></a></div>
            </div>
        </div>
        <div class="footer__inner">
            <img src="../../assets/resource/icon/footer-logo.png" class="footer__logo" alt="창원파티마병원 로고">
            <div class="footer__menu">
                <ul class="footer__list">
                    <li class="footer__item">
                        <router-link to="/term/privacy.do" class="footer__link blue">개인정보처리방침</router-link>
                    </li>
                    <li class="footer__item">
                        <router-link to="/term/email.do" class="footer__link">이메일무단수집거부</router-link>
                    </li>
                    <li class="footer__item">
                        <router-link to="/term/terms.do" class="footer__link">이용약관</router-link>
                    </li>
                </ul>
                <hr class="mo-line">
                <p class="footer__desc mt30">주소 : 경상남도 창원시 의창구 창이대로 45 창원파티마병원 (우 51394)</p>
                <p class="footer__desc">대표번호 : 055-270-1000(통합컨텍센터) <span class="line"></span> <br> 팩스 : 055-265-7766</p>
                <p class="footer__desc mt50">Copyrightⓒ Changwon Fatima Hospital.</p>
            </div>
            <div class="footer__site">
                <div class="footer__btn">
                        <div class="footer__site-wrap" :style="{ height: dynamicHeight }">
                            <ul class="footer__site-list">
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">의료기관</p>
                                    <a href="https://www.fatima.or.kr/main/main.do" title="새창열림" target="_blank">대구파티마병원</a>
                                    <a href="https://www.cmc.or.kr/main.main.sp" title="새창열림" target="_blank">가톨릭중앙의료원</a>
                                    <a href="https://www.cmcseoul.or.kr/page/main" title="새창열림" target="_blank">가톨릭대학교서울성모병원</a>
                                </li>
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">종교</p>
                                    <a href="https://www.benedictine.or.kr" title="새창열림" target="_blank">(재)대구포교성베네딕도수녀회</a>
                                    <a href="http://cathms.kr" title="새창열림" target="_blank">천주교마산교구</a>
                                </li>
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">관공서</p>
                                    <a href="https://www.mohw.go.kr/" title="새창열림" target="_blank">보건복지부</a>
                                    <!-- 이동안됨 -->
                                    <a href="http://health.changwon.go.kr/" title="새창열림" target="_blank">창원시보건소</a>
                                    <a href="https://www.nhis.or.kr/nhis/index.do" title="새창열림" target="_blank">국민건강보험공단</a>
                                    <a href="https://www.hira.or.kr/main.do" title="새창열림" target="_blank">건강보험심사평가원</a>
                                    <a href="https://www.comwel.or.kr/comwel/landing.jsp" title="새창열림" target="_blank">근로복지공단</a>
                                </li>
                                <li class="footer__site-item">
                                    <p class="footer__site-tit">학회</p>
                                    <a href="https://www.kha.or.kr/main" title="새창열림" target="_blank">대한병원협회</a>
                                    <a href="https://www.kosqua.net/" title="새창열림" target="_blank">한국의료질향상학회</a>
                                    <a href="https://www.qi.or.kr/" title="새창열림" target="_blank">한국QI간호사회</a>
                                    <a href="https://www.koiha.or.kr/web/kr/index.do" title="새창열림" target="_blank">의료기관평가인증원</a>
                                </li>
                            </ul>
                        </div>
                    <button type="button" class="footer__plusBtn" @click="toggleMenu()">관련사이트 <span class="plus"></span></button>
                </div>
            </div>
        </div>
    </footer>
</template>


<script>

export default {    
    data() {
        return {
            isMenuOpen: false,
            dynamicHeight: '0'
        };  
    },
    methods: {
        toggleMenu() {
            let footerSiteHeight = document.querySelector(".footer__site-list").offsetHeight;
            this.isMenuOpen = !this.isMenuOpen;
                if (this.isMenuOpen) {   
                    this.dynamicHeight = `${footerSiteHeight}px`;
                } else {
                    this.dynamicHeight = `0`;
                }
        }

    },
    mounted() {
        setTimeout(() => {
            gsap.to('.floating__btn', {scrollTrigger: {trigger: '.footer',start: 'top bottom',toggleClass: { targets: '.floating__btn', className: 'on' },scrub: true,}});
        }, 300);
    }
}
</script>

