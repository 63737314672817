<template>
    <div class="container">
        <div class="sub-tit">
            <h2>합격자안내</h2>
        </div>
        <div class="inner">
            <div class="board-view">
                <div class="board-view__top">
                    <ul class="board-view__share-list" :class="{active : this.shareList}">
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/copy-icon.png" alt=""></a></li>
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/band-icon.png" alt=""></a></li>
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/x-icon.png" alt=""></a></li>
                        <li class="board-view__share-item"><a href="board-view__share-link"><img src="@/assets/resource/icon/facebook-icon.png" alt=""></a></li>
                    </ul>
                    <button type="button" class="board-view__btn share" @click="shareFn()"></button>
                    <button type="button" class="board-view__btn print" @click="print()"></button>
                </div>
                <div class="board-view__wrap" style="margin-top: 0px">
                    <div class="board-view__tit">
                        <h3>{{ guideInfo.rgTitle }}</h3>
                        <div class="board-view__info">
                            <span class="date">{{ $commonUtils.timestampToDate(guideInfo.createdDt) }}</span>
                            <div class="view">
                                <span class="icon"></span>
                                {{ guideInfo.readCnt }}
                            </div>
                        </div>
                    </div>
                    <div class="board-view__file" v-if="JSON.stringify(guideInfo.attachBag) !== '{}'">
                        <div class="tit">첨부파일</div>
                        <ul class="file-list">
                            <li class="file-item" v-for="(i, idx) in attachList" :key="idx">
                                <button type="button" @click="this.$commonUtils.fileDownload(i.attachNo, i.displayName)">
                                    <span class="icon"></span><em class="num">{{ idx + 1 }} .</em> {{ i.displayName }}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!-- 컨텐츠 내용 -->
                    <div class="board-view__cont">
                        <div v-html="guideInfo.rgContent" class="board-view__cont-inner"></div>
                    </div>
                    <div class="contents btn-wrap center">
                        <router-link to="/inquiry/guideResult.do" class="btn gray">목록</router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>


<script>
export default {
    data() {
        return {
            shareList: false,
            guideInfo: {},
            attachList: [],
            rgNo: this.$route.query.rgNo,
        };
    },
    watch: {
    },
    methods: {
        shareFn() {
            this.shareList = !this.shareList
        },
        getRecruitGuideView() {
            this.axios
                .get(this.api.recruit.guide.view, {
                    params: {
                        rgNo: this.rgNo,
                    }
                })
                .then((res) => {
                    this.guideInfo = res.data
                    this.attachList = res.data.attachBag.attach;
                });
        },
        print() {
            window.print()
        },
    },
    async mounted() {
        // console.log(this.rgNo)
        if (this.rgNo) {
            this.getRecruitGuideView()
        } else {
            alert("올바르지 않은 접근입니다.")
            this.$router.back()
        }
    },
};
</script>