// 컴포넌트를 동적으로 스캔
const requireComponent = require.context(
    '@/components', // 컴포넌트들이 위치한 디렉토리
    true, // 하위 디렉토리까지 탐색
    /\.vue$/ // .vue 파일만 대상으로 함
)

// 경로-컴포넌트 매핑 객체 생성
const recruitRouteComponentMapping = {}

requireComponent.keys().forEach(fileName => {

    const pathSegments = fileName.replace('.vue', '').split('/');

    // 매핑할 디렉토리만 추가
    if (pathSegments[1] === 'application' || pathSegments[1] === 'guide' || pathSegments[1] === 'inquiry' || pathSegments[1] === 'system' || pathSegments[1] === 'term') {
        let path = '/' + pathSegments[1] + '/' + pathSegments[2].charAt(0).toLowerCase() + pathSegments[2].slice(1)
        let component = fileName.replace('./', '/')

        // 매핑 객체에 추가
        recruitRouteComponentMapping[`/${path}.do`] = component
    }

});

export default recruitRouteComponentMapping