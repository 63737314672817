<template>
    <div class="container">
        <div class="sub-tit">
            <h2>입사지원서</h2>
        </div>
        <div class="inner">
            <div>
                <div class="input-form type1">
                    <div class="text-box type1 info-text mt20">
                        <div class="input-form__top-wrap">
                            <!--<p class="input-form__top-text type2 c-red">- 온라인 입사지원시 브라우저(익스, 크롬등) 당 1개의 채용공고만 지원해주시기 바랍니다.(동일 브라우저에서 한개이상 지원불가)</p>-->
                            <p class="input-form__top-text type2">- 개인정보 보호를 위해 중간저장 후 일정시간<b class="c-red">(60분)</b>이 경과할 경우 작성한 내용이 자동삭제 됩니다.</p>
                            <p class="input-form__top-text type2">- 지원서 작성 시 이전 페이지로 강제이동시 입력 오류가 발생할 수 있으니 주의 바랍니다.</p>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="text-box type4 top-blue" style="border: none;">
                        <div class="text-box__wrap">
                            <div class="info-text ">
                                <h3 class="depth03__title title">개인정보 수집 및 이용에 대한 동의</h3>
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            본 홈페이지를 활용하여 채용을 실시하는 창원파티마병원 지원자의 개인정보를 중요시하며, <br>
                                            「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,「개인정보보호법」을 준수하기 위하여 노력하고 있습니다.<br>
                                            개인정보수집과 이용에 대한 안내입니다. 반드시 읽어보시고 동의를 하셔야 본 기관에 지원하실 수 있습니다.<br><br>
                                            * 입사지원시 확인사항
                                            <br>
                                            입력하신 정보는 입사를 제외한 어떠한 경우에도 사용되지 않습니다. <br>
                                            입사지원이 마감된 후에는 수정 및 취소가 불가합니다. <br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="info-text">
                                <h3 class="depth03__title title">채용서류 반환 청구 안내</h3>
                                <div class="depth__wrap depth03">
                                    <div class="depth__text">
                                        <p>
                                            <b>「채용절차의 공정화에 관한 법률」 제11조(채용서류의 반환 등) 1항</b> <br>
                                            구인자는 구직자의 채용 여부가 확정된 이후 구직자(확정된 채용대상자는 제외한다)가 채용서류의 반환을 청구하는 경우에는 본인임을 확인한 후 대통령령으로 정하는 바에 따라 반환하여야 한다. 다만, 제7조 제1항에 <span style="border-bottom: 1px solid #e8508d;">따라 홈페이지 또는 전자우편으로 제출된 경우나 구직자가 구인자의 요구 없이 자발적으로
                                        제출한 경우에는 그러하지 아니하다.</span><br>
                                            <br>
                                            <b>「채용절차의 공정화에 관한 법률 시행령」 제4조(채용서류의 반환 청구기간)</b> <br>
                                            법 제11조제4항에 따른 채용서류의 반환 청구기간은 구직자의 채용 여부가 확정된 날 이후 14일부터 180일까지의 기간의 범위에서 구인자가 정한 기간으로 한다. 이 경우 구인자는 채용 여부가 확정되기 전까지 구인자가 정한 채용서류의 반환 청구기간을 구직자에게 알려야 한다. <br>
                                            <br>
                                            <b>「채용절차의 공정화에 관한 법률 시행규칙」 제3조(채용서류의 반환청구 방법)</b> <br>
                                            구직자는 법 제11조제2항에 따라 채용서류의 반환을 청구하는 경우 별지 제3호서식의 채용서류 반환청구서를 홈페이지, 전자우편 또는 팩스 등으로 구인자에게 제출하여야 한다. <br>
                                            <br>
                                            위 관련 법률에 의거하여 본원은 채용서류 반환과 관련하여 아래와 같이 고지하오니 참고하여 주시기 바랍니다. <br>
                                            <br>
                                            □ 채용서류 반환 청구를 희망하는 구직자는 [별지 제3호서식]채용서류 반환청구서(채용절차의 공정화에 관한 법률 시행규칙)를 작성하시어 채용담당자에게 발송 <br>
                                            □ 응시원서에 작성한 이메일과 청구 반환 요청한 이메일등 정보가 다를 경우 본인확인을 요청할 수 있음 <br>
                                            □ 합격자발표일로 부터 최대 180일까지 구직자의 서류반환 청구 요청이 없을 경우 「개인정보보호법」에 따라 채용서류를 파기함
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="contents join-agree">

                    <div class="join-agree__total">
                        <div class="agree-input">
                            <label class="join-agree__total-input">
                                <input type="checkbox" class="agree-input__radio" true-value="Y" false-value="N" v-model="agreeAll" @change="setAgreeAll">
                                <span class="icon"></span> 전체 약관에 동의합니다.
                            </label>
                        </div>
                    </div>

                    <div class="patient">
                        <!-- 개인정보 수집·이용 동의  -->
                        <div>
                            <div class="contents text-box type2" ref="agree1">
                                <h3 class="text-box__tit">개인정보 수집·이용 동의 <span class="c-red">(필수)</span></h3>
                                <div class="text-box type2">
                                    <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                        <h5 class="text-box__tit">1. 수집 목적</h5>
                                        <p class="text-box__desc">입사지원 서비스 이용(채용 전형 진행, 합격 여부 확인, 이력서 작성 및 조회/수정 등)</p>

                                        <h5 class="text-box__tit">2. 수집 항목</h5>
                                        <p class="text-box__desc">성명(국문), 성명(영문), 성별, 생년월일, 사진, 휴대전화, 이메일, 응급연락망, 주민등록지(등본주소지), 현주소, 비밀번호, 학력사항, 병역사항</p>

                                        <h5 class="text-box__tit">3. 보유 및 이용 기간</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">
                                            [입사 지원자] 최종합격자 발표 후 1개월 까지
                                            <br>
                                            [최종 합격자] 영구보관 (재직 중 인사관리)
                                        </p>
                                        <br>
                                        <p class="small-text">※ 지원자는 개인정보 수집·이용에 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 채용 진행이 불가합니다.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="agree-input">
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree1" value="Y" v-model="agree1" @change="checkAgreeAll" id="agree1">
                                    <span class="icon"></span> 동의합니다
                                </label>
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree1" value="N" v-model="agree1" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의하지 않습니다
                                </label>
                            </div>
                        </div>

                        <!-- 개인정보 수집·이용 내역(선택) -->
                        <div>
                            <div class="contents text-box type2" ref="agree2">
                                <h3 class="text-box__tit">개인정보 수집·이용 내역 <span>(선택)</span></h3>
                                <div class="text-box type2">
                                    <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                        <h5 class="text-box__tit">1. 수집 목적</h5>
                                        <p class="text-box__desc">지원자 역량 및 특기를 파악하기 위한 추가정보 확인</p>

                                        <h5 class="text-box__tit">2. 수집 항목</h5>
                                        <p class="text-box__desc">경력사항, 외국어, 자격면허, 교육연수, 자기소개, 취미, 특기</p>

                                        <h5 class="text-box__tit">3. 보유 및 이용 기간</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">
                                            [입사 지원자] 최종합격자 발표 후 1개월 까지
                                            <br>
                                            [최종 합격자] 영구보관 (재직 중 인사관리)
                                        </p>
                                        <br>
                                        <p class="small-text">※ 지원자는 위의 선택적인 개인 정보 수집 - 이용에 대해 동의를 거부 하실 수 있으며, 이는 필수적인 항목이 아니므로 동의를 거부하시더라도 지원이 가능합니다. 단, 이 경우 선택항목을 채용 전형에 활용 할 수 없습니다.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="agree-input">
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree2" value="Y" v-model="agree2" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의합니다
                                </label>
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree2" value="N" v-model="agree2" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의하지 않습니다
                                </label>
                            </div>
                        </div>

                        <!-- 민감정보 수집에 대한 동의 (선택) -->
                        <div>
                            <div class="contents text-box type2" ref="agree3">
                                <h3 class="text-box__tit">민감정보 수집에 대한 동의 <span>(선택)</span></h3>
                                <div class="text-box type2">
                                    <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                        <h5 class="text-box__tit">1. 수집 목적</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">부서 배치 적합도 파악</p>

                                        <h5 class="text-box__tit">2. 수집 항목</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">보훈사항, 장애여부, 채용 신체검사 결과</p>

                                        <h5 class="text-box__tit">3. 보유 및 이용 기간</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">
                                            [입사 지원자] 최종합격자 발표 후 1개월 까지
                                            <br>
                                            [최종 합격자] 영구보관 (재직 중 인사관리)
                                        </p>
                                        <br>
                                        <p class="small-text">※ 지원자는 민감정보 수집 - 이용에 대한 동의를 거부할 수 있습니다.
                                            단, 동의를 거부할 경우, 관련 법률에 근거한 우대 지원을 받지 못할 수 있으며, 직원 채용에 제한을 받을 수 있습니다.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="agree-input">
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree3" value="Y" v-model="agree3" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의합니다
                                </label>
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree3" value="N" v-model="agree3" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의하지 않습니다
                                </label>
                            </div>
                        </div>

                        <!-- 제 3자 제공 동의 (필수) -->
                        <div>
                            <div class="contents text-box type2" ref="agree4">
                                <h3 class="text-box__tit">제 3자 제공 동의 <span class="c-red">(필수)</span></h3>
                                <div class="text-box type2">
                                    <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                        <h5 class="text-box__tit">1. 제공 기관</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">자격증, 경력, 학위 확인 기관</p>

                                        <h5 class="text-box__tit">2. 제공 목적</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">제출서류 진위여부 확인</p>

                                        <h5 class="text-box__tit">3. 제공 항목</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">지원자의 자격증, 경력, 학위 진위 여부 확인에 필요한 사항</p>

                                        <h5 class="text-box__tit">3. 보유기간</h5>
                                        <p class="text-box__desc" style=" color: #111; text-decoration: underline; font-weight: 700;">
                                            정보처리 목적 달성 후 즉시 파기
                                        </p>
                                        <br>
                                        <p class="small-text">※ 지원자는 제 3자 제공 동의를 거부할 권리가 있습니다.
                                            단, 동의를 거부할 경우 채용 진행이 불가합니다.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="agree-input">
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree4" value="Y" v-model="agree4" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의합니다
                                </label>
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree4" value="N" v-model="agree4" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의하지 않습니다
                                </label>
                            </div>
                        </div>

                        <!-- 고유식별정보(주민등록번호) 처리에 대한 안내 -->
                        <div>
                            <div class="contents text-box type2" ref="agree5">
                                <h3 class="text-box__tit">고유식별정보(주민등록번호) 처리에 대한 안내 <span class="c-red">(필수)</span></h3>
                                <div class="text-box type2">
                                    <div class="text-box__wrap type2" style="border-top: 2px solid var(--main-blue);">
                                        <h5 class="text-box__tit">채용 전형 시에는 지원자의 주민등록번호를 수집 - 이용하지 않으며, 채용이 확정된 지원자에 한해 관계 법령에 따라 수집 - 이용합니다.</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="agree-input">
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree5" value="Y" v-model="agree5" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의합니다
                                </label>
                                <label class="agree-input__item">
                                    <input type="radio" class="agree-input__radio" name="agree5" value="N" v-model="agree5" @change="checkAgreeAll">
                                    <span class="icon"></span> 동의하지 않습니다
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="contents btn-wrap center">
                        <a href="javascript:;" class="btn blue" @click="goNextStep">{{ raNo ? '지원서 수정하기' : '지원서 작성하기' }}</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                agreeAll: 'N'
                , agree1: 'N'
                , agree2: 'N'
                , agree3: 'N'
                , agree4: 'N'
                , agree5: 'N'
                , rnNo: this.$route.query.rnNo
                // , raNo: this.$route.query.raNo
                , raNo: ''
                , type: this.$route.query.type
                , noticeInfo: {}
            };
        },
        watch: {
        },
        methods: {
            async goNextStep() {
                if (this.agree1 === 'Y' && this.agree4 === 'Y' && this.agree5 === 'Y') {
                    this.$store.commit('setRaAgree2', this.agree2)
                    this.$store.commit('setRaAgree3', this.agree3)
                    let type = ''
                    if (this.raNo) {
                        this.$store.commit('setRaNo', this.raNo)
                        type = 'update'

                        // 선택동의 사항 저장
                        let formData = new FormData()
                        formData.set('raNo', this.raNo)
                        formData.set('raAgree2', this.agree2)
                        formData.set('raAgree3', this.agree3)

                        await this.axios.post(this.api.recruit.applicant.register, formData)
                    }
                    this.$router.push({path: '/application/applicationStep1.do', query: {rnNo: this.rnNo, type: type}})
                } else if (this.agree1 === 'N') {
                    alert("개인정보 수집·이용 동의 (필수)에 동의 해주시기 바랍니다.")
                    this.$refs.agree1.scrollIntoView({block: 'center'})
                } else if (this.agree4 === 'N') {
                    alert("제 3자 제공 동의 (필수)에 동의 해주시기 바랍니다.")
                    this.$refs.agree4.scrollIntoView({block: 'center'})
                } else if (this.agree5 === 'N') {
                    alert("고유식별정보(주민등록번호) 처리에 대한 안내 (필수)에 동의 해주시기 바랍니다.")
                    this.$refs.agree5.scrollIntoView({block: 'center'})
                }
            },
            setAgreeAll() {
                this.agree1 = this.agreeAll
                this.agree2 = this.agreeAll
                this.agree3 = this.agreeAll
                this.agree4 = this.agreeAll
                this.agree5 = this.agreeAll
            },
            checkAgreeAll() {
                if (this.agree1 === 'Y' && this.agree2 === 'Y' && this.agree3 === 'Y' && this.agree4 === 'Y' && this.agree5 === 'Y') {
                    this.agreeAll = 'Y'
                } else {
                    this.agreeAll = 'N'
                }
            },
            async getRecruitNoticeView() {
                this.axios
                    .get(this.api.recruit.notice.view, {
                        params: {
                            rnNo: this.rnNo,
                            updateCntYn: 'N'
                        }
                    })
                    .then((res) => {
                        this.noticeInfo = res.data
                        if(this.noticeInfo == '' || !this.$commonUtils.isExpose(this.noticeInfo.exposeEndDt)){
                            alert('종료되었거나 조회되지 않는 게시글입니다.')
                            this.$router.push('/')
                        }
                    });
            },
            async getRecruitApplicantView() {
                await this.axios.get(this.api.recruit.applicant.view, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    console.log(res.data)
                    if (res.data) {
                        if (res.data.raAgree1) {
                            this.agree1 = res.data.raAgree1
                        }
                        if (res.data.raAgree2) {
                            this.agree2 = res.data.raAgree2
                        }
                        if (res.data.raAgree3) {
                            this.agree3 = res.data.raAgree3
                        }
                        if (res.data.raAgree4) {
                            this.agree4 = res.data.raAgree4
                        }
                        if (res.data.raAgree5) {
                            this.agree5 = res.data.raAgree5
                        }
                        this.checkAgreeAll()
                    } else {
                        alert("해당 지원정보가 없습니다.")
                    }
                });
            },
        },
        mounted() {

            if (this.type !== 'update') {
                this.$store.commit('setRaNo', null)
            } else {
                this.raNo = this.$store.state.applicantStore.raNo;
            }

            if ((!this.rnNo) || (this.type === 'update' && !this.raNo)) {
                alert("올바르지 않은 접근입니다.")
                this.$router.push({ path: '/application/editApplication.do'})
            }

            if (this.rnNo) {
                this.getRecruitNoticeView()
            }

            if (this.raNo) {
                this.getRecruitApplicantView()
            }
        }
    }
</script>
<style>

</style>