<!-- 전송페이지 공통 -->
<template>
    <div>
        <div class="join-tab">
            <ul class="join-tab__list">
                <li class="join-tab__item active">
                    <div class="step"><span class="text">step </span><span class="num">01</span></div>
                    <div class="tit">개인정보입력</div>
                </li>
                <li class="join-tab__item">
                    <div class="step"><span class="text">step </span><span class="num">02</span></div>
                    <div class="tit">학력 및 경력사항 등 입력</div>
                </li>
                <li class="join-tab__item ">
                    <div class="step"><span class="text">step </span><span class="num">03</span></div>
                    <div class="tit">자기소개서 작성</div>
                </li>
                <li class="join-tab__item ">
                    <div class="step"><span class="text">step </span><span class="num">04</span></div>
                    <div class="tit">입사지원 완료</div>
                </li>
            </ul>
        </div>
        <div class="input-form type1">
            <div class="text-box type1 info-text mt20">
                <div class="input-form__top-wrap">
                    <p class="input-form__top-text type2">- 개인정보 보호를 위해 일정시간<b class="c-red">(60분)</b> 동안 입력이 없을 경우 작성한 내용이 자동삭제 됩니다.</p>
                    <p class="input-form__top-text type2">- 지원서 작성 시 이전 페이지로 강제이동시 입력 오류가 발생할 수 있으니 주의 바랍니다.</p>
                </div>
            </div>
        </div>
        <div class="contents">
            <div class="depth__wrap depth01">
                <div class="depth__bullet"><span class="bullet01"></span></div>
                <div class="depth__text">
                    <h4 class="depth01__title">모집구분</h4>
                </div>
            </div>
            <div class="table-cont">
                <ul class="table-list type02">
                    <li class="table-item">
                        <div class="item tit">부서</div>
                        <div class="item">간호행정교육팀</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">직종</div>
                        <div class="item">간호직</div>
                    </li>
                    <li class="table-item">
                        <div class="item tit">채용구분</div>
                        <div class="item">비정규직</div>
                    </li>
                </ul>
            </div>

            <div class="input-form type1 contents">
                <div class="depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title">인적사항</h4>
                    </div>
                </div>
                <ul class="input-form__list" style="margin-top: 20px;">
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>사진</div>
                            <div class="cont">
                                <div style="display: flex;">
                                    <label class="img-add">
                                        <div class="img">
                                            <img :src="imageSrc" alt="Selected Image" style="max-width: 177px; min-width: 177px; height: 207px; object-fit: cover;" />
                                        </div>
                                        <div class="btn">
                                            <input type="file" @change="previewImage">찾아보기
                                        </div>
                                    </label>
                                    <div style="margin-left: 20px;">
                                        <p class="desc">1. JPG 파일만 가능합니다.</p>
                                        <p class="desc">2. 파일크기는 1000KB 이하이어야 합니다.</p>
                                        <p class="desc">3. jpg 이미지 사이즈 : 3x4 cm or 115x150 px</p>
                                        <p class="desc">4. 6개월 이내 반명함판 사진으로 첨부해 주십시오.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>성명(한글)</div>
                            <div class="cont">
                                <div>
                                    <input type="text" class="cont__text-input">
                                </div>
                                <p class="desc">- 키보드가 영문 입력상태일 경우 한/영 전환버튼을 눌러 한글 전환 후 입력 해주시기 바랍니다.</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item item2">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>성명(영문)</div>
                            <div class="cont row">
                                <span class="line" style="margin-right: 10px;">성</span>
                                <input type="text" class="cont__text-input">
                                <span class="line" style="margin-right: 10px;">이름</span>
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                        <div class="input-form__box">
                            <div class="tit">성별</div>
                            <div class="cont">
                                <div class="input-form__radio">
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="남" checked="">
                                        <span class="icon"></span>
                                        남
                                    </label>
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="여">
                                        <span class="icon"></span>
                                        여
                                    </label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>생년월일</div>
                            <div class="cont">
                                <div class="date_wrap" style="margin: 0;">
                                    <div class="date-input">
                                        <DatePicker type="date" v-model="date" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                                <p class="desc">- 주민등록상의 8자리 생년월일을 입력하여 주시기 바랍니다.ex)1990-12-12</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box phon">
                            <div class="tit"><span class="c-red">* </span>휴대전화</div>
                            <div class="cont">
                                <div style="display: flex; align-items: center;">
                                    <input type="text" class="cont__text-input"> <span class="line">-</span>
                                    <input type="text" class="cont__text-input"> <span class="line">-</span>
                                    <input type="text" class="cont__text-input">
                                </div>
                                <p class="desc">"-"없이 숫자만 입력해주시기 바랍니다. ex)01012345678</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>이메일</div>
                            <div class="cont">
                                <div style="display: flex; align-items: center;">
                                    <input type="text" class="cont__text-input"> <span class="line">@</span>
                                    <input type="text" class="cont__text-input">
                                    <select class="cont__select">
                                        <option value="직접입력">직접입력</option>
                                        <option value="naver.com">naver.com</option>
                                        <option value="gmail.com">daum.net</option>
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="yahoo.com">yahoo.com</option>
                                        <option value="nate.com">nate.com</option>
                                        <option value="korea.com">korea.com</option>
                                        <option value="chol.com">chol.com</option>
                                    </select>
                                </div>
                                <p class="desc">- 비밀번호 분실 시 입력하신 이메일 주소로 비밀번호 찾기 결과가 전송되오니 정확하게 입력하시기 바랍니다.</p>
                            </div>
                        </div>
                    </li>

                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>비밀번호</div>
                            <div class="cont">
                                <div class="password active"><input type="text" class="cont__text-input" data-listener-added_bbbaefcc="true"></div>
                                <p class="desc">- 비밀번호는 영문, 숫자, 특수문자 중 2종류를 조합하여</p>
                                <p class="desc">최소 10자리 이상 또는 3가지 조합하여 8자리 이상으로 만들어 주세요.</p>
                                <p class="desc">- 연속번호, 전화번호, 아이디 등 추측하기 쉬운 개인정보로 비밀번호를 만드실 수 없습니다.</p>
                                <p class="desc">- 특수문자 사용가능 범위 : ! ” # $ ( ) * + - / : ; < -> ? @ [ ] ^ _ ‘ { | } ~ \ /% &</p>
                                <br>
                                <p class="desc">※ 해당 비밀번호는 입사지원서 수정 및 합격자 결과 확인시 필요합니다.</p>
                                <p class="desc">※ 입사지원서 수정은 접수기간 동안 가능합니다.</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>비밀번호 확인</div>
                            <div class="cont">
                                <div class="password">
                                    <input type="text" class="cont__text-input" data-listener-added_bbbaefcc="true">
                                    <p class="desc c-red">비밀번호가 일치하지 않습니다.</p>
                                </div>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn">중복지원 확인(필수)</a>
            </div>
        </div>

        <!-- 기본정보 -->
        <div class="contents">
            <div class="input-form type1 contents">
                <div class="depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title">기본정보</h4>
                    </div>
                </div>
                <ul class="input-form__list" style="margin-top: 20px;">
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>응급연락망</div>
                            <div class="cont">
                                <div>
                                    <input type="text" class="cont__text-input">
                                </div>
                                <p class="desc">"-"없이 숫자만 입력해주시기 바랍니다. ex)01012345678 또는 0212341234</p>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box address">
                            <div class="tit"><span class="c-red">* </span>주민등록지 <br>
                                (등본주소지)
                            </div>
                            <div class="cont">
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                    <button class="btn">우편번호 검색</button>
                                </div>
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                </div>
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item">
                        <div class="input-form__box address">
                            <div class="tit"><span class="c-red">* </span>현주소
                            </div>
                            <div class="cont">
                                <div class="input-form__radio" style="margin-bottom: 10px;">
                                    <label class="input-item" style="cursor: pointer;">
                                        <input type="checkbox" class="radio" name="radio1" v-model="item1" value="본인" checked="">
                                        <span class="icon"></span>
                                        주민등록지 정보와 동일
                                    </label>
                                </div>
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                    <button class="btn">우편번호 검색</button>
                                </div>
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                </div>
                                <div class="cont-item">
                                    <input type="text" class="cont__text-input">
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item item2">
                        <div class="input-form__box">
                            <div class="tit">취미</div>
                            <div class="cont row">
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                        <div class="input-form__box">
                            <div class="tit">특기</div>
                            <div class="cont row">
                                <input type="text" class="cont__text-input">
                            </div>
                        </div>
                    </li>

                </ul>
            </div>

        </div>


        <!-- 보훈사항 -->
        <div class="contents">
            <div class="input-form type1 contents">
                <div class="depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title">보훈사항</h4>
                    </div>
                </div>
                <ul class="input-form__list" style="margin-top: 20px;">
                    <li class="input-form__item item2">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>보훈대상여부</div>
                            <div class="cont radio">
                                <div class="input-form__radio">
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="유" checked="">
                                        <span class="icon"></span>
                                        유
                                    </label>
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="무">
                                        <span class="icon"></span>
                                        무
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>보훈구분</div>
                            <div class="cont row">
                                <select class="cont__select full" name="suppbhflag" title="보훈구분">
                                    <option value="">선택</option>

                                    <option value="01">
                                        무공수훈자
                                    </option>

                                    <option value="02">
                                        공상군경
                                    </option>

                                    <option value="04">
                                        특원(반상)
                                    </option>

                                    <option value="05">
                                        전상군경
                                    </option>

                                    <option value="07">
                                        순국선열
                                    </option>

                                    <option value="08">
                                        애국지사
                                    </option>

                                    <option value="09">
                                        전몰군경
                                    </option>

                                    <option value="10">
                                        순직군경
                                    </option>

                                    <option value="11">
                                        6.25참전재일학도의용공인
                                    </option>

                                    <option value="12">
                                        4.19혁명사망자
                                    </option>

                                    <option value="13">
                                        4.19혁명부상자
                                    </option>

                                    <option value="14">
                                        순직공무원
                                    </option>

                                    <option value="15">
                                        공상공무원
                                    </option>

                                    <option value="16">
                                        특별공로순직자
                                    </option>

                                    <option value="17">
                                        특별공로상이자
                                    </option>

                                    <option value="18">
                                        국가사회발전특별공로자
                                    </option>

                                    <option value="19">
                                        기타
                                    </option>

                                    <option value="20">
                                        상이군경
                                    </option>

                                    <option value="21">
                                        유족
                                    </option>

                                    <option value="22">
                                        전상유족
                                    </option>

                                </select>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item item2">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>보훈대상여부</div>
                            <div class="cont row"><input type="text" class="cont__text-input"></div>
                        </div>
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>유족관계</div>
                            <div class="cont row">

                                <select class="cont__select full" name="suppsrvvfamyrela" title="유족관계">
                                    <option value="">선택</option>

                                    <option value="01">
                                        고조부
                                    </option>

                                    <option value="02">
                                        고조모
                                    </option>

                                    <option value="03">
                                        증조부
                                    </option>

                                    <option value="04">
                                        증조모
                                    </option>

                                    <option value="05">
                                        조부
                                    </option>

                                    <option value="06">
                                        조모
                                    </option>

                                    <option value="07">
                                        백부
                                    </option>

                                    <option value="08">
                                        백모
                                    </option>

                                    <option value="09">
                                        부
                                    </option>

                                    <option value="0A">
                                        배)외조부
                                    </option>

                                    <option value="0B">
                                        배)외조모
                                    </option>

                                    <option value="0C">
                                        계부
                                    </option>

                                    <option value="0D">
                                        계모
                                    </option>

                                    <option value="10">
                                        모
                                    </option>

                                    <option value="11">
                                        숙부
                                    </option>

                                    <option value="12">
                                        숙모
                                    </option>

                                    <option value="13">
                                        고모
                                    </option>

                                    <option value="14">
                                        고모부
                                    </option>

                                    <option value="15">
                                        외숙부
                                    </option>

                                    <option value="16">
                                        이모
                                    </option>

                                    <option value="17">
                                        이모부
                                    </option>

                                    <option value="18">
                                        본인
                                    </option>

                                    <option value="19">
                                        자매
                                    </option>

                                    <option value="20">
                                        형제
                                    </option>

                                    <option value="21">
                                        종형제
                                    </option>

                                    <option value="22">
                                        고종사촌
                                    </option>

                                    <option value="23">
                                        외사촌
                                    </option>

                                    <option value="24">
                                        이종사촌
                                    </option>

                                    <option value="25">
                                        자
                                    </option>

                                    <option value="26">
                                        조카
                                    </option>

                                    <option value="27">
                                        생질
                                    </option>

                                    <option value="28">
                                        손
                                    </option>

                                    <option value="29">
                                        종손
                                    </option>

                                    <option value="30">
                                        시부
                                    </option>

                                    <option value="31">
                                        시모
                                    </option>

                                    <option value="32">
                                        장인
                                    </option>

                                    <option value="33">
                                        장모
                                    </option>

                                    <option value="34">
                                        처
                                    </option>

                                    <option value="35">
                                        남편
                                    </option>

                                    <option value="36">
                                        외숙모
                                    </option>

                                    <option value="37">
                                        형부
                                    </option>

                                    <option value="38">
                                        처제
                                    </option>

                                    <option value="39">
                                        처남
                                    </option>

                                    <option value="40">
                                        시백부
                                    </option>

                                    <option value="41">
                                        시백모
                                    </option>

                                    <option value="42">
                                        시숙부
                                    </option>

                                    <option value="43">
                                        시숙모
                                    </option>

                                    <option value="44">
                                        시동생
                                    </option>

                                    <option value="45">
                                        시누이
                                    </option>

                                    <option value="46">
                                        동서
                                    </option>

                                    <option value="47">
                                        올케
                                    </option>

                                    <option value="48">
                                        자부
                                    </option>

                                    <option value="49">
                                        질부
                                    </option>

                                    <option value="50">
                                        사위
                                    </option>

                                    <option value="51">
                                        손자녀
                                    </option>

                                    <option value="53">
                                        친척
                                    </option>

                                    <option value="54">
                                        지인
                                    </option>

                                    <option value="55">
                                        스승
                                    </option>

                                    <option value="56">
                                        지도교수
                                    </option>

                                    <option value="57">
                                        학과장
                                    </option>

                                    <option value="58">
                                        신부
                                    </option>

                                    <option value="59">
                                        외조부
                                    </option>

                                    <option value="60">
                                        외조모
                                    </option>

                                    <option value="61">
                                        형수
                                    </option>

                                    <option value="62">
                                        매형
                                    </option>

                                    <option value="63">
                                        배)조부
                                    </option>

                                    <option value="64">
                                        배)조모
                                    </option>

                                    <option value="65">
                                        손부
                                    </option>

                                    <option value="66">
                                        제수
                                    </option>

                                    <option value="67">
                                        제부
                                    </option>

                                    <option value="68">
                                        남동생
                                    </option>

                                    <option value="69">
                                        누나(언니)
                                    </option>

                                    <option value="70">
                                        동문
                                    </option>

                                    <option value="71">
                                        딸
                                    </option>

                                    <option value="72">
                                        매
                                    </option>

                                    <option value="73">
                                        매부
                                    </option>

                                    <option value="74">
                                        며느리
                                    </option>

                                    <option value="75">
                                        배)매
                                    </option>

                                    <option value="76">
                                        배)모
                                    </option>

                                    <option value="77">
                                        배)부
                                    </option>

                                    <option value="78">
                                        배)자
                                    </option>

                                    <option value="79">
                                        배)제
                                    </option>

                                    <option value="82">
                                        배)형
                                    </option>

                                    <option value="83">
                                        배우자
                                    </option>

                                    <option value="84">
                                        사촌
                                    </option>

                                    <option value="85">
                                        손녀
                                    </option>

                                    <option value="86">
                                        손자
                                    </option>

                                    <option value="87">
                                        아들
                                    </option>

                                    <option value="88">
                                        여동생
                                    </option>

                                    <option value="89">
                                        외손녀
                                    </option>

                                    <option value="90">
                                        외손자
                                    </option>

                                    <option value="91">
                                        자형
                                    </option>

                                    <option value="92">
                                        제
                                    </option>

                                    <option value="93">
                                        친지
                                    </option>

                                    <option value="94">
                                        형
                                    </option>

                                    <option value="95">
                                        처형
                                    </option>

                                    <option value="96">
                                        은사
                                    </option>

                                    <option value="97">
                                        백숙부모
                                    </option>

                                    <option value="98">
                                        외숙부모
                                    </option>

                                    <option value="99">
                                        기타
                                    </option>

                                </select>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>

        </div>


        <!-- 병역사항 -->
        <div class="contents">
            <div class="input-form type1 contents">
                <div class="depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title">병역사항</h4>
                    </div>
                </div>
                <ul class="input-form__list" style="margin-top: 20px;">
                    <li class="input-form__item item2">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>병역사항 이행여부</div>
                            <div class="cont radio">
                                <div class="input-form__radio">
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="유" checked="">
                                        <span class="icon"></span>
                                        유
                                    </label>
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="무">
                                        <span class="icon"></span>
                                        무
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>면제사유</div>
                            <div class="cont row"><input type="text" class="cont__text-input"></div>
                        </div>
                    </li>
                    <li class="input-form__item item2">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>복무기간</div>
                            <div class="cont row">
                                <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="startDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                    <span class="line" style="margin: 0 10px;">~</span>
                                    <div class="date-input" style="width: 170px;">
                                        <DatePicker type="date" v-model="endDate" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'" />
                                        <img src="@/assets/resource/icon/date_icon.png" alt="아이콘" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>주특기</div>
                            <div class="cont row"><input type="text" class="cont__text-input"></div>
                        </div>
                    </li>
                    <li class="input-form__item item3">
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>역종</div>
                            <div class="cont row">
                                <select class="cont__select full" name="armyclssvcstatcd" title="역종">
                                    <option value="">선택</option>

                                    <option value="01">
                                        예비역
                                    </option>

                                    <option value="02">
                                        보충역
                                    </option>

                                    <option value="03">
                                        국민역
                                    </option>

                                    <option value="04">
                                        미필
                                    </option>

                                    <option value="05">
                                        면제
                                    </option>

                                    <option value="06">
                                        제대예정자
                                    </option>

                                    <option value="07">
                                        미필보충역
                                    </option>

                                    <option value="08">
                                        실역필보충
                                    </option>

                                    <option value="09">
                                        제１국민역
                                    </option>

                                    <option value="10">
                                        제２국민역
                                    </option>

                                    <option value="11">
                                        특례보충역
                                    </option>

                                    <option value="12">
                                        동원지정
                                    </option>

                                    <option value="13">
                                        동원미지정
                                    </option>

                                    <option value="14">
                                        일반예비군
                                    </option>

                                    <option value="15">
                                        민방위
                                    </option>

                                    <option value="99">
                                        기타
                                    </option>

                                </select>
                            </div>
                        </div>
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>병과</div>
                            <div class="cont row">
                                <select class="cont__select full" name="armybrnhmiltsrvccd" title="병과">
                                    <option value="">선택</option>

                                    <option value="01">
                                        보병
                                    </option>

                                    <option value="02">
                                        포병
                                    </option>

                                    <option value="03">
                                        기갑
                                    </option>

                                    <option value="04">
                                        공병
                                    </option>

                                    <option value="05">
                                        통신
                                    </option>

                                    <option value="06">
                                        병기
                                    </option>

                                    <option value="07">
                                        병참
                                    </option>

                                    <option value="08">
                                        수송
                                    </option>

                                    <option value="09">
                                        화학
                                    </option>

                                    <option value="10">
                                        부관 (행정)
                                    </option>

                                    <option value="11">
                                        헌병
                                    </option>

                                    <option value="12">
                                        경리
                                    </option>

                                    <option value="13">
                                        정훈
                                    </option>

                                    <option value="14">
                                        군의
                                    </option>

                                    <option value="15">
                                        치의
                                    </option>

                                    <option value="16">
                                        의정
                                    </option>

                                    <option value="17">
                                        군종
                                    </option>

                                    <option value="18">
                                        법무
                                    </option>

                                    <option value="19">
                                        군악
                                    </option>

                                    <option value="20">
                                        간호
                                    </option>

                                    <option value="21">
                                        전투
                                    </option>

                                    <option value="22">
                                        기관
                                    </option>

                                    <option value="23">
                                        기술
                                    </option>

                                    <option value="24">
                                        시설
                                    </option>

                                    <option value="25">
                                        일반
                                    </option>

                                    <option value="26">
                                        보급
                                    </option>

                                    <option value="27">
                                        조종
                                    </option>

                                    <option value="28">
                                        정비
                                    </option>

                                    <option value="29">
                                        취사
                                    </option>

                                    <option value="30">
                                        레이다
                                    </option>

                                    <option value="31">
                                        암호
                                    </option>

                                    <option value="32">
                                        전문
                                    </option>

                                    <option value="33">
                                        여군
                                    </option>

                                    <option value="34">
                                        위생
                                    </option>

                                    <option value="35">
                                        의무
                                    </option>

                                    <option value="36">
                                        전산
                                    </option>

                                    <option value="37">
                                        전투경찰
                                    </option>

                                    <option value="38">
                                        (해,공군)기술
                                    </option>

                                    <option value="39">
                                        (해,공군)전문
                                    </option>

                                    <option value="40">
                                        (해,공군)전투
                                    </option>

                                    <option value="41">
                                        (해군)함정기관
                                    </option>

                                    <option value="42">
                                        (해병대)보급
                                    </option>

                                    <option value="43">
                                        공중전문의
                                    </option>

                                    <option value="44">
                                        기상
                                    </option>

                                    <option value="45">
                                        발전
                                    </option>

                                    <option value="46">
                                        부안
                                    </option>

                                    <option value="47">
                                        소총
                                    </option>

                                    <option value="48">
                                        운전
                                    </option>

                                    <option value="49">
                                        원호
                                    </option>

                                    <option value="50">
                                        유선
                                    </option>

                                    <option value="51">
                                        작전
                                    </option>

                                    <option value="52">
                                        정보,보안
                                    </option>

                                    <option value="53">
                                        탄약
                                    </option>

                                    <option value="54">
                                        특전
                                    </option>

                                    <option value="55">
                                        항공
                                    </option>

                                    <option value="56">
                                        항공포병
                                    </option>

                                    <option value="57">
                                        행정
                                    </option>

                                    <option value="58">
                                        FM 장비운용
                                    </option>

                                    <option value="59">
                                        조정
                                    </option>

                                    <option value="99">
                                        기타
                                    </option>

                                </select>
                            </div>
                        </div>
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>계급</div>
                            <div class="cont row">
                                <select class="cont__select full" name="armyclasscd" title="계급">
                                    <option value="">선택</option>

                                    <option value="11">
                                        대장
                                    </option>

                                    <option value="12">
                                        중장
                                    </option>

                                    <option value="13">
                                        소장
                                    </option>

                                    <option value="14">
                                        준장
                                    </option>

                                    <option value="21">
                                        대령
                                    </option>

                                    <option value="23">
                                        중령
                                    </option>

                                    <option value="25">
                                        소령
                                    </option>

                                    <option value="31">
                                        대위
                                    </option>

                                    <option value="33">
                                        중위
                                    </option>

                                    <option value="35">
                                        소위
                                    </option>

                                    <option value="37">
                                        준위
                                    </option>

                                    <option value="41">
                                        상사
                                    </option>

                                    <option value="42">
                                        중사
                                    </option>

                                    <option value="44">
                                        장기하사
                                    </option>

                                    <option value="45">
                                        단기하사
                                    </option>

                                    <option value="46">
                                        일반하사
                                    </option>

                                    <option value="47">
                                        하사
                                    </option>

                                    <option value="48">
                                        일반중사
                                    </option>

                                    <option value="51">
                                        병장
                                    </option>

                                    <option value="52">
                                        상병
                                    </option>

                                    <option value="53">
                                        일병
                                    </option>

                                    <option value="54">
                                        이병
                                    </option>

                                    <option value="55">
                                        방위병
                                    </option>

                                    <option value="56">
                                        제2 국민병
                                    </option>

                                    <option value="57">
                                        원사
                                    </option>

                                    <option value="58">
                                        일등상사
                                    </option>

                                    <option value="59">
                                        이등상사
                                    </option>

                                    <option value="60">
                                        사병
                                    </option>

                                    <option value="61">
                                        영관
                                    </option>

                                    <option value="62">
                                        하관
                                    </option>

                                    <option value="99">
                                        기타
                                    </option>

                                </select>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item item3">
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>예비군</div>
                            <div class="cont radio">
                                <div class="input-form__radio">
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="유" checked="">
                                        <span class="icon"></span>
                                        유
                                    </label>
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="무">
                                        <span class="icon"></span>
                                        무
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>민방위</div>
                            <div class="cont radio">
                                <div class="input-form__radio">
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="유" checked="">
                                        <span class="icon"></span>
                                        유
                                    </label>
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="무">
                                        <span class="icon"></span>
                                        무
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>제대구분</div>
                            <div class="cont row">
                                <select class="cont__select full" name="armydembztflag" title="제대구분">
                                    <option value="">선택</option>

                                    <option value="01">
                                        만기제대
                                    </option>

                                    <option value="02">
                                        소집해제
                                    </option>

                                    <option value="03">
                                        의가사제대
                                    </option>

                                    <option value="04">
                                        의병제대
                                    </option>

                                    <option value="05">
                                        명예제대
                                    </option>

                                    <option value="06">
                                        불명예제대
                                    </option>

                                    <option value="07">
                                        상이제대
                                    </option>

                                    <option value="08">
                                        방위소집해제
                                    </option>

                                    <option value="09">
                                        예편
                                    </option>

                                    <option value="10">
                                        신검불합격
                                    </option>

                                    <option value="11">
                                        특례보충역
                                    </option>

                                    <option value="12">
                                        징집면제
                                    </option>

                                    <option value="13">
                                        병역면제(기타)
                                    </option>

                                    <option value="14">
                                        병역미필
                                    </option>

                                    <option value="15">
                                        보충역필
                                    </option>

                                    <option value="16">
                                        예편
                                    </option>

                                    <option value="17">
                                        의원
                                    </option>

                                    <option value="18">
                                        소집면제
                                    </option>

                                    <option value="19">
                                        제대예정
                                    </option>

                                    <option value="99">
                                        기타
                                    </option>

                                </select>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>


        </div>

        <!-- 기타 -->
        <div class="contents">
            <div class="input-form type1 contents">
                <div class="depth__wrap depth01">
                    <div class="depth__bullet"><span class="bullet01"></span></div>
                    <div class="depth__text">
                        <h4 class="depth01__title">기타</h4>
                    </div>
                </div>
                <ul class="input-form__list" style="margin-top: 20px;">
                    <li class="input-form__item">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>장애여부</div>
                            <div class="cont radio">
                                <div class="input-form__radio">
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="유" checked="">
                                        <span class="icon"></span>
                                        유
                                    </label>
                                    <label class="input-item">
                                        <input type="radio" class="radio" name="radio1" v-model="item1" value="무">
                                        <span class="icon"></span>
                                        무
                                    </label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="input-form__item item3">
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>장애번호</div>
                            <div class="cont row"><input type="text" class="cont__text-input"></div>
                        </div>
                        <div class="input-form__box email">
                            <div class="tit"><span class="c-red">* </span>장애정도</div>
                            <div class="cont row">

                                <select class="cont__select full" name="supphandcapdgr" title="장애정도선택">
                                    <option value="">선택</option>
                                    <option value="01">경증</option>
                                    <option value="02">중증</option>
                                </select>
                            </div>
                        </div>
                        <div class="input-form__box">
                            <div class="tit"><span class="c-red">* </span>장애부위</div>
                            <div class="cont row"><input type="text" class="cont__text-input"></div>
                        </div>
                    </li>

                </ul>
            </div>
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn">중간저장</a>
                <a href="javascript:;" class="btn blue">다음단계</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import DatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default {
        data() {
            return {
                isChecked: false,
                imageSrc: require('@/assets/resource/img/img-add.png'),
                date: null, // 선택된 날짜를 저장할 변수
                dateFormat: 'YYYY-MM-DD', // 날짜 형식
            };
        },
        methods: {
            previewImage(event) {
                const file = event.target.files[0];
                if (file && file.type.startsWith('image/') && file.size <= 1000000) { // 1000KB 이하
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.imageSrc = e.target.result;
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('유효하지 않은 파일입니다. JPG 파일만 가능하며 크기는 1000KB 이하이어야 합니다.');
                }
            },
            isCheckedFn() {
                this.isChecked = true;
            }
        },
        setup() {
            const date = ref(null); // 선택된 날짜를 저장할 변수
            const startDate = ref(null); // 시작 날짜
            const endDate = ref(null); // 종료 날짜
            const dateFormat = 'yyyy-MM-dd'; // 날짜 형식

            return {
                date,
                startDate,
                endDate,
                dateFormat,
            };
        },
        components: {
            DatePicker,
        },
    };
</script>