<template>
    <div class="container print">
        <div class="sub-tit">
            <h2>응시원서</h2>
        </div>
        <div class="inner">
            <div>
                <!-- 모집구분 -->
                <div class="contents">
                    <div class="input-form type1 contents">
                        <div class="depth__wrap depth01">
                            <div class="depth__bullet"><span class="bullet01"></span></div>
                            <div class="depth__text">
                                <h4 class="depth01__title">모집구분</h4>
                            </div>
                        </div>
                        <div class="table-cont">
                            <ul class="table-list type02">
                                <input type="hidden" name="rnNo" v-model="applicantInfo.rnNo" />
                                <li class="table-item">
                                    <div class="item tit">채용공고</div>
                                    <div class="item">{{noticeInfo.rnTitle}}</div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">채용구분</div>
                                    <div class="item">{{$commonUtils.getRecruitCodeName('type', noticeInfo.rnType)}}</div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">부서</div>
                                    <div class="item">{{noticeInfo.rnDepartment}}</div>
                                </li>
                                <li class="table-item">
                                    <div class="item tit">직종</div>
                                    <div class="item" v-if="noticeInfo.rnCategoryDetail">[{{$commonUtils.getRecruitCodeName('category', noticeInfo.rnCategory)}}] {{$commonUtils.getRecruitCodeName('categoryDetail', noticeInfo.rnCategoryDetail)}}</div>
                                    <div class="item" v-else>{{$commonUtils.getRecruitCodeName('category', noticeInfo.rnCategory)}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 인적사항 -->
                <div class="contents">
                    <div class="input-form type1 contents">
                        <div class="depth__wrap depth01">
                            <div class="depth__bullet"><span class="bullet01"></span></div>
                            <div class="depth__text">
                                <h4 class="depth01__title">인적사항</h4>
                            </div>
                        </div>
                        <ul class="input-form__list" style="margin-top: 20px;">
                            <li class="input-form__item">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>사진</div>
                                    <div class="cont">
                                        <div style="display: flex;">
                                            <label class="img-add">
                                                <div class="img">
                                                    <img :src="imageSrc" alt="Selected Image" style="width: 210px; height: 280px; object-fit: cover;" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item" item2>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>성명(한글)</div>
                                    <div class="cont">
                                        <div ref="raName">
                                            <input type="text" class="cont__text-input" name="raName" v-model="applicantInfo.raName" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>성명(영문)</div>
                                    <div class="cont row">
                                        <span class="line" style="margin-right: 10px;">성</span>
                                        <input type="text" class="cont__text-input" name="raEngName1" v-model="applicantInfo.raEngName1" readonly>
                                        <span class="line" style="margin-right: 10px;">이름</span>
                                        <input type="text" class="cont__text-input" name="raEngName2" v-model="applicantInfo.raEngName2" readonly>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item item2">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>성별</div>
                                    <div class="cont">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="M" name="raGender" v-model="applicantInfo.raGender" readonly disabled>
                                                <span class="icon"></span>
                                                남
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="F" name="raGender" v-model="applicantInfo.raGender" readonly disabled>
                                                <span class="icon"></span>
                                                여
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>생년월일</div>
                                    <div class="cont">
                                        <input type="text" class="cont__text-input" name="raEngName2" v-model="applicantInfo.raBirth" readonly>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item" item2>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>휴대전화</div>
                                    <div class="cont">
                                        <div style="display: flex; align-items: center;" ref="raHpNo">
                                            <input type="text" class="cont__text-input" name="raHpNo1" :value="raHpNo1 + '-' + raHpNo2 + '-' + raHpNo3" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>이메일</div>
                                    <div class="cont">
                                        <div style="display: flex; align-items: center;">
                                            <input type="text" class="cont__text-input" name="raEmail1" v-model="applicantInfo.raEmail" readonly>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 기본정보 -->
                <div class="contents">
                    <div class="input-form type1 contents">
                        <div class="depth__wrap depth01">
                            <div class="depth__bullet"><span class="bullet01"></span></div>
                            <div class="depth__text">
                                <h4 class="depth01__title">기본정보</h4>
                            </div>
                        </div>
                        <ul class="input-form__list" style="margin-top: 20px;">
                            <li class="input-form__item">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>응급연락망</div>
                                    <div class="cont">
                                        <div>
                                            <input type="text" class="cont__text-input" style="max-width: 35%" name="raEmerHpNo" v-model="applicantInfo.raEmerHpNo" readonly>
                                            &nbsp;&nbsp;&nbsp;(관계 : <input type="text" class="cont__text-input" style="max-width: 8.2vw;" name="raEmerRel" v-model="applicantInfo.raEmerRel" readonly>)
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item">
                                <div class="input-form__box address">
                                    <div class="tit"><span class="c-red">* </span>주민등록지 <br>
                                        (등본주소지)
                                    </div>
                                    <div class="cont">
                                        <div class="cont-item">
                                            <input type="text" class="cont__text-input" name="raZipcode" v-model="applicantInfo.raZipcode" readonly>
                                        </div>
                                        <div class="cont-item">
                                            <input type="text" class="cont__text-input" name="raAddress1" v-model="applicantInfo.raAddress1" readonly>
                                        </div>
                                        <div class="cont-item">
                                            <input type="text" class="cont__text-input" name="raAddress2" v-model="applicantInfo.raAddress2" readonly>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item">
                                <div class="input-form__box address">
                                    <div class="tit"><span class="c-red">* </span>현주소
                                    </div>
                                    <div class="cont">
                                        <div class="input-form__radio" style="margin-bottom: 10px;">
                                            <label class="input-item" style="cursor: pointer;">
                                                <input type="checkbox" class="radio" name="raCDulpYn" v-model="applicantInfo.raCDulpYn" true-value="Y" false-value="N" readonly disabled>
                                                <span class="icon"></span>
                                                주민등록지 정보와 동일
                                            </label>
                                        </div>
                                        <div class="cont-item">
                                            <input type="text" class="cont__text-input" name="raCZipcode" v-model="applicantInfo.raCZipcode" readonly>
                                        </div>
                                        <div class="cont-item">
                                            <input type="text" class="cont__text-input" name="raCAddress1" v-model="applicantInfo.raCAddress1" readonly>
                                        </div>
                                        <div class="cont-item">
                                            <input type="text" class="cont__text-input" name="raCAddress2" v-model="applicantInfo.raCAddress2" readonly>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item item2">
                                <div class="input-form__box">
                                    <div class="tit">취미</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raHobby" v-model="applicantInfo.raHobby" readonly>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit">특기</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raSpecialty" v-model="applicantInfo.raSpecialty" readonly>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>

                <!-- 보훈사항 -->
                <div class="contents">
                    <div class="input-form type1 contents">
                        <div class="depth__wrap depth01">
                            <div class="depth__bullet"><span class="bullet01"></span></div>
                            <div class="depth__text">
                                <h4 class="depth01__title">보훈사항</h4>
                            </div>
                        </div>
                        <ul class="input-form__list" style="margin-top: 20px;">
                            <li class="input-form__item item2">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>보훈대상여부</div>
                                    <div class="cont radio">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="Y" name="raVeteranYn" v-model="applicantInfo.raVeteranYn" readonly disabled>
                                                <span class="icon"></span>
                                                유
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="N" name="raVeteranYn" v-model="applicantInfo.raVeteranYn" readonly disabled>
                                                <span class="icon"></span>
                                                무
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>보훈구분</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raVeteranSn" v-model="applicantInfo.raVeteranType" :disabled="applicantInfo.raVeteranYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item item2">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>보훈번호(숫자6자리)</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raVeteranSn" v-model="applicantInfo.raVeteranSn" :disabled="applicantInfo.raVeteranYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>유족관계</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raVeteranSn" v-model="applicantInfo.raVeteranRel" :disabled="applicantInfo.raVeteranYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>

                <!-- 병역사항 -->
                <div class="contents">
                    <div class="input-form type1 contents">
                        <div class="depth__wrap depth01">
                            <div class="depth__bullet"><span class="bullet01"></span></div>
                            <div class="depth__text">
                                <h4 class="depth01__title">병역사항</h4>
                            </div>
                        </div>
                        <ul class="input-form__list" style="margin-top: 20px;">
                            <li class="input-form__item item2">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>병역사항 이행여부</div>
                                    <div class="cont radio">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="Y" name="raMilitaryYn" v-model="applicantInfo.raMilitaryYn" readonly disabled>
                                                <span class="icon"></span>
                                                유
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="N" name="raMilitaryYn" v-model="applicantInfo.raMilitaryYn" readonly disabled>
                                                <span class="icon"></span>
                                                무
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>면제사유</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raMilitaryExemption" v-model="applicantInfo.raMilitaryExemption" :disabled="applicantInfo.raMilitaryYn === 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item item2">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>복무기간</div>
                                    <div class="cont row">
                                        <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                            <input type="text" class="cont__text-input" v-model="applicantInfo.raMilitaryStartD" :disabled="applicantInfo.raMilitaryYn !== 'Y' ? true : null" readonly>
                                            <span class="line" style="margin: 0 10px;">~</span>
                                            <input type="text" class="cont__text-input" v-model="applicantInfo.raMilitaryEndD" :disabled="applicantInfo.raMilitaryYn !== 'Y' ? true : null" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>군별</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raMilitarySpecialty" v-model="applicantInfo.raMilitarySpecialty" :disabled="applicantInfo.raMilitaryYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item item3">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>역종</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raMilitarySpecialty" v-model="applicantInfo.raMilitaryService" :disabled="applicantInfo.raMilitaryYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>병과</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raMilitarySpecialty" v-model="applicantInfo.raMilitaryOrdnance" :disabled="applicantInfo.raMilitaryYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>계급</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raMilitarySpecialty" v-model="applicantInfo.raMilitaryRank" :disabled="applicantInfo.raMilitaryYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item item3">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>예비군</div>
                                    <div class="cont radio">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="Y" name="raMilitaryReserveYn" v-model="applicantInfo.raMilitaryReserveYn" readonly disabled>
                                                <span class="icon"></span>
                                                유
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="N" name="raMilitaryReserveYn" v-model="applicantInfo.raMilitaryReserveYn" readonly disabled>
                                                <span class="icon"></span>
                                                무
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>민방위</div>
                                    <div class="cont radio">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="Y" name="raMilitaryCd" v-model="applicantInfo.raMilitaryCd" readonly disabled>
                                                <span class="icon"></span>
                                                유
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="N" name="raMilitaryCd" v-model="applicantInfo.raMilitaryCd" readonly disabled>
                                                <span class="icon"></span>
                                                무
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>제대구분</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raMilitarySpecialty" v-model="applicantInfo.raMilitaryDischarge" :disabled="applicantInfo.raMilitaryYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 기타 -->
                <div class="contents">
                    <div class="input-form type1 contents">
                        <div class="depth__wrap depth01">
                            <div class="depth__bullet"><span class="bullet01"></span></div>
                            <div class="depth__text">
                                <h4 class="depth01__title">기타</h4>
                            </div>
                        </div>
                        <ul class="input-form__list" style="margin-top: 20px;">
                            <li class="input-form__item">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>장애여부</div>
                                    <div class="cont radio">
                                        <div class="input-form__radio">
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="Y" name="raDisabilityYn" v-model="applicantInfo.raDisabilityYn" readonly disabled>
                                                <span class="icon"></span>
                                                유
                                            </label>
                                            <label class="input-item">
                                                <input type="radio" class="radio" value="N" name="raDisabilityYn" v-model="applicantInfo.raDisabilityYn" readonly disabled>
                                                <span class="icon"></span>
                                                무
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item item3">
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>장애번호</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raDisabilitySn" v-model="applicantInfo.raDisabilitySn" :disabled="applicantInfo.raDisabilityYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>장애정도</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raDisabilitySn" v-model="applicantInfo.raDisabilityDegree" :disabled="applicantInfo.raDisabilityYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                                <div class="input-form__box">
                                    <div class="tit"><span class="c-red">* </span>장애부위</div>
                                    <div class="cont row">
                                        <input type="text" class="cont__text-input" name="raDisabilityArea" v-model="applicantInfo.raDisabilityArea" :disabled="applicantInfo.raDisabilityYn !== 'Y' ? true : null" readonly>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 학력사항 -->
                <div class="contents" ref="educationDiv">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">학력사항</h4>
                        </div>
                    </div>
                    <div class="table-wrap table-type1 long">
                        <div>
                            <table style="table-layout: fixed">
                                <caption class="blind">
                                </caption>
                                <colgroup>
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11);">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <col style="width: calc(100%/11)">
                                    <!--<col style="width: 100px">-->
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th><span class="c-red">* </span>학력구분</th>
                                        <th><span class="c-red">* </span>학교명</th>
                                        <th><span class="c-red">* </span>전공</th>
                                        <th><span class="c-red">* </span>수업 년한</th>
                                        <th><span class="c-red">* </span>입학일자 ~ 졸업일자</th>
                                        <th><span class="c-red">* </span>학위코드/이수</th>
                                        <th><span class="c-red">* </span>학위번호</th>
                                        <th><span class="c-red">* </span>소재지</th>
                                        <th><span class="c-red">* </span>평점</th>
                                        <th><span class="c-red">* </span>석차</th>
                                        <th>백분율</th>
                                        <!--<th>비고</th>-->
                                    </tr>
                                </thead>
                                <tbody ref="educationSection">
                                    <tr v-for="(educationInfo, idx) in educationList" :key="educationInfo.raeNo">
                                        <td>
                                            <p class="text-load">{{ educationInfo.raeType }}</p>
                                        </td>
                                        <td v-if="idx === 0">
                                            <p class="text-load">{{ educationInfo.raeName }}</p>
                                        </td>
                                        <td class="" v-else-if="idx !== 0">
                                            <p class="text-load">{{educationInfo.raeName}}</p>
                                            <div class="input-form type1" style="margin-top: 10px;">
                                                <div class="input-form__radio">
                                                    <label class="input-item" style="display: flex; justify-content: center; cursor: pointer; width: 100%;">
                                                        <input type="checkbox" class="radio" v-model="educationInfo.raeTransferYn" true-value="Y" false-value="N" readonly disabled>
                                                        <span class="icon"></span> 편입
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td v-if="idx === 0"></td>
                                        <td v-else-if="idx !== 0">
                                            <p class="text-load">{{educationInfo.raeMajor}}</p>
                                            <div class="input-form type1" style="margin-top: 10px;">
                                                <div class="input-form__radio">
                                                    <label class="input-item" style="display: flex; justify-content: center; cursor: pointer; width: 100%;">
                                                        <input type="checkbox" class="radio" v-model="educationInfo.raeDoubleMajorYn" true-value="Y" false-value="N" readonly disabled>
                                                        <p class="text-load">{{educationInfo.raeDoubleMajorYn}}</p>
                                                        <span class="icon"></span> 복수전공
                                                    </label>
                                                </div>
                                            </div>
                                            <p class="text-load" v-if="educationInfo.raeDoubleMajorYn === 'Y'">{{educationInfo.raeDoubleMajor}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load" style="text-align: center;">{{educationInfo.raeYear}}</p>
                                        </td>
                                        <td>
                                            <div class="date_wrap" style="flex-wrap: wrap; flex-direction: column; margin: 0;">
                                                <p class="text-load">{{educationInfo.raeAdmissionD}}</p>
                                                <span class="line" style="margin: 0 10px;">~</span>
                                                <p class="text-load">{{educationInfo.raeGraduationD}}</p>
                                            </div>
                                        </td>
                                        <td v-if="idx === 0"></td>
                                        <td v-else-if="idx !== 0">
                                            <p class="text-load">{{educationInfo.raeDegree}}</p>
                                            <p class="text-load">{{educationInfo.raeDegreeSatus}}</p>
                                        </td>
                                        <td v-if="idx === 0"></td>
                                        <td v-else-if="idx !== 0">
                                            <p class="text-load">{{educationInfo.raeDegreeSn}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{educationInfo.raeLocation}}</p>
                                        </td>
                                        <td v-if="idx === 0"></td>
                                        <td v-else-if="idx !== 0">
                                            <span class="text-load">{{educationInfo.raeGrade}}</span>
                                        </td>
                                        <td v-if="idx === 0"></td>
                                        <td v-else-if="idx !== 0">
                                            <div class="input-form type1" style="margin-bottom: 10px;">
                                                <div class="input-form__radio">
                                                    <label class="input-item" style="display: flex; justify-content: center; width: 100%; cursor: pointer;">
                                                        <input type="checkbox" class="radio" v-model="educationInfo.raeRankYn" true-value="Y" false-value="N" readonly disabled>
                                                        <span class="icon"></span>석차
                                                    </label>
                                                </div>
                                            </div>
                                            <span class="text-load">{{educationInfo.raeRank}}</span>/
                                            <span class="text-load">{{educationInfo.raeRankStandard}}</span>
                                        </td>
                                        <td>
                                            <span class="text-load">{{educationInfo.raePercentage}}</span>/100
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- 학력사항 -->

                <!-- 경력사항 -->
                <div class="contents" ref="careerDiv">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">경력사항</h4>
                        </div>
                    </div>
                    <div class="table-wrap table-type1 long">
                        <div>
                            <table style="table-layout: fixed;">
                                <caption class="blind"></caption>
                                <colgroup>
                                    <col style="width: auto;">
                                    <col style="width: calc(100%/10);">
                                    <col style="width: calc(100%/11);">
                                    <col style="width: calc(100%/11);">
                                    <col style="width: calc(100%/11);">
                                    <col style="width: calc(100%/11);">
                                    <col style="width: calc(100%/11);">
                                    <col style="width: calc(100%/11);">
                                    <col style="width: calc(100%/11);">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>근무기간</th>
                                        <th>근무처</th>
                                        <th>고용형태</th>
                                        <th>부서명</th>
                                        <th>직책명</th>
                                        <th>직위명</th>
                                        <th>직무</th>
                                        <th>퇴직사유</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(careerInfo) in careerList" :key="careerInfo.racNo">
                                        <td>
                                            <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                                <p class="text-load">{{careerInfo.racStartD}}</p>
                                                <span class="line" style="margin: 0 10px;">~</span>
                                                <p class="text-load" v-if="careerInfo.racWorkingYn !== 'Y'">{{careerInfo.racEndD}}</p>
                                                <p class="text-load" v-else>재직중</p>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racName}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racEmployType}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racDepartment}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racPosition}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racTitle}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racDuty}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racResignReason}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{careerInfo.racNote}}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- 경력사항 -->

                <!-- 자격면허 -->
                <div class="contents" ref="licenseDiv">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">자격면허</h4>
                        </div>
                    </div>
                    <div class="table-wrap table-type1 long">
                        <div>
                            <table style="table-layout: fixed;">
                                <caption class="blind"></caption>
                                <colgroup>
                                    <col style="width: auto;">
                                    <col style="width: calc(100%/7);">
                                    <col style="width: calc(100%/5);">
                                    <col style="width: calc(100%/5);">

                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>자격면허명</th>
                                        <th>취득일자</th>
                                        <th>발급기관</th>
                                        <th>자격면허번호</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(licenseInfo) in licenseList" :key="licenseInfo.raliNo">
                                        <td>
                                            <p class="text-load">{{licenseInfo.raliName}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{licenseInfo.raliObtainD}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{licenseInfo.raliIssuer}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{licenseInfo.raliSn}}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- 자격면허 -->

                <!-- 외국어 -->
                <div class="contents" ref="languageDiv">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">외국어</h4>
                        </div>
                    </div>
                    <div class="table-wrap table-type1 long">
                        <div>
                            <table style="table-layout: fixed;">
                                <caption class="blind"></caption>
                                <colgroup>
                                    <col style="width: calc(100%/6);">
                                    <col style="width: calc(100%/6);">
                                    <col style="width: calc(100%/6);">
                                    <col style="width: calc(100%/6);">
                                    <col style="width: calc(100%/6);">
                                    <col style="width: calc(100%/6);">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>외국어</th>
                                        <th>종합등급</th>
                                        <th>시험코드</th>
                                        <th>취득일자</th>
                                        <th>시험점수</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(languageInfo) in languageList" :key="languageInfo.ralaNo">
                                        <td>
                                            <p class="text-load">{{languageInfo.ralaType}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{languageInfo.ralaGrade}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{languageInfo.ralaTest}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{languageInfo.ralaObtainD}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{languageInfo.ralaScore}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{languageInfo.ralaNote}}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- 외국어 -->

                <!-- 교육연수 -->
                <div class="contents" ref="trainingDiv">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">교육연수</h4>
                        </div>
                    </div>
                    <div class="table-wrap table-type1 long">
                        <div>
                            <table style="table-layout: fixed;">
                                <caption class="blind"></caption>
                                <colgroup>
                                    <col style="width: calc(100%/4);">
                                    <col style="width: calc(100%/4);">
                                    <col style="width: calc(100%/4);">
                                    <col style="width: calc(100%/4);">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>교육기관</th>
                                        <th>교육내용</th>
                                        <th>교육기간</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(trainingInfo) in trainingList" :key="trainingInfo.raeNo">
                                        <td>
                                            <p class="text-load">{{trainingInfo.ratInstitution}}</p>
                                        </td>
                                        <td>
                                            <p class="text-load">{{trainingInfo.ratContent}}</p>
                                        </td>
                                        <td>
                                            <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                                <span class="text-load">{{trainingInfo.ratStartD}}</span>
                                                <span class="line" style="margin: 0 10px;">~</span>
                                                <span class="text-load">{{trainingInfo.ratEndD}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="text-load">{{trainingInfo.ratNote}}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- 교육연수 -->

                <!-- 자기소개 -->
                <div class="contents">
                    <div class="input-form type1">
                        <div class="info-text depth__wrap depth01">
                            <div class="depth__bullet">
                                <span class="bullet01"></span>
                            </div>
                            <div class="depth__text">
                                <h4 class="depth01__title title">자기소개</h4>
                            </div>
                        </div>
                        <ul class="input-form__list introduction">
                            <li class="input-form__item desc">
                                <div class="input-form__box">
                                    <div class="tit" style="display: flex; align-items: center;">성장과정 및 학교생활</div>
                                    <div class="cont">
                                        <p class="small-text">{{raiGrowth.length}}/500자</p>
                                        <!-- <textarea class="cont__text-input" :value="raiGrowth" ref="raiGrowth" @input="(event) => (raiGrowth = event.target.value)" maxlength="500" readonly></textarea> -->
                                        <!-- <p class="text-input" :value="raiGrowth" ref="raiGrowth" @input="(event) => (raiGrowth = event.target.value)" maxlength="500" readonly>{{raiGrowth}}</p> -->
                                        <p class="text-load">{{raiGrowth}}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item desc">
                                <div class="input-form__box">
                                    <div class="tit" style="display: flex; align-items: center;">지원한 분야와 <br>
                                        관련한 자신의 장점 및 보완점</div>
                                    <div class="cont">
                                        <p class="small-text">{{raiStrength.length}}/500자</p>
                                        <!-- <textarea class="cont__text-input" :value="raiStrength" @input="(event) => (raiStrength = event.target.value)" maxlength="500" readonly></textarea> -->
                                        <!-- <p class="text-input" :value="raiStrength" @input="(event) => (raiStrength = event.target.value)" maxlength="500" readonly>{{raiStrength}}</p> -->
                                        <p class="text-load">{{raiStrength}}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item desc">
                                <div class="input-form__box">
                                    <div class="tit" style="display: flex; align-items: center;">성격 및 특기사항</div>
                                    <div class="cont">
                                        <p class="small-text">{{raiSpecialty.length}}/500자</p>
                                        <!-- <textarea class="cont__text-input" :value="raiSpecialty" ref="raiSpecialty" @input="(event) => (raiSpecialty = event.target.value)" maxlength="500" readonly></textarea> -->
                                        <!-- <p class="text-input" :value="raiSpecialty" ref="raiSpecialty" @input="(event) => (raiSpecialty = event.target.value)" maxlength="500" readonly>{{raiSpecialty}}</p> -->
                                        <p class="text-load">{{raiSpecialty}}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item desc">
                                <div class="input-form__box">
                                    <div class="tit" style="display: flex; align-items: center;">지원 동기 및 <br> 입사 후 포부/장래 계획</div>
                                    <div class="cont">
                                        <p class="small-text">{{raiPlan.length}}/500자</p>
                                        <!-- <textarea class="cont__text-input" :value="raiPlan" ref="raiPlan" @input="(event) => (raiPlan = event.target.value)" maxlength="500" readonly></textarea> -->
                                        <!-- <p class="text-input" :value="raiPlan" ref="raiPlan" @input="(event) => (raiPlan = event.target.value)" maxlength="500" readonly>{{raiPlan}}</p> -->
                                        <p class="text-load">{{raiPlan}}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="input-form__item desc">
                                <div class="input-form__box">
                                    <div class="tit" style="display: flex; align-items: center;">건강상태 <br>(최근 질병 또는 사고로 치료를 받았거나 과거 질병이 있었던 경우 작성)</div>
                                    <div class="cont">
                                        <p class="small-text">{{raiHealth.length}}/500자</p>
                                        <!-- <textarea class="cont__text-input " :value="raiHealth" ref="raiHealth" @input="(event) => (raiHealth = event.target.value)" maxlength="500" readonly></textarea> -->
                                        <!-- <p class="text-input " :value="raiHealth" ref="raiHealth" @input="(event) => (raiHealth = event.target.value)" maxlength="500" readonly>{{raiHealth}}</p> -->
                                        <p class="text-load">{{raiHealth}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 자기소개 -->

                <p class="desc" style="margin-top: 20px; text-align: center; font-size: 22px;">* 위 내용은 사실과 틀림없으며 기재사실이 허위로 판명될 경우에는 합격취소 또는 임용취소 처분을 감수 할 것을 서약하면서 응시지원서를 제출합니다.</p>
                <p class="desc" style="margin-top: 20px;text-align: right; font-size: 22px;">{{$commonUtils.timestampToDate(applicantInfo.submitDt)}}</p>
                <br>
                <p class="desc" style="margin-top: 20px;text-align: right; font-size: 22px;">지원자 : {{applicantInfo.raName}} &nbsp;&nbsp;&nbsp;(인)</p>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                imageSrc: require('@/assets/resource/img/img-add.png'),
                dateFormat: 'yyyy-MM-dd', // 날짜 형식

                rnNo: this.$route.query.rnNo,
                // raNo: this.$route.query.raNo,
                raNo: '',
                noticeInfo: {},
                applicantInfo: {},
                raHpNo1: '',
                raHpNo2: '',
                raHpNo3: '',
                educationList: [{}, {}],
                careerList: [{}],
                licenseList: [{}],
                languageList: [{}],
                trainingList: [{}],
                raiGrowth: '',
                raiStrength: '',
                raiSpecialty: '',
                raiPlan: '',
                raiHealth: '',

                token: this.$route.query.token,
            };
        },
        watch: {
        },
        methods: {
            getRecruitNoticeView() {
                this.axios
                    .get(this.api.recruit.notice.view, {
                        params: {
                            rnNo: this.rnNo,
                        }
                    })
                    .then((res) => {
                        this.noticeInfo = res.data
                        this.applicantInfo.rnNo = this.noticeInfo.rnNo
                    });
            },
            async getRecruitApplicantView() {
                await this.axios.get(this.api.recruit.applicant.view, {
                    params: {
                        raNo: this.raNo,
                        mgrToken: this.token,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.updateYn = 'Y'
                        this.applicantInfo = res.data
                        if (this.applicantInfo.raHpNo) {
                            this.raHpNo1 = this.applicantInfo.raHpNo.substring(0, 3)
                            this.raHpNo2 = this.applicantInfo.raHpNo.substring(3, 7)
                            this.raHpNo3 = this.applicantInfo.raHpNo.substring(7)
                        }
                        if (this.applicantInfo.raPwd) {
                            this.applicantInfo.raPwd = ''
                            this.applicantInfo.raPwd2 = ''
                            this.checkDuplicateYn = 'Y'
                            this.checkDuplicateName = this.applicantInfo.raName
                            this.checkDuplicateBirth = this.applicantInfo.raBirth
                            this.checkDuplicateHpNo = this.applicantInfo.raHpNo
                        }
                        if (this.applicantInfo.attachBag && this.applicantInfo.attachBag.profile) {
                            this.applicantInfo.attachBag.profile[0]
                            this.imageSrc = "/displayFile.do?name=" + this.applicantInfo.attachBag.profile[0].savedName
                        }

                        if (this.token) {
                            document.title = this.applicantInfo.rnTitle + " " + this.applicantInfo.raName + " 지원자 응시원서";
                        }
                    } else {
                        alert("올바르지 않은 접근입니다.")
                        window.close();
                        return
                    }
                });
            },
            isNotEmptyList(list) {
                let valueCount = 0

                for (const object of list) {
                    for (const value of Object.values(object)) {
                        if (value) {
                            valueCount++
                        }
                    }
                }

                return valueCount > 0
            },
            async getEducationList() {
                await this.axios.get(this.api.recruit.applicant.educationList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.educationList = res.data
                    }
                });
            },
            async getCareerList() {
                await this.axios.get(this.api.recruit.applicant.careerList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.careerList = res.data
                    }
                });
            },
            async getLicenseList() {
                await this.axios.get(this.api.recruit.applicant.licenseList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.licenseList = res.data
                    }
                });
            },
            async getLanguageList() {
                await this.axios.get(this.api.recruit.applicant.languageList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.languageList = res.data
                    }
                });
            },
            async getTrainingList() {
                await this.axios.get(this.api.recruit.applicant.trainingList, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.trainingList = res.data
                    }
                });
            },
            async getIntroductionInfo() {
                await this.axios.get(this.api.recruit.applicant.introductionView, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    if (res.data) {
                        this.raiGrowth = res.data.raiGrowth.replaceAll("&#40;", "(").replaceAll("&#41;", ")")
                        this.raiStrength = res.data.raiStrength.replaceAll("&#40;", "(").replaceAll("&#41;", ")")
                        this.raiSpecialty = res.data.raiSpecialty.replaceAll("&#40;", "(").replaceAll("&#41;", ")")
                        this.raiPlan = res.data.raiPlan.replaceAll("&#40;", "(").replaceAll("&#41;", ")")
                        this.raiHealth = res.data.raiHealth.replaceAll("&#40;", "(").replaceAll("&#41;", ")")
                    }
                });
            },
        },
        async mounted() {
            document.title = "창원파티마병원 일반채용 응시원서";

            if (this.token) {
                this.raNo = this.$route.query.raNo
            } else {
                this.raNo = this.$store.state.applicantStore.raNo;
                this.$store.commit('setRaNo', null)
            }

            if (!this.rnNo || !this.raNo) {
                alert("올바르지 않은 접근입니다.")
                window.close();
                return
            }

            if (this.rnNo) {
                this.getRecruitNoticeView()
            }

            if (this.raNo) {
                await this.getRecruitApplicantView()
                this.getEducationList()
                this.getCareerList()
                this.getLicenseList()
                this.getLanguageList()
                this.getTrainingList()
                this.getIntroductionInfo()
            }

            if (this.applicantInfo.raNo) {
                setTimeout(function () {
                    window.print()

                    setTimeout(function () {
                        window.close();
                    }, 100);
                }, 1000);
            }
        }
    };
</script>
<style scoped>
    .container.print {
        margin: 0 auto;
        zoom: 49%;

        * {
            -webkit-print-color-adjust: exact;
        }

        background-color: #fff;
    }

    @media print {

        html,
        body {
            -webkit-print-color-adjust: exact;
            width: 210mm;
            height: 297mm;
        }

        div.contents {
            page-break-inside: avoid;
            page-break-after: auto;
        }

        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid;
            page-break-after: auto;
        }

        thead {
            display: table-header-group;
        }

        tfoot {
            display: table-footer-group;
        }
    }

    .item,
    .tit,
    .cont,
    input,
    textarea {
        font-size: 22px !important;
    }

    p.text-input {
        font-size: 25px !important;
    }

    textarea.cont__text-input {
        max-width: 100% !important;
    }

    /*print resize css 적용 초기화*/
    .input-form.type1 .input-form__box .tit {
        min-width: 288px;
    }

    .input-form__item.item2 {
        display: flex;
        flex-direction: initial;
    }

    .input-form__item.item3 {
        display: flex;
        flex-direction: initial;
    }

    .input-form__box.email .cont .cont__text-input {
        width: 165px;
    }

    .input-form__list.introduction .text-load {
        font-size: 30px;
    }
</style>