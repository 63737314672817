const applicantStore = {
    state: {
        // 지원자번호
        raNo: '',
        rnNo: '',
        raAgree2: '',
        raAgree3: '',
    },
    mutations: {
        setRaNo: function (state, raNo) {
            state.raNo = raNo;
        },
        setRnNo: function (state, rnNo) {
            state.rnNo = rnNo;
        },
        setRaAgree2: function (state, raAgree2) {
            state.raAgree2 = raAgree2;
        },
        setRaAgree3: function (state, raAgree3) {
            state.raAgree3 = raAgree3;
        },
        // 초기화
        applicantInit: function(state){
            for(let i in state) {
                state[i] = ''
            }
        },
    }
}

export default applicantStore