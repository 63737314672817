<template>
    <div class="contents">
        <div class="praise full-width">
            <div class="praise__tit welfare">
                <h3>
                    <b class="c-red">다양한 복지 혜택</b>을 제공하여 <br>
                    직원들의 <b class="c-blue">삶의 질 향상</b>을 지원하고 있습니다.
                </h3>
            </div>
        </div>

        <div class="inner" style="margin-bottom: 150px;">
            <ul class="welfare__list">
                <li class="welfare__item">
                    <div class="icon"><img src="@/assets/resource/icon/welfare-icon1.png" alt=""></div>
                    <h4>건강 관리</h4>
                    <div class="text">
                        <p>- 직원 건강검진 실시</p>
                        <p>- 직원 및 가족 진료비 감면</p>
                    </div>
                </li>
                <li class="welfare__item">
                    <div class="icon"><img src="@/assets/resource/icon/welfare-icon2.png" alt=""></div>
                    <h4>자녀 교육</h4>
                    <div class="text">
                        <p>- 직원 자녀 대학 입학 축하금 지급</p>
                        <p>- 직장 어린이집 운영</p>
                    </div>
                </li>
                <li class="welfare__item">
                    <div class="icon"><img src="@/assets/resource/icon/welfare-icon3.png" alt=""></div>
                    <h4>경조 / 포상</h4>
                    <div class="text">
                        <p>- 각종 경조금 및 경조휴가 제공</p>
                        <p>- 장기 근속자 포상 및 근속수당 제공</p>
                        <p>- 우수직원 포상 및 해외연수 실시</p>
                    </div>
                </li>
                <li class="welfare__item">
                    <div class="icon"><img src="@/assets/resource/icon/welfare-icon4.png" alt=""></div>
                    <h4>문화생활</h4>
                    <div class="text">
                        <p>- 사내 동호회 운영 및 지원</p>
                        <p>- 직원 휴게 및 휴식공간 제공</p>
                        <p>- 리조트 이용 지원</p>
                    </div>
                </li>
                <li class="welfare__item">
                    <div class="icon"><img src="@/assets/resource/icon/welfare-icon5.png" alt=""></div>
                    <h4>생활 지원</h4>
                    <div class="text">
                        <p>- 식사 제공</p>
                        <p>- 기숙사 지원</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>

</style>